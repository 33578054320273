const silverVeil = {
  50: '#FAF9FC',
  100: '#F3F2F7',
  200: '#EDECF3',
  300: '#E7E6EE',
  400: '#DBD9E3',
  500: '#D0CFD9',
  600: '#B6B3C1',
  700: '#9B97A7',
  800: '#817D8D',
  900: '#676272',
  950: '#4D4957',
};

export default silverVeil;
