/**
 * @deprecated
 * Helper pour wrapper des anciens input dans un AppTextField
 *
 * Usage :
 * <AppTextFieldHelper>
 *   <input value={value} onChange={someAction} />
 * </AppTextFieldHelper>
 */
import * as React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { ForwardedRef, cloneElement, isValidElement } from 'react';
import { AppInputLabel } from '@/components/AppInputLabel';

interface AppTextFieldHelperProps {
  label: string;
  children: React.ReactElement;
  error?: boolean;
  helper?: string;
}

/**
 * @deprecated Use AppTextField
 */
export const AppTextFieldHelper = React.forwardRef(function AppTextFieldHelper(
  props: AppTextFieldHelperProps,
  ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
) {
  const { label, children, helper, error } = props;
  const TextFieldProps = {
    disabled: children.props.disabled,
    error: error,
    readOnly: children.props.readOnly,
    required: children.props.required,
  };

  if (!isValidElement<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(children))
    throw new Error('AppTextFieldHelper doit contenir un input, un textarea ou un select');

  const displayedChildren = cloneElement(children as any, {
    ref,
    className: `${children.props.className || ''} form-control`.trim(),
    style: {
      ...children.props.style,
      borderColor: '#555183',
      margin: '0',
      position: 'relative',
      top: '1.5rem',
      height: 44,
      padding: 9,
      borderRadius: 10,
    },
  });

  return (
    <FormControl variant="standard" {...TextFieldProps} sx={{ width: '100%' }}>
      <AppInputLabel shrink {...TextFieldProps}>
        {label}
      </AppInputLabel>
      {displayedChildren}
      <FormHelperText style={{ position: 'relative', top: '1.5rem' }}>{helper ?? ' '}</FormHelperText>
    </FormControl>
  );
});
