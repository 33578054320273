import React from 'react';
import { Button, type ButtonProps } from '@/components/Button';
import { Add, Edit, Delete } from '@/components/AppIcon';
import { Check, ChevronRight } from '@mui/icons-material';
import { styled } from '@mui/material';

type Action =
  // List of available actions
  'add' | 'cancel' | 'delete' | 'ok' | 'previous' | 'next' | 'finish' | 'validate' | 'edit' | 'close';

const actionMap: Record<Action, { icon: React.ReactNode; label: string; color: ButtonProps['color'] }> = {
  add: {
    icon: <Add sx={{ height: 18 }} />,
    label: 'Ajouter',
    color: 'secondary',
  },
  cancel: {
    icon: undefined,
    label: 'Annuler',
    color: 'secondary',
  },
  close: {
    icon: undefined,
    label: 'Fermer',
    color: 'secondary',
  },
  edit: {
    icon: <Edit />,
    label: 'Editer',
    color: 'secondary',
  },
  delete: {
    icon: <Delete />,
    label: 'Supprimer',
    color: 'error',
  },
  ok: {
    icon: undefined,
    label: 'OK',
    color: 'secondary',
  },
  previous: {
    icon: undefined,
    label: 'Précédent',
    color: 'secondary',
  },
  next: {
    icon: <ChevronRight sx={{ height: 18 }} />,
    label: 'Suivant',
    color: 'primary',
  },
  finish: {
    icon: <Check sx={{ height: 18 }} />,
    label: 'Terminer',
    color: 'primary',
  },
  validate: {
    icon: <Check sx={{ height: 18 }} />,
    label: 'Valider',
    color: 'secondary',
  },
};

export interface ActionButtonProps extends ButtonProps {
  /**
   * Well known action (ex: add, remove, destroy, etc)
   */
  actionName?: Action;
  label?: React.ReactNode;
}

/**
 * This component is useful to avoid duplication for every common button
 *
 * @param props
 */
export const ActionButton = (props: ActionButtonProps) => {
  const { label, actionName, startIcon, children, submitting, ...otherProps } = props;

  // eslint-disable-next-line security/detect-object-injection
  const actionData = actionName ? actionMap[actionName] : actionMap.add;

  const ButtonCustom = styled(Button)(({ theme }) => ({ padding: '10px 16px' }));

  return (
    <ButtonCustom
      color={actionData?.color}
      startIcon={submitting ? undefined : startIcon ?? actionData?.icon}
      submitting={submitting}
      {...otherProps}
    >
      {label ?? actionData?.label ?? children}
    </ButtonCustom>
  );
};
