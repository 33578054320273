import { z } from 'zod';
import { ParcoursTextType } from './ParcoursTextType';
import { ParcoursEmployeeRef } from './ParcoursEmployeeRef';

export const ParcoursActionName = z.string({ description: 'Parcours action name' });

export type ParcoursActionName = z.infer<typeof ParcoursActionName>;

export const ParcoursActionEmail = z.object({
  _: z.literal('ParcoursActionEmail'),
  to: z.array(ParcoursEmployeeRef).optional(),
  cc: z.array(ParcoursEmployeeRef).optional(),
  subject: z.string({ description: 'Mail subject. If omitted, automatically generated' }).optional(),
  content: z.string(),
  contentType: ParcoursTextType,
});
export type ParcoursActionEmail = z.infer<typeof ParcoursActionEmail>;

export const ParcoursAction = z.discriminatedUnion('_', [
  ParcoursActionEmail,
  z.object({ _: z.literal('ParcoursActionNotification') }),
]);
export type ParcoursAction = z.infer<typeof ParcoursAction>;
