import { Editor } from 'slate';
import { wrapLink } from './editor/wrapLink';
import { isValidURL } from './editor/isValidURL';

const inlineTypes = new Set(['link']);

export const withInlineTypes = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => inlineTypes.has(element.type) || isInline(element);

  editor.insertText = (text) => {
    if (text && isValidURL(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  // Convert as link when data is a valid url
  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isValidURL(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
