import aquaSerenity from './color/aquaSerenity';
import infernoRed from './color/infernoRed';
import royalLavender from './color/royalLavender';
import silverVeil from './color/silverVeil';

// https://material-web.dev/theming/color/
// Utiliser les tokens de couleurs et non les couleurs directement
export const colorScheme = {
  // Primary
  primary: aquaSerenity[400],
  onPrimary: royalLavender[950],
  primaryContainer: aquaSerenity[100],
  onPrimaryContainer: aquaSerenity[950],
  // Secondary
  secondary: undefined, // TODO:
  onSecondary: undefined, // TODO:
  secondaryContainer: undefined, // TODO:
  onSecondaryContainer: undefined, // TODO:
  // Tertiary
  tertiary: undefined, // TODO:
  onTertiary: undefined, // TODO:
  tertiaryContainer: undefined, // TODO:
  onTertiaryContainer: undefined, // TODO:
  // Error
  error: infernoRed[700],
  onError: infernoRed[100], // '#FFFFFF', ??
  errorContainer: undefined, // TODO:
  onErrorContainer: undefined, // TODO:

  surface: royalLavender[50],
  onSurface: royalLavender[950],

  outlineVariant: silverVeil[300],
  onSurfaceVariant: royalLavender[700],

  outline: silverVeil[400],
  white: '#FFFFFF',
};
export type ColorScheme = typeof colorScheme;
