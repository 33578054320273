import { DocumentBoxType } from '../../../../../declaration/types/DocumentBoxType';
import React, { useEffect, useState } from 'react';
import { DocumentAbsence } from '../../../../../../types/absences.type';
import { Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import './DocumentPreview.scss';

/**
 * Zoom au survole en pourcentage
 */
const ZOOM = 250;

interface DocumentPreviewProperties {
  document: DocumentAbsence;
  type?: DocumentBoxType;
}

const DocumentPreview = ({ document, type = DocumentBoxType.Success }: DocumentPreviewProperties) => {
  const [scale, setScale] = useState(1);
  const [transformOrigin, setTransformOrigin] = useState<string>('center center');

  const [file, setFile] = useState<File>();
  const [numberPages, setNumberPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages: nextNumberPages }: PDFDocumentProxy) => {
    setNumberPages(nextNumberPages);
  };

  const getDocumentClassName = (base: string) => {
    if (type === DocumentBoxType.Failed) return `${base} ${base}--Failed`;
    if (type === DocumentBoxType.Warning) return `${base} ${base}--Warning`;

    return `${base} ${base}--Default`;
  };

  const onMouseMove = (event: any) => {
    const { left, top, width, height } = event.currentTarget.getBoundingClientRect();
    const x = ((event.clientX - left) / width) * 100;
    const y = ((event.clientY - top) / height) * 100;
    setTransformOrigin(`${x}% ${y}%`);
  };

  useEffect(() => {
    const fetchFileFromUrl = async (url: string) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const parts = url.split('.');
        const extension = parts[parts.length - 1];
        setFile(
          new File([blob], document.name, {
            type: extension === 'pdf' ? 'application/pdf' : 'image/jpg',
          }),
        );
      } catch {
        /* empty */
      }
    };
    if (document && !file) {
      fetchFileFromUrl(document.url);
    }
  }, [document]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div
        onMouseMove={onMouseMove}
        onMouseEnter={() => setScale(ZOOM / 100)}
        onMouseLeave={() => setScale(1)}
        className={getDocumentClassName('DocumentPreview')}
        style={{
          cursor: 'crosshair',
          overflow: 'hidden',
          borderRadius: 10,
          padding: 8,
          background: 'white',
        }}
        onClick={() => window.open(document.url, '_blank')}
      >
        <Document file={document.url} onLoadSuccess={onDocumentLoadSuccess}>
          <div
            style={{
              transformOrigin,
              transform: `scale(${scale})`,
              borderRadius: 10,
            }}
          >
            {Array.from({ length: numberPages || 0 }).map((element, index) => (
              <>
                <Page
                  className={'DocumentPreview__Page'}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={800}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </>
            ))}
          </div>
        </Document>
      </div>
    </div>
  );
};

export default React.memo(DocumentPreview);
