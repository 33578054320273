import { Element, Editor } from 'slate';

export function currentURL(editor: Editor): string | undefined {
  const { selection } = editor;
  if (!selection) return undefined;

  // eslint-disable-next-line unicorn/prefer-spread
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
    }),
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return match?.[0]?.url;
}
