import React from 'react';
import type { Location } from 'react-router-dom';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { Typography } from '@mui/material';

export interface DeclarationDocumentHeaderProps {
  location: Location;
  absenceType: any;
}

export const DeclarationDocumentHeader = ({ location, absenceType }: DeclarationDocumentHeaderProps) => {
  return (
    <>
      <StepProgressBar type={absenceType} />
      {location.state?.document?.ocr_result?.data?.documentStatus === 'rejected' ? (
        <h3 className="DeclarationDocumentHeader__text question-form mt-5 mb-5 text-center">
          <Typography color="error" variant="h6">
            Votre document a été refusé, que souhaitez-vous faire ?
          </Typography>
        </h3>
      ) : (
        <>
          <h3 className="DeclarationDocumentHeader__text question-form mt-5">
            Vous allez maintenant joindre votre justificatif.
          </h3>
          <h3 className="DeclarationDocumentHeader__text question-form mb-5">De quel document disposez-vous ?</h3>
        </>
      )}
    </>
  );
};
