import React, { useEffect, useState } from 'react';
import { Spacer } from '@/components/Spacer';
import {
  EventRepeatOutlined,
  FileDownloadOff,
  PregnantWomanOutlined,
  SdCardAlertOutlined,
  SignalCellularNoSimOutlined,
} from '@mui/icons-material';
import { NotificationSearchFilter, NotificationsType } from './data';
import { NotificationSearchBarFilter } from './NotificationSearchBarFilters';
import { SearchField } from '@/components/DashboardRH/shared/components/Form';
import { Box } from '@mui/material';
import { FilterButton } from '@/components/FilterButton';

export const emptyNotificationSearchFilter: NotificationSearchFilter = {
  content: '',
  portfolio: false,
  type: {
    [NotificationsType.MissingDocument]: {
      label: `Gérer les justificatifs manquants`,
      icon: <SignalCellularNoSimOutlined />,
      checked: false,
    },
    [NotificationsType.VerifyDocument]: {
      label: `Valider les justificatifs`,
      icon: <SdCardAlertOutlined />,
      checked: false,
    },
    [NotificationsType.AbsenceEnd]: {
      label: `Clôturer les absences`,
      icon: <EventRepeatOutlined />,
      checked: false,
    },
    [NotificationsType.Pathologie]: {
      label: 'Gérer les congés pathologiques',
      icon: <PregnantWomanOutlined />,
      checked: false,
    },
    [NotificationsType.IntegrationFailed]: {
      label: 'Gérer les intégrations échouées',
      icon: <FileDownloadOff />,
      checked: false,
    },
  },
};

interface NotificationSearchBarProperties {
  disablePortfolio?: boolean;
  onSubmit?: (filters: NotificationSearchFilter) => void;
  onCancel?: () => void;
  onSearchFilterChanged?: (filters: NotificationSearchFilter) => void;
}

export const NotificationSearchBar = ({
  disablePortfolio,
  onSubmit,
  onCancel,
  onSearchFilterChanged,
}: NotificationSearchBarProperties): JSX.Element => {
  const [filters, setFilters] = useState<NotificationSearchFilter>(emptyNotificationSearchFilter);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onSearchFilterChanged?.(filters);
  }, [filters]);
  return (
    <>
      <Box ml={2.5} mr={2.5} display="flex">
        <Box>
          <SearchField
            placeholder="Par nom, par prénom ou par matricule"
            onChange={(searchString: string) => {
              const newFilters = {
                ...filters,
                content: searchString,
              };
              setFilters(newFilters);
              onSubmit?.(newFilters);
            }}
          />
        </Box>

        <Box>
          <FilterButton
            open={isOpen}
            onClick={() => {
              const initialValue = isOpen;
              setIsOpen(!initialValue);
              if (initialValue) onSubmit?.(filters);
            }}
          />

          <NotificationSearchBarFilter
            open={isOpen}
            value={filters}
            onChange={(value) => setFilters(value)}
            onSubmit={(value) => {
              onSubmit?.(value);
              setIsOpen(false);
            }}
            onCancel={() => {
              setFilters(emptyNotificationSearchFilter);
              onCancel?.();
              setIsOpen(false);
            }}
            disablePortfolio={disablePortfolio}
          />
        </Box>
        <Spacer />
      </Box>
    </>
  );
};
