import { z } from 'zod';

export const ParcoursPublic = z.object({
  code: z.string(),
  title: z.string(),
  action: z.string(),
  description: z.string().array(),
  parcours_id: z.string().uuid().optional(),
});

export interface ParcoursPublic extends z.infer<typeof ParcoursPublic> {}
