import React from 'react';
import { Navbar } from '@/components/Navbar/Navbar';
import Footer from '../Footer/Footer';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function DepotDocumentFin() {
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb- text-center">Vos documents ont bien été pris en compte</h3>

        <form className="form-legacy form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>
        </form>
        <div className="position-relative col-12 col-md-8 mt-1 mb-5 text-center">
          <Link className="btn-link" to="/">
            <button type="button" className="btn btn-kerij col-6 col-lg-3">
              Retour à l'accueil
            </button>
          </Link>
        </div>
      </div>
      <NavbarMobile />
      <Footer />
    </div>
  );
}

export default DepotDocumentFin;
