import { Absence } from './absences.type';
import type { EmployeeCivility } from './EmployeeCivility';
import type { EmployeeTimePackage } from './EmployeeTimePackage';
interface Business {
  address: string;
  city: string;
  country: string;
  created_at: string;
  id: string;
  id_parent_company: string;
  logo_url: string;
  name: string;
  status: string;
  updated_at: string;
  zipcode: string;
}

export interface EmployeeContact {
  id: string;
  type: EmployeeContactType;
  firstname: string;
  lastname: string;
  email: string;
  is_actif: boolean;
  is_info: boolean;
  created_at: Date;
  updated_at: Date;
  id_employee_information: string;
}

// Copied from /backend/src/__legacy__/database/client/models/employee-contact
export const EmployeeContactType = {
  Manager1: 'Manager_1',
  Manager2: 'Manager_2',
  ManagerRH1: 'Manager_RH_1',
  ManagerRH2: 'Manager_RH_2',
  GTA1: 'GTA_1',
  GTA2: 'GTA_2',
  ReferentHandicap1: 'Referent_handicap_1',
  ReferentHandicap2: 'Referent_handicap_2',
  PreventionRisk1: 'Prevention_risk_1',
  PreventionRisk2: 'Prevention_risk_2',
  InfirmiereTravail1: 'Infirmiere_travail_1',
  InfirmiereTravail2: 'Infirmiere_travail_2',
  MedecinTravail1: 'Medecin_travail_1',
  MedecinTravail2: 'Medecin_travail_2',
  AssistanteSociale1: 'Assistante_sociale_1',
  AssistanteSociale2: 'Assistante_sociale_2',
} as const;
export type EmployeeContactType = (typeof EmployeeContactType)[keyof typeof EmployeeContactType];

export const EmployeeContactTypeModule = {
  format(self: EmployeeContactType) {
    switch (self) {
      case EmployeeContactType.GTA1: {
        return 'Référent Gestionnaire temps 1';
      }
      case EmployeeContactType.GTA2: {
        return 'Référent Gestionnaire temps 2';
      }
      case EmployeeContactType.ReferentHandicap1: {
        return 'Référent Handicap 1';
      }
      case EmployeeContactType.ReferentHandicap2: {
        return 'Référent Handicap 2';
      }
      case EmployeeContactType.PreventionRisk1: {
        return 'Prévention Risques 1';
      }
      case EmployeeContactType.PreventionRisk2: {
        return 'Prévention Risques 2';
      }
      case EmployeeContactType.AssistanteSociale1: {
        return 'Assistante Sociale 1';
      }
      case EmployeeContactType.AssistanteSociale2: {
        return 'Assistante Sociale 2';
      }
      case EmployeeContactType.InfirmiereTravail1: {
        return 'Infirmière Travail 1';
      }
      case EmployeeContactType.InfirmiereTravail2: {
        return 'Infirmière Travail 2';
      }
      case EmployeeContactType.MedecinTravail1: {
        return 'Médecin Travail 1';
      }
      case EmployeeContactType.MedecinTravail2: {
        return 'Médecin Travail 2';
      }
      default: {
        return self.replaceAll('_', ' ');
      }
    }
  },
  isManager(self: EmployeeContactType) {
    return ([EmployeeContactType.Manager1, EmployeeContactType.Manager2] as EmployeeContactType[]).includes(self);
  },
  isManagerRH(self: EmployeeContactType) {
    return ([EmployeeContactType.ManagerRH1, EmployeeContactType.ManagerRH2] as EmployeeContactType[]).includes(self);
  },
  isReferentHandicap(self: EmployeeContactType) {
    return (
      [EmployeeContactType.ReferentHandicap1, EmployeeContactType.ReferentHandicap2] as EmployeeContactType[]
    ).includes(self);
  },
  isSST(self: EmployeeContactType) {
    return (
      [
        EmployeeContactType.InfirmiereTravail1,
        EmployeeContactType.InfirmiereTravail2,
        EmployeeContactType.MedecinTravail1,
        EmployeeContactType.MedecinTravail2,
      ] as EmployeeContactType[]
    ).includes(self);
  },
};

export interface EmployeeInformation {
  account_code: string;
  address1: string;
  address2: string;
  address3: string;
  birth_commun: string;
  birth_country: string;
  birth_country_code: string;
  birthday: string;
  birthname: string;
  email: string;
  civility: EmployeeCivility;
  coa: string;
  count: number;
  country_code: string;
  created_at: string;
  description: string;
  end_date: string;
  firstname: string;
  id: string;
  id_business: string;
  job_title: string;
  lastname: string;
  locality: string;
  contract_nature: string;
  nb_hours_per_days: number;
  notification: string;
  work_time: string;
  time_package: EmployeeTimePackage;
  reason: string;
  gross_salary: number;
  start_date: string;
  category: string;
  updated_at: string;
  zipcode: string;
  business: Business;
  employee_login: UserInterface;
  employee_contacts: Array<EmployeeContact>;
  absences?: Absence[];
  totalLastYearAbsencesDays?: number;
  totalLastYearAbsences?: number;
  organisation?: Organisation;
}

export interface Organisation {
  account_code: string;
  short_description: string;
  long_description: string;
}

export interface UserInterface {
  cgu_checked: boolean;
  created_at: string;
  email: string;
  employee_information: EmployeeInformation;
  id: string;
  last_change_password: string;
  id_employee_information: string;
  phone: string;
  status: string;
  updated_at: string;
}

export const getStatusUserLogin = (login: UserInterface) => {
  const hasPreAccount = !!login?.email;
  const hasAccount = !!login?.last_change_password;

  switch (true) {
    case hasAccount: {
      return 'Compte actif';
      break;
    }
    case hasPreAccount: {
      return 'Compte provisionné';
      break;
    }
    default: {
      return 'Compte inactif';
      break;
    }
  }
};
