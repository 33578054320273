import * as React from 'react';
import { InputProps } from '@/hooks/useInput';
import { Box, Typography } from '@mui/material';
import { HTMLEditor } from '@/components/HTMLEditor';
import { Parcours } from '@/types';
import { ParcoursActionEmail } from '@/types/ParcoursAction';
import { parcoursEmailFooter, parcoursEmailHeader } from '@/components/ParcoursDetail';

export const defaultParcoursScriptAction = {
  email: {
    _: 'ParcoursActionEmail',
    contentType: 'text/html',
    subject: 'Éligibilité de {{ parcours.employee }} au parcours {{ parcours.name }}',
    // eslint-disable-next-line xss/no-mixed-html
    content: [
      'Vous êtes éligible à un parcours',
      '',
      'Si vous souhaitez le suivre, vous pouvez en faire la demande <strong>directement depuis votre espace KERij</strong>',
    ]
      .map((_) => `<p>${_}</p>`)
      .join('\n'),
    to: [],
    cc: [],
  } satisfies ParcoursActionEmail,
} satisfies Parcours['script']['action'];

type ParcoursActions = any; //TODO: implement this

export interface ParcoursActionsInputProps extends InputProps<'div', ParcoursActions> {}

export function ParcoursActionsInput(props: ParcoursActionsInputProps) {
  const { value, onChange } = props;

  const useActionEdit = (actionName: string, defaultValue?: any) => [
    value[actionName] ?? defaultValue,
    (event: any, valueNew: any) => {
      onChange?.(event, {
        ...value,
        [actionName]: valueNew,
      });
    },
  ];
  const [actionEmail, setActionEmail] = useActionEdit('email');

  React.useEffect(() => {
    setActionEmail({}, actionEmail ?? defaultParcoursScriptAction.email);
  }, []);

  return (
    <>
      <Box>
        <HTMLEditor
          initialValue={actionEmail?.content ?? ''}
          onValueChange={(value) => {
            setActionEmail(
              {},
              {
                ...actionEmail,
                contentType: 'text/html',
                content: value,
              },
            );
          }}
          beforeEditable={
            <Box sx={{ padding: 2, whiteSpace: 'pre', cursor: 'not-allowed' }}>
              <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>{parcoursEmailHeader}</Typography>
            </Box>
          }
          afterEditable={
            <Box sx={{ padding: 2, whiteSpace: 'pre', cursor: 'not-allowed' }}>
              <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>{parcoursEmailFooter}</Typography>
            </Box>
          }
          rows={10}
        />
      </Box>
    </>
  );
}
