/* eslint-disable unicorn/prefer-module */
import React, { useEffect, useState } from 'react';
import { EmployeeCard } from '@/components/EmployeeCard';
import { getCollaborateursRequest, getMoreCollaborateursRequest } from '../../store/action/collaborateurs';
import { EmployeeInformation } from '../../types/user.interface';
import useDebounce from '../../hooks/useDebounce';
import { employeeCsv } from '../../services/employee';
import { ActionButton } from '@/components/ActionButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppDispatch, useAppSelector } from '@/store';
import { ListCard } from '@/components/DashboardRH/ListCard';
import { RHPage } from '@/components/RHPage';
import { CollaborateurSearchBar } from './CollaborateurSearchBar';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { useToast } from '@/components/Toast';

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
  filename: 'Extractions des collaborateurs',
  fieldSeparator: ';',
});

const Collaborateurs = () => {
  const toast = useToast();
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const searchValue = useDebounce(search, 1000);
  const [isPortfolioFilter, setIsPortfolioFilter] = useState(false);

  useEffect(() => {
    dispatch(
      getCollaborateursRequest({ searchValue: searchValue, portfolio: isPortfolioFilter ? 'true' : undefined }) as any,
    );
  }, [searchValue, isPortfolioFilter]);

  const employeeData: EmployeeInformation[] | undefined = useAppSelector(
    (state: any) => state.collaborateurs?.employeesData?.retrieved?.employees,
  );

  const count: number = useAppSelector((state) => state.collaborateurs?.employeesData?.retrieved?.count);

  const dispatch = useAppDispatch();
  const fetchMoreData = () => {
    const newOffset = offset + 10;

    dispatch(
      getMoreCollaborateursRequest(
        newOffset,
        { searchValue, portfolio: isPortfolioFilter ? 'true' : undefined },
        () => {
          setOffset(newOffset);
        },
      ) as any,
    );
  };

  async function extractCollaborateurs() {
    setSubmitting(true);
    try {
      const { data } = await employeeCsv(searchValue, isPortfolioFilter ? 'true' : undefined);
      if (data.length === 0) {
        setSubmitting(false);
        return toast.present({ message: 'Aucune ligne à exporter', severity: 'error' });
      }
      download(csvConfig)(generateCsv(csvConfig)(data));
      toast.present({ message: `${data.length} ligne(s) exportée(s)`, severity: 'success' });
    } catch {
      toast.present({ message: "Une erreur est survenue durant l'export", severity: 'error' });
    }
    setSubmitting(false);
  }

  return (
    <RHPage
      title="Collaborateurs"
      actions={
        <ActionButton
          label="Export Excel"
          disabled={submitting}
          onClick={extractCollaborateurs}
          endIcon={<FileDownloadIcon />}
        />
      }
      topElement={
        <CollaborateurSearchBar
          onSubmit={(filters) => {
            setSearch(filters.content);
            setIsPortfolioFilter(filters.portfolio ?? false);
          }}
          onCancel={() => setIsPortfolioFilter(false)}
        />
      }
    >
      <InfiniteScroll
        style={{ overflow: 'visible' }}
        dataLength={employeeData?.length || 0}
        next={fetchMoreData}
        hasMore={(employeeData?.length || 0) < count}
        loader={<h4> Loading...</h4>}
      >
        <MemoRenderEmployeeData employeeData={employeeData} />
      </InfiniteScroll>
    </RHPage>
  );
};

const RenderEmployeeData = ({ employeeData }: any) => {
  return (
    <>
      {employeeData?.map((employee: EmployeeInformation, index: number) => {
        return (
          <ListCard key={index}>
            <EmployeeCard employeeInformation={employee} />
          </ListCard>
        );
      })}
    </>
  );
};

const MemoRenderEmployeeData = React.memo(RenderEmployeeData);

export default Collaborateurs;
