import Card from '@mui/material/Card';
import React from 'react';
import { RHNotification } from '../../../../../types/rh.interface';
import { DocumentIcon, LinkIcon } from '../../../Icons';
import { AbsenceCardHeader } from '@/components/DashboardRH/AbsenceCard';
import { EmployeeCardHeader } from '@/components/DashboardRH/EmployeeCard';
import { ListCardActions, ListCardContent } from '@/components/DashboardRH/ListCard';
import { Box } from '@mui/material';

export interface NotificationCardInterface {
  rhNotification: RHNotification;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}

const NotificationCard = ({ rhNotification, children, actions }: NotificationCardInterface) => {
  return (
    <Card className="mb-4 w-100">
      {rhNotification.employee_information && rhNotification.absence && (
        <AbsenceCardHeader
          employeeInformation={rhNotification.employee_information}
          absence={rhNotification.absence}
          secondaryAction={
            <>
              <DocumentIcon absence={rhNotification.absence} />
              <LinkIcon absence={rhNotification.absence} />
            </>
          }
        />
      )}

      {rhNotification.employee_information && !rhNotification.absence && (
        <EmployeeCardHeader employeeInformation={rhNotification.employee_information} />
      )}

      <ListCardContent>{children}</ListCardContent>
      {actions && (
        <ListCardActions>
          <Box textAlign="right">{actions}</Box>
        </ListCardActions>
      )}
    </Card>
  );
};
export default NotificationCard;
