import React, { useMemo } from 'react';
import { Parcours } from '@/types';
import { defaultParcoursScriptAction } from '@/components/ParcoursInput';
import { AppSelectMultiple, type AppSelectMultipleProps } from '@/components/AppSelect';
import { EmployeeContactQuery } from '@/query';
import { ParcoursActionEmail, ParcoursEmployeeRef } from '@/types';
import { PageFormBody, PageFormHelperText } from '@/components/Page';

const emptyArray: any[] = [];

export interface ParcoursActionContactStepProps {
  value?: Parcours['script']['action'];
  onChange?: (value: Parcours['script']['action']) => void;
}

export const ParcoursActionContactStep = (props: ParcoursActionContactStepProps) => {
  const { value, onChange } = props;

  const employeeContactSelectItems = EmployeeContactQuery.useSelectItems();
  const parseEmailAction = useMemo(() => ParcoursActionEmail.safeParse(value?.email), [value?.email]);

  const useField = (type: 'cc' | 'to') => {
    const fieldValue = parseEmailAction.success ? parseEmailAction.data[type] ?? emptyArray : emptyArray;
    const onFieldChange: AppSelectMultipleProps['onChange'] = (event, newValue) => {
      const parseAction = ParcoursActionEmail.safeParse(value?.email);
      const safeValue = parseAction.success ? parseAction.data : defaultParcoursScriptAction.email;

      const parsedValue: Array<ParcoursEmployeeRef> = (newValue?.filter(
        (value) => ParcoursEmployeeRef.safeParse(value).success,
      ) ?? []) as Array<ParcoursEmployeeRef>;

      const destination: ParcoursEmployeeRef[] = parsedValue.includes('employee') ? ['employee'] : parsedValue;

      if (type === 'cc') {
        onChange?.({ email: { ...safeValue, cc: destination } });
      } else {
        // TODO: bouger dans un autre endroit
        const subject = parsedValue.includes('employee')
          ? 'Votre éligibilité au parcours {{ parcours.name }}'
          : 'Éligibilité de {{ parcours.employee }} au parcours {{ parcours.name }}';
        onChange?.({ email: { ...safeValue, to: destination, subject } });
      }
    };

    return {
      value: fieldValue,
      onChange: onFieldChange,
    };
  };
  const to = useField('to');
  const cc = useField('cc');

  const itemsTo = useMemo(
    () => [
      { value: 'employee', label: 'Le collaborateur' },
      ...(employeeContactSelectItems.data?.map((value) => ({
        ...value,
        disabled: to.value.includes('employee') || cc.value.includes(value.value as any),
      })) ?? []),
    ],
    [cc, employeeContactSelectItems.data, to],
  );
  const itemsCc = useMemo(
    () =>
      employeeContactSelectItems.data?.map((value) => ({
        ...value,
        disabled: cc.value.includes('employee') || to.value.includes(value.value as any),
      })) ?? [],
    [cc, employeeContactSelectItems.data, to],
  );

  return (
    <PageFormBody
      before={
        <PageFormHelperText whiteSpace={'pre-wrap'}>
          {`Dans cette étape, vous allez définir les destinataires du mail.

Le mail personnel du salarié sera toujours en copie cachée. Seul son mail professionnel sera visible par la communauté.`}
        </PageFormHelperText>
      }
    >
      <AppSelectMultiple
        render="chip"
        items={itemsTo}
        label="Destinataires de l'email"
        placeholder="Sélectionnez une ou plusieurs personnes"
        {...to}
      />

      <AppSelectMultiple
        render="chip"
        items={itemsCc}
        label="En copie de l'email"
        placeholder="Sélectionnez une ou plusieurs personnes"
        {...cc}
      />
    </PageFormBody>
  );
};
