import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { Navbar } from '@/components/Navbar/Navbar';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import ReactLoading from 'react-loading';
import { StatusCodes } from 'http-status-codes';
import { encodeToken } from '../../services/auth';
import { employeeAddQuestionReturn, employeeVerifyQuestionReturn } from '../../services/employee';
import RequireLogin from '../RequireLogin';
import useQuestionnaire from '../../hooks/useQuestionnaire';
import { AccountContext, useAppSelector } from '@/store';

function QuestionnaireRetour() {
  const user = useAppSelector(AccountContext.getUser);
  const loading = useAppSelector(AccountContext.isLoading);
  const [etatRetour, setEtatRetour] = useState('1');
  const [etatRetourPourquoi, setEtatRetourPourquoi] = useState('1');
  const [etatRetourLabel, setEtatRetourLabel] = useState('Je me sens bien à la situation');
  const [etatRetourPourquoiLabel, setEtatRetourPourquoiLabel] = useState();
  const [expire, setExpire] = useState('');
  const [idSalarieInformation, setIdSalarieInformation] = useState();
  const [idAbsence, setIdAbsence] = useState();

  const [decode, access, setAccess, loadingAbsence, setLoadingAbsence] = useQuestionnaire();

  const navigate = useNavigate();
  const handleClick = () => {
    encodeToken({
      idEmployeeInformation: idSalarieInformation,
      idAbsence: idAbsence,
    }).then((value) => {
      if (value.status === StatusCodes.OK) {
        employeeAddQuestionReturn({
          question: 'Bonjour, vous êtes revenu sur votre lieu de travail. Comment vous sentez-vous ?',
          response: etatRetourLabel,
          reason: etatRetourPourquoiLabel,
          typeNotification: 'Notification de questionnaire de retour',
        }).then((value) => {
          if (value.status === StatusCodes.OK) {
            navigate('/questionnaireretourfin', { replace: true });
          }
        });
      }
    });
  };

  useEffect(() => {
    try {
      if (!loading || user) {
        if (user && decode) {
          setIdSalarieInformation(decode.idEmployeeInformation);
          setIdAbsence(decode.idAbsence);
          setLoadingAbsence(true);
          encodeToken({
            idAbsence: decode.idAbsence,
            idEmployeeInformation: user.employee_information.id,
          }).then((value) => {
            if (value.status === StatusCodes.OK) {
              employeeVerifyQuestionReturn().then((value) => {
                if (value.status === StatusCodes.OK) {
                  if (value.data.isVerified) {
                    setExpire('');
                  } else {
                    setExpire("Ce lien n'est plus disponible");
                  }
                  setAccess(true);
                  setLoadingAbsence(false);
                }
              });
            }
          });
        }
      } else if ((loading || !user) && decode) {
        setIdSalarieInformation(decode.idEmployeeInformation);
        setIdAbsence(decode.idAbsence);
        setLoadingAbsence(true);
        encodeToken({
          idAbsence: decode.idAbsence,
          idEmployeeInformation: decode.idEmployeeInformation,
        }).then((value) => {
          if (value.status === StatusCodes.OK) {
            employeeVerifyQuestionReturn().then((value) => {
              if (value.status === StatusCodes.OK) {
                if (value.data.isVerified) {
                  setExpire('');
                } else {
                  setExpire("Ce lien n'est plus disponible");
                }
                setAccess(true);
                setLoadingAbsence(false);
              }
            });
          }
        });
      }
    } catch {
      setAccess(false);
      setLoadingAbsence(false);
    }
  }, [loading, decode]);

  return (
    <RequireLogin>
      <div>
        <Navbar />
        {loading && !user && loadingAbsence ? (
          <div className="container-fluid container-loading">
            <h2>Veuillez patienter</h2>
            <div className="container">
              <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
            </div>
          </div>
        ) : (
          <div>
            {expire ? (
              <div className="container-fluid mt-5">
                <h1 className="question-form mb-5">{expire}</h1>
              </div>
            ) : (
              <div>
                {access ? (
                  <div className="container-fluid mt-5">
                    <h1 className="question-form mb-5">{`Questionnaire de retour à l'emploi`}</h1>

                    <h4 className="subtitle-services text-center">
                      {' '}
                      Bonjour, vous êtes revenu sur votre lieu de travail. Comment vous sentez-vous ?
                    </h4>

                    <form className="form-legacy form-declaration form-declaration col-lg-4">
                      <label>
                        <select
                          className="form-select"
                          name="retour"
                          value={etatRetour}
                          onChange={(e) => {
                            setEtatRetour(e.target.value);
                            const index = e.nativeEvent.target.selectedIndex;
                            setEtatRetourLabel(e.nativeEvent.target[index].text);
                          }}
                          required
                        >
                          <option value={1}>Je me sens bien à la situation</option>
                          <option value={2}>Je suis indifférent à la situation</option>
                          <option value={3}>Je ne me sens pas très bien à la situation</option>
                        </select>
                      </label>
                    </form>

                    {/* A afficher dans le cas 3s*/}
                    {etatRetour === '3' && (
                      <div className="type1-at container-fluid">
                        <h4 className="question-form mt-5 mb-5 text-center">Pourquoi ?</h4>

                        <form className="form-legacy mb-3 col-lg-4">
                          <label>
                            <select
                              className="form-select"
                              name="retourPourquoi"
                              value={etatRetourPourquoi}
                              onChange={(e) => {
                                setEtatRetourPourquoi(e.target.value);
                                const index = e.nativeEvent.target.selectedIndex;
                                setEtatRetourPourquoiLabel(e.nativeEvent.target[index].text);
                              }}
                              required
                            >
                              <option value={1}>Superman</option>
                              <option value={2}>Patman</option>
                              <option value={3}>Spiderman</option>
                            </select>
                          </label>
                        </form>
                      </div>
                    )}
                    <button type="button" className="btn btn-kerij" onClick={handleClick}>
                      Valider mes réponses
                    </button>
                  </div>
                ) : (
                  <div className="container-fluid mt-5">
                    <h1 className="question-form mb-5">
                      {`Attention ! Vous n'avez pas
                                            l'autorisation d'accéder à cette
                                            page`}
                    </h1>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <NavbarMobile />
        <Footer />
      </div>
    </RequireLogin>
  );
}

export default QuestionnaireRetour;
