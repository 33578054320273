import { AppTextField } from '@/components/AppTextField';
import { InputAdornment, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Search as MUISearch } from '@mui/icons-material';

export interface SearchFieldProps {
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}
export const SearchField = ({ value, placeholder, onChange }: SearchFieldProps) => {
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    const timeoutCallback = setTimeout(() => {
      onChange?.(searchFilter);
    }, 300);
    return () => {
      clearTimeout(timeoutCallback);
    };
  }, [searchFilter]);

  return (
    <AppTextField
      value={value}
      variant="standard"
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        type: 'search',
        style: { marginTop: 0, width: 460, height: 40 },
      }}
      onChange={(_, value) => setSearchFilter(() => value)}
    />
  );
};

const Search = styled(MUISearch)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));
