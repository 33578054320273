import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { StatusCodes } from 'http-status-codes';
import { employeeUpdatePassword } from '../../../../../services/employee';
import { useToast } from '@/components/Toast';

function CheckCodeUI() {
  const toast = useToast();
  const [code, setCode] = useState('');
  const state = useLocation();
  const navigate = useNavigate();
  const handleChange = (code: string) => setCode(code);
  useEffect(() => {
    if (!state.state) {
      navigate('/signup');
    }
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const data = await employeeUpdatePassword({
        idEmployeeLogin: state.state.id_salarie_login,
        email: state.state.email,
        codeValidation: code,
        password: state.state.mot_de_passe,
        idBusiness: state.state.idBusiness,
      });
      if (data.status === StatusCodes.OK || data.status === StatusCodes.NO_CONTENT) {
        toast.present({ message: 'Votre mot de passe a été mis à jour', severity: 'success' });
        navigate('/login', {
          state: { document: state.state.document },
          replace: true,
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="form-legacy form-code mt-5 mb-5">
          <h3 className="question-form mb-5">Code de vérification</h3>

          <div className="msg-code">
            <em>Veuillez entrer le code de vérification</em>
          </div>
          {/* <div className="msg-code"><em>Code envoyé au 06 ** ** ** 73</em> </div> */}

          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={4}
            renderSeparator={<span style={{ width: '10px' }}></span>}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={true}
            inputStyle={{
              width: '3.70rem',
            }}
          />
          <button className="btn-submit">Vérifier</button>
        </form>
      </div>
    </div>
  );
}

export default CheckCodeUI;
