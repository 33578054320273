import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'popstate-direction';

function ChoixDateSalarieFin() {
  return (
    <div>
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">Votre choix de date a bien été pris en compte</h3>

        <form className="form-legacy form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          <div className="col-12">
            {/* <p>Le salarié va maintenant être informé de vos propositions afin de confirmer la date de l’entretien<br/><br/></p>      */}
            <p>Votre choix va être retranscrit aux Ressources Humaines et à votre contact de référence.</p>
          </div>
        </form>
        {/* <div className="position-relative col-12 col-md-8 mt-5 mb-5">
          <button
            type="button"
            className="position-absolute top-0 start-50 translate-middle-x btn btn-kerij"
            onClick={() => {
              window.close();
            }}
          >
            Quitter Kerij
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default ChoixDateSalarieFin;
