import React, { useState, useContext, useEffect } from 'react';
import { Navbar } from '@/components/Navbar/Navbar';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import Footer from '../Footer/Footer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import './DepotDocument.css';
import axios from '../../lib/axios';
import ReactLoading from 'react-loading';
import { useError } from '@/shared/useError';
import { StatusCodes } from 'http-status-codes';
import { verifyToken } from '../../services/auth';

function DepotDocument() {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token_decode');
  const [decode, setDecode] = useState({
    idRhNotification: '',
    idEmployeeInformation: '',
    idAbsence: '',
    dateAction: '',
    idBusiness: '',
  });
  const [apte, setApte] = useState('1');
  const [loading, setLoading] = useState(false);
  const { error, triggerError, clearError } = useError();

  const handleClickCheck = async (event) => {
    setLoading(true);
    event.preventDefault();
    var formData = new FormData();
    formData.set('idAbsence', decode.idAbsence);
    formData.set('apt', apte);
    formData.set('dateAction', decode.dateAction);
    for (let file of files) {
      formData.append('documents', file.file);
      formData.append('type_document', file.type);
    }
    axios
      .post('/api/token/encode', {
        idRhNotification: decode.idRhNotification,
        idBusiness: decode.idBusiness,
        idEmployeeInformation: decode.idEmployeeInformation,
      })
      .then((val) => {
        if (val.status === StatusCodes.OK) {
          const token = val.data.token;
          axios
            .post(`/api/rh/upload_medical_document`, formData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((value) => {
              if (value.status === StatusCodes.OK) {
                setLoading(false);
                navigate('/depotdocumentfin');
              } else {
                setLoading(false);
                triggerError("Une erreur s'est produite lors du téléchargement des documents");
              }
            })
            .catch(() => {
              setLoading(false);
              triggerError("Une erreur s'est produite lors du téléchargement des documents");
            });
        }
      });
  };

  const [access, setAccess] = useState(false);

  useEffect(() => {
    if (token) {
      verifyToken({}, token)
        .then((val) => {
          if (val.status === StatusCodes.OK) {
            setDecode(val.data.decode);
          }
        })
        .catch(() => {
          triggerError("Une erreur s'est produite");
        });
    }
  }, []);

  useEffect(() => {
    if (decode.idRhNotification && decode.idEmployeeInformation && decode.idBusiness) {
      axios
        .post('/api/rh/verify_after_visit_info', {
          idRhNotification: decode.idRhNotification,
          idBusiness: decode.idBusiness,
        })
        .then((value) => {
          if (value.data.employee) {
            setAccess(true);
          }
        })
        .catch(() => {
          setLoading(false);
          triggerError("Une erreur s'est produite");
        });
    }
  }, [decode]);

  return (
    <div className="DepotDocument">
      <Navbar />
      {loading ? (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
        </div>
      ) : (
        <div>
          {access ? (
            <div className="container-fluid">
              <h3 className="question-form mt-5 mb-5 text-center">
                Bonjour, veuillez nous transmettre le/les document(s) transmis par le médecin du travail
              </h3>

              <form className="form-legacy form-declaration col-lg-4">
                <h5 className="question-form mb-3 text-center"> Êtes-vous apte à reprendre votre activité ?</h5>
                <label className="col-12 mb-5">
                  <select
                    className="form-select"
                    name="apte"
                    value={apte}
                    onChange={(e) => {
                      setApte(e.target.value);
                      // if (e.target.value !== 3) {
                      //     const index = e.nativeEvent.target.selectedIndex
                      //     setApteLabel(e.nativeEvent.target[index].text)
                      // }
                    }}
                    required
                  >
                    <option value={1}>Je suis apte</option>
                    <option value={2}>Je ne suis pas apte</option>
                  </select>
                </label>
                <h5 className="question-form mb-3 text-center"> Ajouter vos documents juste ici</h5>
                <div className="container-file">
                  <label htmlFor="file" className="label-form-file label-file btn btn-sm col-12">
                    <FontAwesomeIcon icon={faUpload} className="icon-upload"></FontAwesomeIcon> Sélectionner Fichier
                  </label>

                  <input
                    className="form-control form-file input-file"
                    name="document"
                    type="file"
                    id="file"
                    onChange={(event) => {
                      const allowedTypes = event.target.accept.split(', ');
                      let allowedFiles = [...event.target.files]
                        .filter((value) => allowedTypes.includes(value.type))
                        .map((value) => {
                          return {
                            file: value,
                            status: -1,
                          };
                        });
                      setFiles([...files, ...allowedFiles]);
                      setIsActive(false);
                      document.getElementById('file').value = '';
                    }}
                    accept="image/jpeg, image/png, application/pdf"
                    multiple
                  />
                </div>
                <div className="text-center mb-1 text-muted small">
                  <em>Taille des fichiers : 5 Mo maximum</em>
                </div>
              </form>

              <div
                className={
                  isActive ? 'information-document col-lg-6 row mb-5' : 'information-document col-lg-6 row mb-5 d-none'
                }
              ></div>
              {files.length !== 0 && <div>Fichier sélectionné :</div>}
              {files.map((value, index) => {
                return (
                  <div
                    key={index}
                    className="file-container col-lg-4"
                    style={{
                      backgroundColor:
                        (value.status === -1 && 'transparent') ||
                        (value.status === 1 && 'green') ||
                        (value.status === 0 && 'red'),
                    }}
                    onClick={() => {
                      const src = URL.createObjectURL(value.file);
                      window.open(src, '_blank');
                    }}
                  >
                    <FontAwesomeIcon icon={faFile} className="file-icon"></FontAwesomeIcon>
                    <div className="file-name">{value.file.name}</div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="close-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        const newFiles = files.filter((v, i) => i !== index);
                        setFiles(newFiles);
                      }}
                    ></FontAwesomeIcon>
                  </div>
                );
              })}

              <div className="position-relative col-12 col-md-8 text-center mb-3">
                <button
                  type="button"
                  className={
                    isActive || files.length > 0
                      ? 'btn btn-kerij col-6 col-lg-3'
                      : 'btn btn-kerij col-6 col-lg-3 disabled'
                  }
                  onClick={handleClickCheck}
                >
                  Transmettre les documents
                </button>
              </div>
            </div>
          ) : (
            <div className="container-fluid mt-5">
              <h1 className="question-form mb-5">Attention ! Vous n'avez pas l'autorisation d'accéder à cette page</h1>
            </div>
          )}
        </div>
      )}
      <NavbarMobile />
      <Footer />
    </div>
  );
}

export default DepotDocument;
