const goldenHarvest = {
  50: '#FFF7E5',
  100: '#FDEED0',
  200: '#FDE5B4',
  300: '#FAD896',
  400: '#F7CA77',
  500: '#FCCC69',
  600: '#E3B95E',
  700: '#CA9F52',
  800: '#B28646',
  900: '#9A6E3A',
  950: '#6A4927',
};

export default goldenHarvest;
