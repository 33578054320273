import React from 'react';
import { Box, Stepper, styled } from '@mui/material';
import { type RHStep } from './data';
import { RHStepperStepConnector } from './RHStepperStepConnector';
import { RHStepperStep } from './RHStepperStep';

const componentName = 'RHStepper';

export interface RHStepperProps {
  activeStep: number;
  steps: Array<RHStep>;
}

export function RHStepper(props: RHStepperProps) {
  const { activeStep, steps } = props;
  return (
    <StepperContainer className={componentName}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<RHStepperStepConnector />}
        sx={{ justifyContent: 'center' }}
      >
        {steps.map((step, index) => {
          const icon = step.avatar ?? `${index}`;
          return <RHStepperStep key={index} title={step.title} icon={icon} />;
        })}
      </Stepper>
    </StepperContainer>
  );
}

const StepperContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2.5),
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[1],
  border: '1px solid',
  borderColor: theme.shape.borderColorVariant,
  borderRadius: theme.shape.borderRadius,
}));
