import * as React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { AppTextField } from '../AppTextField';
import { parcoursCreate } from '@/services/rh/parcours';
import { PageFormBody, PageFormHelperText } from '@/components/Page';

export interface ParcoursProgramStepProps {
  value: parcoursCreate.Parameters;
  onChange: (value: parcoursCreate.Parameters) => void;
}

export const ParcoursProgramStep = (props: ParcoursProgramStepProps) => {
  const { value, onChange } = props;

  return (
    <PageFormBody
      before={
        <PageFormHelperText whiteSpace={'pre-wrap'}>
          {`En première étape, nous vous proposons de donner un titre à votre parcours et de le catégoriser. Ainsi, il sera plus facile pour vous ensuite de le retrouver.`}
        </PageFormHelperText>
      }
    >
      <AppTextField
        label={'Nom du parcours'}
        value={value.name}
        onChange={(_, newName) => {
          onChange({ ...value, name: newName });
        }}
      />
      <FormControl>
        <FormLabel>Type de parcours</FormLabel>
        <RadioGroup
          value={value.legal}
          defaultValue={false}
          onChange={(_, legalString) => {
            onChange({ ...value, legal: legalString === 'true' });
          }}
        >
          {[
            { value: 'false' as const, label: 'Management' },
            { value: 'true' as const, label: 'Légal' },
          ].map((option) => {
            return (
              <FormControlLabel
                key={String(option.value)}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </PageFormBody>
  );
};
