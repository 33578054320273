import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'popstate-direction';
import 'moment/locale/fr';

function FicheEntretienFin() {
  return (
    <div>
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">La fiche d'entretien a bien été envoyé</h3>

        <form className="form-legacy form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          <div className="col-12">
            <p>
              Le salarié et vous allez recevoir un mail de conclusion de l'entretien avec la fiche complétée
              précedemment en pièce jointe.
              <br />
              <br />
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FicheEntretienFin;
