const aquaSerenity = {
  50: '#EFFEFE',
  100: '#D8FFFF',
  200: '#BFFBFB',
  300: '#A7F6F6',
  400: '#90F0F0',
  500: '#70E3E3',
  600: '#56D2D2',
  700: '#40C9C9',
  800: '#38A0A0',
  900: '#2F7777',
  950: '#245555',
};

export default aquaSerenity;
