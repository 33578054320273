import { Box, Typography } from '@mui/material';
import CustomModal from '../../../../../../base/CustomModal';
import React from 'react';
import { ActionButton } from '@/components/ActionButton';

interface IntegrationFailedModalProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onReExport?: () => void;
}

export const IntegrationFailedModal = ({ visible, setVisible, onReExport }: IntegrationFailedModalProps) => {
  return (
    <CustomModal
      open={visible || false}
      setOpen={setVisible}
      renderHeader={() => `Relancer l'export`}
      renderFooter={() => (
        <Box textAlign="right">
          <ActionButton actionName="cancel" variant="text" onClick={() => setVisible(false)} sx={{ mr: 0.5 }} />
          <ActionButton actionName="validate" label={'Confirmer'} onClick={onReExport} />
        </Box>
      )}
      renderContent={() => (
        <Box sx={{ minHeight: 200, display: 'inline-flex' }}>
          <Box
            sx={(theme) => ({
              margin: 'auto',
              color: theme.palette.primaryBackground.contrastText,
            })}
          >
            <Typography fontWeight={600} fontSize={14} mb={1.5}>
              {`Vous êtes sur le point de relancer l'export de l'absence.`}
            </Typography>

            <Typography fontSize={14}>
              {`Assurez-vous d'avoir réalisé les actions nécessaires dans le logiciel des temps.`}
            </Typography>
          </Box>
        </Box>
      )}
    />
  );
};
