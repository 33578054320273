import React, { ComponentProps } from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
  SelectProps,
  Box,
  Chip,
  styled,
} from '@mui/material';
import { AppSelectItem } from './data';
import { AppInputLabel } from '../AppInputLabel';
import { AppInput } from '../AppInput';

type InputValue = string[] | undefined;

export interface AppSelectMultipleProps extends Omit<ComponentProps<typeof Select<InputValue>>, 'onChange'> {
  label?: React.ReactNode;
  items: Array<AppSelectItem<string>>;
  helperText?: string;
  error?: boolean;
  LabelProps?: React.ComponentProps<typeof InputLabel>;
  onChange?: (event: SelectChangeEvent<'' | InputValue>, value: InputValue) => void;
  render?: 'string' | 'chip';
}

export const AppSelectMultiple = React.forwardRef(function AppSelectMultiple(
  props: AppSelectMultipleProps,
  ref: React.ForwardedRef<typeof Select<InputValue>>,
) {
  const TextFieldProps: Pick<AppSelectMultipleProps, 'disabled' | 'readOnly' | 'required' | 'error'> = {
    disabled: props.disabled,
    error: props.error,
    readOnly: props.readOnly,
    required: props.required,
  };

  const { label, items, value, helperText: helper, LabelProps, render, onChange, ...rootProps } = props;

  const changeValue = (event: SelectChangeEvent<SelectProps<InputValue>['value']>) => {
    const { target } = event;
    const newValues = typeof target.value === 'string' ? target.value.split(',') : target.value;
    onChange?.(event, newValues);
  };

  return (
    <FormControl size="small" sx={{ width: '100%' }} {...TextFieldProps}>
      <AppInputLabel shrink {...LabelProps} style={{ top: '7px', left: '-14px' }}>
        {label}
      </AppInputLabel>
      <Select
        ref={ref}
        {...rootProps}
        multiple
        value={value}
        onChange={changeValue}
        input={<AppInput />}
        renderValue={(selected) =>
          render === 'chip' ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <SelectChip key={value} label={items.find((item) => item.value === value)?.label} />
              ))}
            </Box>
          ) : (
            items
              .filter((item) => selected.includes(item.value))
              .map((item) => item.label)
              .join(', ')
          )
        }
      >
        {items.map((item) => (
          <MenuItem value={item.value} key={item.value} disabled={item.disabled}>
            <Checkbox checked={value?.includes(item.value)} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText sx={{ height: 16, marginTop: 0.5 }}>{helper ?? ' '}</FormHelperText>
    </FormControl>
  );
});

const SelectChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primaryBackground.main,
  color: theme.palette.primaryBackground.contrastText,
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  borderWidth: 1,
  fontWeight: 'bold',
}));
