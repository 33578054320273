import React, { ForwardedRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  type DialogProps,
  type ButtonProps,
} from '@mui/material';
import { ActionButton } from '@/components/ActionButton';

export interface ConfirmDialogProps extends Omit<DialogProps, 'title' | 'content'> {
  title?: React.ReactNode;
  content?: React.ReactElement | string;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ConfirmDialog = React.forwardRef(function ConfirmDialog(
  props: ConfirmDialogProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { title, open, content, onConfirm, onCancel, onClose, ...otherProps } = props;
  const handleConfirm: ButtonProps['onClick'] = (event) => {
    onConfirm?.(event);
    // if (!event.isDefaultPrevented) {
    onClose?.(event, 'backdropClick');
    // }
  };
  const handleCancel: ButtonProps['onClick'] = (event) => {
    onCancel?.(event);
    // if (!event.isDefaultPrevented) {
    onClose?.(event, 'backdropClick');
    // }
  };
  return (
    <Dialog ref={ref} open={open} onClose={onClose} {...otherProps}>
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ActionButton actionName="cancel" onClick={handleCancel} />
        <ActionButton actionName="ok" color="primary" onClick={handleConfirm} autoFocus />
      </DialogActions>
    </Dialog>
  );
});
