import * as React from 'react';
import classNames from 'clsx';
import { Avatar, Box, type BoxProps, Divider, styled, Typography } from '@mui/material';

// Name and options
const componentName = 'PageTitle';

// Component properties
export interface PageTitleProps extends Omit<BoxProps, 'title'> {
  /**
   * Avatar element
   */
  avatar?: React.ReactNode;
  /**
   * Title element
   */
  title: React.ReactNode;
  /**
   * Sub title element
   */
  subtitle?: React.ReactNode;
  divider?: boolean;
}

/**
 * @example
 * <PageTitle
 *   avatar={<Avatar />}
 *   title={'This is a title'}
 *   subtitle={'This is a subtitle'}
 * />
 * @param props
 */
export function PageTitle(props: PageTitleProps) {
  const { className, title, subtitle, avatar, divider, ...otherProps } = props;
  const hasSubtitle = React.Children.count(subtitle) !== 0;

  const rootElement = (
    <Root
      className={classNames(
        componentName,
        {
          [`${componentName}--multiline`]: hasSubtitle,
        },
        className,
      )}
      paddingTop={0.75}
      paddingBottom={2.5}
      {...otherProps}
    >
      {React.Children.count(avatar) === 0 ? undefined : (
        <Box className={`${componentName}__Avatar`}>
          <Avatar>{avatar}</Avatar>
        </Box>
      )}
      <Box flex={1} className={`${componentName}__TextBox`}>
        <Typography variant="h6" color="primary" className={`${componentName}__Title`}>
          {title}
        </Typography>
        {hasSubtitle ? (
          <Typography
            variant="body2"
            color={(theme) => theme.palette.text.secondary}
            className={`${componentName}__Subtitle`}
          >
            {subtitle}
          </Typography>
        ) : undefined}
      </Box>
    </Root>
  );
  return divider ? (
    <>
      {rootElement}
      <Divider />
    </>
  ) : (
    rootElement
  );
}

const Root = styled(Box, {
  name: componentName,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1.5),

  [`& .${componentName}__Avatar`]: {
    display: 'flex',
    height: '100%',
    aspectRatio: 1,
  },
  [`& .${componentName}__Title`]: {
    lineHeight: 'normal',
  },
  [`& .${componentName}__Subtitle`]: {
    color: theme.palette.primary.dark,
  },
}));
