import React from 'react';
import { SearchField } from '../../../shared/components/Form';
import { Box } from '@mui/material';

export const FilterSortAbsences = ({ setSearch }) => {
  return (
    <Box ml={2.5} mr={2.5} display="flex">
      <Box>
        <SearchField
          onChange={(searchString) => setSearch(searchString)}
          placeholder="Par nom, par prénom ou par matricule"
        />
      </Box>
    </Box>
  );
};
