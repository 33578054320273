/**
 * Ce input est une representation du TextField de MUI
 * Pour les différentes partie d'un TextField custom :
 * @see https://mui.com/material-ui/react-text-field/#components
 *
 * Au besoin pour des cas particulier, un Composant permet
 * d'encapsuler un vieux input de l'app pour en faire un AppTextField
 * @path ../AppTextFieldHelper
 *
 * Pour des TextField de type Select
 * @path ../AppSelect
 */
import * as React from 'react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBaseProps,
  InputLabelProps,
  InputProps as MUIInputProps,
  TextFieldProps,
} from '@mui/material';
import { ForwardedRef } from 'react';
import { InputProps, useInput } from '@/hooks/useInput';
import { AppInputLabel } from '@/components/AppInputLabel';
import { AppInput } from '@/components/AppInput';

type InputValue = string;

export interface AppTextFieldProps
  extends Omit<TextFieldProps, keyof InputProps<HTMLInputElement | HTMLTextAreaElement, InputValue>>,
    InputProps<HTMLInputElement | HTMLTextAreaElement, InputValue> {
  InputProps?: MUIInputProps;
  LabelProps?: InputLabelProps;
  variant?: FormControlProps['variant'];
}

export const AppTextField = React.forwardRef(function AppTextField(
  props: AppTextFieldProps,
  ref: ForwardedRef<Element>,
) {
  const TextFieldProps: Pick<AppTextFieldProps, 'disabled' | 'readOnly' | 'required' | 'error'> = {
    disabled: props.disabled,
    error: props.error,
    readOnly: props.readOnly,
    required: props.required,
  };

  const { useRootProps, useSlotProps } = useInput({ componentName: 'AppTextField', props, ref });
  const { label, placeholder, helperText, InputProps, LabelProps, multiline, maxRows, variant, ...rootProps } =
    useRootProps(props);
  const inputSlotProps = {
    ...useSlotProps(),
    multiline,
    maxRows,
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = React.useCallback(
    (event) => {
      inputSlotProps.onChange(event, event.target.value);
    },
    [inputSlotProps.onChange],
  );

  const formControlProps: FormControlProps = { ...rootProps, ...TextFieldProps };
  const labelProps: InputLabelProps = { shrink: true, ...LabelProps };
  const inputProps: MUIInputProps = {
    ...inputSlotProps,
    ...TextFieldProps,
    ...InputProps,
    onChange: handleChange,
  };

  const variantInputProps: Pick<InputBaseProps, 'sx'> =
    variant == 'standard'
      ? {
          sx(theme) {
            return {
              borderColor: theme.shape.borderColor,
              boxShadow: theme.shadows[1],
            };
          },
        }
      : {};

  return (
    <FormControl variant="standard" {...formControlProps} sx={{ width: '100%' }}>
      <AppInputLabel shrink {...labelProps}>
        {label}
      </AppInputLabel>
      <AppInput {...inputProps} {...variantInputProps} placeholder={placeholder} />
      <FormHelperText sx={{ height: 16, marginTop: 0.5 }}>{helperText ?? ' '}</FormHelperText>
    </FormControl>
  );
});
