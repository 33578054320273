import * as React from 'react';
import classNames from 'clsx';
import { Descendant, Editor, Element as SlateElement, Node as SlateNode } from 'slate';
import { ReactEditor, Slate } from 'slate-react';
import { MarkdownShortcuts } from './withShortcut';
import type { EditableProps } from 'slate-react/dist/components/editable';
import { useTextEditor } from './useTextEditor';
import { useHTMLRender } from './useHTMLRender';
import { MUIEditable } from './MUIEditable';
import { useHTMLSerializer } from './useHTMLSerializer';
import { MUIToolbar } from './MUIToolbar';
import { Paper } from '@mui/material';

// Name and options
const componentName = 'MarkdownEditor';

// Component properties
export interface HTMLEditorProps extends EditableProps {
  /**
   * Markdown initial content
   */
  initialValue?: string;
  /**
   * Markdown initial content
   */
  onValueChange?: (value: string) => void;
  /**
   * Element to be displayed before editable
   */
  beforeEditable?: React.ReactNode;
  /**
   * Element to be displayed after editable
   */
  afterEditable?: React.ReactNode;
}

export function HTMLEditor(props: HTMLEditorProps) {
  const {
    className,
    initialValue: initialValueProps,
    onValueChange,
    beforeEditable,
    afterEditable,
    ...otherProps
  } = props;
  const markdownSerializer = useHTMLSerializer();
  const markdownRender = useHTMLRender();
  const editor = useTextEditor();
  const initialValue = React.useMemo(
    () => (initialValueProps == null ? [] : markdownSerializer.parse(initialValueProps)),
    [initialValueProps],
  );
  const handleChange = React.useCallback((value: Descendant[]): void => {
    const isASTChange = editor.operations.some((op) => 'set_selection' !== op.type);
    if (isASTChange) {
      // Save the value to Local Storage.
      const content = markdownSerializer.stringify(value);
      onValueChange?.(content);
    }
  }, []);

  const handleDOMBeforeInput = React.useCallback(
    (e: InputEvent) => {
      queueMicrotask(() => {
        const pendingDiffs = ReactEditor.androidPendingDiffs(editor);

        const scheduleFlush = pendingDiffs?.some(({ diff, path }) => {
          if (!diff.text.endsWith(' ')) {
            return false;
          }

          const { text } = SlateNode.leaf(editor, path);
          const beforeText = text.slice(0, diff.start) + diff.text.slice(0, -1);
          if (!(beforeText in MarkdownShortcuts)) {
            return;
          }

          const blockEntry = Editor.above(editor, {
            at: path,
            match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
          });
          if (!blockEntry) {
            return false;
          }

          const [, blockPath] = blockEntry;
          return Editor.isStart(editor, Editor.start(editor, path), blockPath);
        });

        if (scheduleFlush) {
          ReactEditor.androidScheduleFlush(editor);
        }
      });
    },
    [editor],
  );

  return (
    <Paper
      elevation={1}
      sx={(theme) => ({
        border: '1px solid',
        borderColor: theme.shape.borderColor,
      })}
    >
      <Slate editor={editor} initialValue={initialValue} onChange={handleChange}>
        <MUIToolbar />
        {beforeEditable}
        <MUIEditable
          onDOMBeforeInput={handleDOMBeforeInput}
          {...markdownRender}
          spellCheck
          autoFocus
          className={classNames(componentName, className)}
          {...otherProps}
        />
        {afterEditable}
        {props.children}
      </Slate>
    </Paper>
  );
}
