import axios from '@/lib/axios';
import { AbsenceStatus } from '@/types';
import { AbsenceCode } from '@/types/AbsenceCode';
import { AxiosResponse } from 'axios';

export const absencesExtract = async (params: absencesExtract.Parameters) => {
  return await axios.get<absencesExtract.APIResponse>(`/api/rh/extract/absences`, { params });
};

export namespace absencesExtract {
  export interface Parameters {
    search?: string;
    portfolio?: 'true';
    duration?: string;
    type?: 'not_close' | 'current';
    internal_code_absence?: Array<AbsenceCode>;
    status?: Array<AbsenceStatus>;
  }
  export type Return = AxiosResponse;
  export type APIResponse = {
    Matricule: string;
    Email: string;
    ['Nom prénom']: string;
    ['Date début absence initiale']: string;
    ['Date fin absence en cours']: string;
    ['Initial / Prolongation']: 'Prolongation' | 'Initial';
    ['Nature absence']: string | 'Non défini';
    ['Durée']: string | number;
  }[];
}
