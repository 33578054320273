import React from 'react';
import { Spacer } from '@/components/Spacer';
import { Avatar, Box, Card, CardActionArea, CardContent, Tooltip, Typography } from '@mui/material';
import imgDemat from '../../../assets/Arret_demat.jpg';
import remiseDoc from '../../../assets/remise-doc.png';

export interface DeclarationDocumentCardButtonProps {
  type: 'scanbot' | 'file';
  disable?: boolean;
  tooltip?: React.ReactElement;
  onClick: () => void;
}

export const DeclarationDocumentCardButton = (props: DeclarationDocumentCardButtonProps) => {
  const { type, tooltip, disable, onClick } = props;

  const render = (card: React.ReactElement) => {
    if (tooltip == null) return card;
    return (
      <Tooltip placement="right-start" title={tooltip}>
        {card}
      </Tooltip>
    );
  };

  return render(
    <Card
      elevation={5}
      sx={(theme) => ({
        display: 'flex',
        mb: 2.5,
        minHeight: 112,
        backgroundColor: disable ? theme.palette.grey[50] : undefined,
        opacity: disable ? 0.6 : 1,
      })}
    >
      <CardActionArea
        sx={{ display: 'flex', borderRadius: 0 }}
        onClick={disable ? undefined : onClick}
        disableTouchRipple={disable}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardContent sx={{ flex: '1 0 auto', paddingBottom: '20px !important' }}>
            <Typography
              fontSize={16}
              fontWeight={600}
              sx={(theme) => ({ color: disable ? theme.palette.grey[700] : theme.palette.primary.main })}
            >
              {type === 'scanbot'
                ? `Mon médecin m'a remis un arrêt au format papier`
                : `Vous disposez d'un arrêt dématérialisé`}
            </Typography>

            <Spacer size="small" />

            <Typography
              color="primary"
              fontSize={12}
              sx={(theme) => ({ color: disable ? theme.palette.grey[700] : theme.palette.primary.main })}
            >
              {type === 'scanbot'
                ? `Vous allez utiliser notre outil de scan`
                : `Vous allez déposer un document (au format PDF de préférence)`}
            </Typography>
          </CardContent>
        </Box>

        <Avatar
          sx={(theme) => ({
            width: 80,
            height: 80,
            mr: 2,
            backgroundColor: theme.palette.primaryBackground.main,
          })}
          src={type === 'scanbot' ? remiseDoc : imgDemat}
        ></Avatar>
      </CardActionArea>
    </Card>,
  );
};
