import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { PRO_URL } from '@/constants/urls.constants';

function ProfilValidationRH() {
  return (
    <div>
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">Informations validées</h3>

        <form className="form-legacy form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          <div className="col-12">
            <p>
              Merci. <br /> Vos informations sont validées.
            </p>
          </div>
        </form>
        <Link to={PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS} className="text-center">
          <button type="button" className="btn btn-kerij">
            Accéder au tableau de bord
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ProfilValidationRH;
