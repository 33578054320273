import type { InputProps, TextFieldProps } from '@mui/material';
import {
  type FieldPath,
  type InternalFieldName,
  type FieldValues,
  type Control,
  useController,
  type UseControllerProps,
} from 'react-hook-form';

// == TextField ==
export type UseMUITextFieldRegister<TFieldValues extends FieldValues> = <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  name: TFieldName,
  options?: UseControllerProps<TFieldValues, TFieldName>['rules'],
) => UseMUITextFieldRegisterReturn<TFieldName>;

export interface UseMUITextFieldRegisterReturn<TFieldName extends InternalFieldName = InternalFieldName> {
  required: TextFieldProps['required'];
  disabled: TextFieldProps['disabled'];
  error: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  name: TFieldName;
  onChange: TextFieldProps['onChange'];
  value: TextFieldProps['value'];
  InputProps: Pick<InputProps, 'onBlur'>;
}

// == Wrapper ==

export interface UseMUIField<TFieldValues extends FieldValues> {
  /**
   * Register a TextField input
   */
  registerTextField: UseMUITextFieldRegister<TFieldValues>;
}

/**
 *
 * @example
 * ```ts
 * const { control } = useForm();
 * const { registerTextField } = useMUIField(control);
 *
 * <TextField {...registerTextField('myFieldName')} />
 *
 * ```
 * @param control
 * @returns
 */
export function useMUIField<TFieldValues extends FieldValues, TContext = any>(
  control: Control<TFieldValues, TContext>,
): UseMUIField<TFieldValues> {
  return {
    registerTextField: (name, options) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { field, fieldState } = useController({
        name,
        rules: options,
        control,
      });
      const errorMessage = fieldState.error?.message;

      return {
        required: Boolean(options?.required),
        name: field.name,
        value: field.value,
        disabled: field.disabled,
        error: Boolean(errorMessage),
        onChange: (event: any) => {
          field.onChange(event.target.value);
        },
        // required
        ...(fieldState.error?.message ? { helperText: errorMessage } : {}),
        InputProps: {
          onBlur: field.onBlur,
        },
      };
    },
  };
}
