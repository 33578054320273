import React, { useMemo } from 'react';
import classNames from 'clsx';
import { Box, StepLabel as MUIStepLabel, stepLabelClasses, StepLabelProps, styled, Typography } from '@mui/material';

const componentName = 'RHStepperStepLabel';

export interface RHStepperStepLabelProps extends StepLabelProps {}

export function RHStepperStepLabel(props: RHStepperStepLabelProps) {
  const { className, children, ...stepLabelProps } = props;

  const title = useMemo(() => {
    if (typeof children !== 'string' || children.trim() === '') return children;

    const words = children.split(' ');
    const firstWord = words[0];
    const rest = words.slice(1).join(' ');

    return (
      <Box>
        <Typography fontSize={12} lineHeight="16px" fontWeight={600}>
          {firstWord}
        </Typography>
        <Typography fontSize={12} lineHeight="16px">
          {rest}
        </Typography>
      </Box>
    );
  }, [children]);

  return (
    <StepLabel className={classNames(componentName, className)} {...stepLabelProps}>
      {title}
    </StepLabel>
  );
}

const StepLabel = styled(MUIStepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.alternativeLabel}`]: {
    [`& .${stepLabelClasses.label}`]: {
      marginTop: theme.spacing(0.5),
    },
    [`& .${stepLabelClasses.disabled}`]: {
      color: theme.palette.primary.dark,
    },
  },
}));
