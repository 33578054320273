import React, { useEffect, useRef, useState } from 'react';
import { EmployeeInformation as EmployeeInformationComponent } from '../../../features/dashboard-rh/create-absence-rh/components/EmployeeInformation.component';
import { statementStep } from '../../../features/dashboard-rh/create-absence-rh/constants/step.constants';
import { AbsenceType } from '../../../features/dashboard-rh/create-absence-rh/components/AbsenceType.component';
import { AbsenceType1 } from '../../../features/dashboard-rh/create-absence-rh/components/AbsenceType1.component';
import { AbsenceType2 } from '../../../features/dashboard-rh/create-absence-rh/components/AbsenceType2.component';
import { Date } from '../../../features/dashboard-rh/create-absence-rh/components/Date.component';
import { DocumentUploader } from '../../../components/DashboardRH/DocumentUploader';
import { AbsenceCreation } from '../../../features/dashboard-rh/create-absence-rh/props/step.interface';
import {
  absenceType,
  getAbsenceDescriptionFromTypes,
  toSelect,
} from '../../../features/dashboard-rh/create-absence-rh/constants/absenceType.constant';
import moment from 'moment';
import { IsProlongation } from '../../../features/dashboard-rh/create-absence-rh/components/IsProlongation.component';
import { Absence, DocumentAbsence, DocumentStatus, TypeAbsenceLabels } from '../../../types/absences.type';
import { fetchEmpBusinessCodeAbsence } from '../../../services/employee';
import { createAbsence } from '@/services/rh/absences/service';
import { Box, Button, Paper, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import { getFullName } from '../../../shared/utils/getFullName.service';
import { EmployeeInformation } from '../../../types/user.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { RHAccountContext, useAppSelector } from '@/store';
import { formatDate } from '@/utils/date.utils';
import { PermissionBox } from '@/components/PermissionBox';
import { useToast } from '@/components/Toast';
import { RHPage } from '@/components/RHPage';

const STEP = {
  EMPLOYEE: 0,
  TYPE: 1,
  DATE: 2,
  DOCUMENT: 3,
};
export const CreateAbsencePage = () => {
  const toast = useToast();
  const [activeStep, setActiveStep] = React.useState(0);
  const [createdAbsence, setCreatedAbsence] = useState<Absence | null>();
  const [listDocumentsAbsence, setListDocumentsAbsence] = useState<Array<DocumentAbsence>>();
  const handleNext = async () => {
    if (activeStep + 1 === STEP.DOCUMENT) await handleClick();
    setActiveStep((previousActiveStep) => previousActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((previousActiveStep) => previousActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setNewAbsence(initialState);
    setCreatedAbsence(null);
    setInfosEmployee(null);
  };

  const [infosEmployee, setInfosEmployee] = useState<EmployeeInformation | null>(null);
  const [codeAbsence, setCodeAbsence] = useState<any[]>([]);
  const viewRef = useRef<any>(null);
  const rh = useAppSelector(RHAccountContext.getProfile);
  const [globalStep, setGlobalStep] = useState<statementStep>(statementStep.TypeAbsence);
  const employee = useAppSelector((state) =>
    state.employee.employeeList?.find((employee: EmployeeInformation) => employee.id === infosEmployee?.id),
  );
  const initialState = {
    absenceType: '0',
    dateDebut: '',
    dateFin: '',
    period: [
      {
        dateDebut: '',
        dateFin: '',
        error: -1,
      },
    ],
    files: [],
    isTextArea: false,
    message: '',
    withNoDoc: false,
    prolongation: false,
  };
  const [newAbsence, setNewAbsence] = useState<AbsenceCreation>(initialState);
  const handleChangeStep = (nextStep: statementStep) => {
    setGlobalStep(nextStep);
  };

  const handleChangeInfosEmployee = (employee: any) => {
    setInfosEmployee(employee);
  };

  useEffect(() => {
    if (viewRef.current) {
      viewRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newAbsence]);

  const handleClick = async () => {
    if (!infosEmployee) return;
    const formData = new FormData();
    formData.set('zipcode', infosEmployee.zipcode);
    if (newAbsence.absenceType === absenceType.conge_parentalite.toString()) {
      for (const period of newAbsence.period) {
        formData.append('lastdayWork', formatDate(period.dateDebut));
        formData.append('dateStart', formatDate(period.dateDebut));
        formData.append('dateEnd', period.dateFin ? formatDate(period.dateFin) : '');
      }
    } else {
      formData.append('lastdayWork', formatDate(newAbsence.lastDayWork));
      formData.append('dateStart', formatDate(newAbsence.dateDebut));
      formData.append('dateEnd', newAbsence.dateFin ? formatDate(newAbsence.dateFin) : '');
    }
    formData.set('birthday', newAbsence.dateNaissance ? formatDate(newAbsence.dateNaissance) : '');
    formData.set('declareBy', '1');
    formData.set('dateAction', moment().format('YYYY-MM-DD'));
    formData.set(
      'description',
      newAbsence.absenceType && newAbsence.absenceType !== toSelect.toString() ? newAbsence.absenceType : '',
    );
    formData.set(
      'description1',
      newAbsence.absenceType1 && newAbsence.absenceType1 !== toSelect.toString() ? newAbsence.absenceType1 : '',
    );
    formData.set(
      'description2',
      newAbsence.absenceType2 && newAbsence.absenceType2 !== toSelect.toString() ? newAbsence.absenceType2 : '',
    );
    formData.set('canContact', 'Non');
    formData.set('message', '');
    formData.set(
      'isReductionTime',
      typeof newAbsence.isClickedOption1 === 'boolean' &&
        newAbsence.absenceType === absenceType.conge_maternite.toString()
        ? newAbsence.isClickedOption1.toString()
        : '',
    );
    formData.set(
      'isPromoTime',
      typeof newAbsence.isClickedOption2 === 'boolean' &&
        newAbsence.absenceType === absenceType.conge_maternite.toString()
        ? newAbsence.isClickedOption2.toString()
        : '',
    );
    formData.set('dateEndReduction', newAbsence.dateEndReduction ?? '');
    formData.set('hoursReduction', newAbsence.hoursReduction ?? '');
    formData.set('dateEndPromo', newAbsence.dateEndPromo ?? '');
    formData.set('hasWorkedPlus', newAbsence.isWorkDay ? newAbsence.isWorkDay.toString() : 'false');
    formData.set(
      'actionLevel',
      newAbsence.stateAbsence === '2' && newAbsence.absenceType === TypeAbsenceLabels.ArretDeTravail ? '1' : '0',
    );
    if (newAbsence.absenceType === absenceType.conge_parentalite.toString()) {
      formData.set(
        'isFather',
        newAbsence.relationEnfant === 'pere' ? 'true' : newAbsence.relationEnfant === 'conjoint' ? 'false' : '',
      );
    }
    if (codeAbsence) {
      if (codeAbsence.length === 1) {
        formData.set('codeAbsence', codeAbsence?.at(0)?.code || '');
      } else {
        for (const period in newAbsence.period) {
          if (period === '0') {
            formData.append('codeAbsence', codeAbsence.filter((value) => value.order === 0)?.at(0)?.code || '');
          } else if (['1', '2', '3'].includes(period)) {
            formData.append('codeAbsence', codeAbsence.filter((value) => value.order === 1)?.at(0)?.code || '');
          } else {
            formData.append('codeAbsence', codeAbsence.filter((value) => value.order === 2)?.at(0)?.code || '');
          }
        }
      }
    } else {
      formData.set('codeAbsence', '');
    }
    for (const file of newAbsence.files as File[]) {
      formData.append('documents', file || '');
    }
    formData.append('prolongation', newAbsence.prolongation ? 'true' : 'false');

    try {
      const { data, status } = await createAbsence({
        params: {
          idBusiness: rh?.rh_information?.business?.id ?? infosEmployee?.business?.id,
          idEmployeeInformation: infosEmployee?.id,
        },
        body: formData,
      });
      if (status === StatusCodes.OK) setCreatedAbsence(data.absence);
    } catch {
      toast.present({ message: 'Une erreur est survenue à la création', severity: 'error' });
    }
  };

  useEffect(() => {
    const fetchCodeAbsence = async () => {
      const response = await fetchEmpBusinessCodeAbsence({
        isOption1: newAbsence.isClickedOption1 || null,
        isOption2: newAbsence.isClickedOption2 || null,
        description: newAbsence.absenceType,
        description1: newAbsence.absenceType1,
        description2: newAbsence.absenceType2,
        idBusiness: infosEmployee?.business?.id,
      });
      if (response.status === StatusCodes.OK) {
        setCodeAbsence(response.data.code);
      }
    };

    if (infosEmployee?.business.id) {
      fetchCodeAbsence();
    }
  }, []);

  const steps = [
    {
      label: 'Rechercher un employé',
      description: <EmployeeInformationComponent value={infosEmployee} onChange={handleChangeInfosEmployee} />,
    },
    {
      label: "Définir le type d'absence",
      description: (
        <>
          {infosEmployee && (
            <AbsenceType
              absence={newAbsence}
              setAbsence={setNewAbsence}
              handleChangeStep={handleChangeStep}
              infosEmployee={infosEmployee}
            />
          )}
          {infosEmployee && globalStep >= statementStep.TypeAbsence1 ? (
            <div className={'col-12'}>
              <AbsenceType1
                absence={newAbsence}
                setAbsence={setNewAbsence}
                handleChangeStep={handleChangeStep}
                infosEmployee={infosEmployee}
              />
            </div>
          ) : (
            <></>
          )}
          {infosEmployee && globalStep >= statementStep.IsProlongation ? (
            <div className={'col-12'}>
              <IsProlongation absence={newAbsence} setAbsence={setNewAbsence} handleChangeStep={handleChangeStep} />
            </div>
          ) : (
            <></>
          )}
          {infosEmployee && globalStep >= statementStep.TypeAbsence2 ? (
            <div className={'col-12'}>
              <AbsenceType2
                absence={newAbsence}
                setAbsence={setNewAbsence}
                handleChangeStep={handleChangeStep}
                infosEmployee={infosEmployee}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      label: 'Renseigner la période',
      description: (
        <>
          {infosEmployee && globalStep >= statementStep.Date ? (
            <div className={'col-12'}>
              <Date
                absence={newAbsence}
                setAbsence={setNewAbsence}
                handleChangeStep={handleChangeStep}
                infosEmployee={infosEmployee}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      label: 'Déposer les justificatifs',
      description: (
        <>
          {globalStep >= statementStep.Document &&
          createdAbsence &&
          createdAbsence.employee_information &&
          infosEmployee &&
          createdAbsence ? (
            <div className={'col-12'}>
              <DocumentUploader
                absence={createdAbsence}
                onDocumentsChanged={(documents) => {
                  setListDocumentsAbsence(documents);
                }}
                infosEmployee={createdAbsence.employee_information}
                status={DocumentStatus.APPROVED}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];
  return (
    // <PageTitle label={'Créer une absence'} />

    <PermissionBox scope="absence-management" action="edit">
      <RHPage title="Créer une absence">
        {globalStep >= statementStep.TypeAbsence ? (
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === STEP.EMPLOYEE && infosEmployee ? (
                      <Typography variant="caption">{getFullName(infosEmployee)}</Typography>
                    ) : index === STEP.TYPE ? (
                      <Typography variant="caption">
                        {getAbsenceDescriptionFromTypes({
                          absenceType: newAbsence?.absenceType || '',
                          absenceType1: newAbsence?.absenceType1 || '',
                          absenceType2: newAbsence?.absenceType2 || '',
                        }) || ''}
                      </Typography>
                    ) : index === STEP.DATE ? (
                      <Typography variant="caption">
                        {newAbsence?.dateDebut ? moment(newAbsence.dateDebut).format('DD/MM/YYYY') : ''}

                        {newAbsence?.dateFin
                          ? ` au

                                                            ${moment(newAbsence.dateFin).format('DD/MM/YYYY')}
                                                            `
                          : ''}
                      </Typography>
                    ) : index === STEP.DOCUMENT ? (
                      <Typography variant="caption">Dernière étape</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  {step.description}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        disabled={
                          (index === STEP.EMPLOYEE && !infosEmployee) ||
                          (index === STEP.TYPE && globalStep < statementStep.Date) ||
                          (index === STEP.DATE && globalStep < statementStep.Document) ||
                          (index === STEP.DOCUMENT && globalStep !== statementStep.Document)
                        }
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1
                          ? listDocumentsAbsence?.length === 0
                            ? 'Je n’ai pas de justificatif'
                            : 'Terminer'
                          : 'Suivant'}
                      </Button>
                      {index < STEP.DOCUMENT && (
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{
                            mt: 1,
                            mr: 1,
                          }}
                        >
                          Précédent
                        </Button>
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        ) : (
          <></>
        )}
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }} className={'text-center'}>
            <h3 className="question-form mt-5 mb-5 ">Déclaration Terminée</h3>
            <div className="declaration-check mb-5">
              <FontAwesomeIcon className="fa-5x" icon={faCheck} />
            </div>
            <Typography>
              Votre déclaration a bien été transmise et le manager informé de l’absence.
              <br />
              {employee.employee_login
                ? 'Le collaborateur va recevoir une notification'
                : 'Le collaborateur ne pourra pas être notifié'}
            </Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Créer un nouvel arrêt
            </Button>
          </Paper>
        )}
        <div ref={viewRef} />
      </RHPage>
    </PermissionBox>
  );
};
