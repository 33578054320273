import React from 'react';
import classNames from 'clsx';
import { StepIconProps, styled } from '@mui/material';

const componentName = 'RHStepperStepIcon';

export interface RHStepperStepIconProps extends StepIconProps {}

export function RHStepperStepIcon(icon: RHStepperStepIconProps['icon']) {
  return function render(props: RHStepperStepIconProps) {
    const { active, completed, className } = props;
    return (
      <StepIconRoot
        sx={{ fontSize: active || completed ? 18 : 14 }}
        ownerState={{ completed, active }}
        className={classNames(componentName, className)}
      >
        {icon}
      </StepIconRoot>
    );
  };
}

const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  borderRadius: '50%',
  border: '1px solid',
  borderColor: theme.shape.borderColorVariant,
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.primary.dark,
  width: theme.spacing(3.75),
  height: theme.spacing(3.75),
  justifyContent: 'center',
  alignItems: 'center',
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundColor: theme.palette.aquaSerenity[200],
        borderColor: theme.palette.aquaSerenity[200],
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: theme.palette.aquaSerenity[200],
        borderColor: theme.palette.aquaSerenity[200],
      },
    },
  ],
}));
