import * as React from 'react';
import { useSlate } from 'slate-react';
import { ToolbarButton, type ToolbarButtonProps } from './ToolbarButton';
import Link from '@mui/icons-material/Link';
import { Dialog, DialogTitle, DialogContent, DialogActions, type DialogProps, TextField, Box } from '@mui/material';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import { ActionButton, ActionButtonProps } from '@/components/ActionButton';
import { Editor } from 'slate';
import { isBlockActive } from '../editor/isBlockActive';
import { LinkOff } from '@mui/icons-material';
import { unwrapBlock } from '../editor/unwrapBlock';
import { wrapLink } from '../editor/wrapLink';
import { currentURL } from '../editor/currentURL';
import { isValidURL } from '../editor/isValidURL';
import { useForm } from 'react-hook-form';
import { useMUIField } from '@/hooks/useMUIField';

export interface LinkButtonProps extends Omit<ToolbarButtonProps, 'icon'> {}

/**
 * Toolbar button for adding links
 *
 * @see ToolbarButton
 */
export function LinkButton({ ref, onMouseDown, ...props }: LinkButtonProps) {
  const editor = useSlate();
  // Handles the dialog that is opened upon clicking the Link Toolbar/HoveringBar button
  const modalController = useMUIDialogController();
  const modalProps = modalController.getRootProps();
  const selected = isBlockActive(editor, 'link', 'type');

  // Handles custom buttons click
  const handleMouseDown: LinkButtonProps['onMouseDown'] = (event) => {
    // When the dialog box is opened, the focus is lost as well as current selected text.
    // We need to save it for later on.
    //editor.rememberCurrentSelection();
    modalController.control.setOpen(true);
    onMouseDown?.(event);
  };
  const handleDialogSave: LinkDialogProps['onSave'] = (url) => {
    if (url == null || url.length === 0) {
      unwrapBlock(editor, 'link');
    } else {
      // Adds the link to the editor.
      // The link will wrap the selected text when `rememberCurrentSelection()` was called
      insertLink(editor, url);
    }
  };
  const handleDialogDelete: LinkDialogProps['onDelete'] = () => {
    unwrapBlock(editor, 'link');
  };

  return (
    <React.Fragment>
      <ToolbarButton
        icon={<Link />}
        tooltip="Lien"
        ref={ref}
        onMouseDown={handleMouseDown}
        selected={selected}
        {...props}
      />
      {modalProps.open ? (
        <LinkDialog
          {...modalProps}
          defaultValue={currentURL(editor)}
          title="Editer un lien"
          onClose={() => modalController.control.setOpen(false)}
          onSave={handleDialogSave}
          onDelete={handleDialogDelete}
          deleteButtonProps={{
            disabled: !selected,
          }}
        />
      ) : undefined}
    </React.Fragment>
  );
}

export interface LinkDialogProps extends DialogProps {
  title?: string;
  defaultValue?: string;
  onSave?: (value: string | undefined) => void;
  onDelete?: () => void;
  deleteButtonProps?: ActionButtonProps;
}

export function LinkDialog(props: LinkDialogProps) {
  const { title, defaultValue, onClose, onSave, onDelete, deleteButtonProps, ...otherProps } = props;
  const form = useForm({
    mode: 'onTouched',
    defaultValues: {
      linkURL: defaultValue,
    },
  });
  const { registerTextField } = useMUIField(form.control);
  const linkURLField = registerTextField('linkURL', {
    required: true,
    validate: {
      url: (value) => value == null || isValidURL(value) || "Ce champs n'est pas une adresse valide",
    },
  });

  //Calls `onCancel` prop and sets the default value
  const handleClose: LinkDialogProps['onClose'] = (event, reason) => {
    onClose?.(event, reason);
    form.reset();
  };
  const handleDelete = (event: any) => {
    onDelete?.();
    handleClose(event, 'backdropClick');
  };
  // Calls `onSave` prop and sets the default value
  const handleSubmit = form.handleSubmit((data) => {
    onSave?.(data.linkURL);
    handleClose({} as any, 'backdropClick');
  });
  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
      {...otherProps}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="URL du lien"
          fullWidth
          autoFocus
          variant="outlined"
          sx={{ marginTop: 2, minWidth: '300px' }}
          helperText="ex: https://mycompany.com"
          {...linkURLField}
        />
      </DialogContent>
      <DialogActions>
        <ActionButton
          onClick={handleDelete}
          actionName="delete"
          startIcon={<LinkOff />}
          color="error"
          {...deleteButtonProps}
        />
        <Box sx={{ flex: 1 }} />
        <ActionButton onClick={(event) => handleClose(event, 'escapeKeyDown')} actionName="cancel" />
        <ActionButton color="primary" actionName="ok" type="submit" disabled={!form.formState.isValid} />
      </DialogActions>
    </Dialog>
  );
}

const insertLink = (editor: Editor, url: string | undefined) => {
  if (!url) return;
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
