import moment from 'moment';
import { useError } from '@/shared/useError';
import React, { useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { verifyAbsenceDate } from '@/services/rh/absences/service';
import { AbsenceDateRange } from '@/components/AbsenceDatesRange';
import { isEmptyDate, isInvalideDate, isToLate } from '@/components/AbsenceDatesRange/data';
import { generateCrossoverMessage } from '@/features/declaration/utils/generateCrossoverMessage';
import { AbsenceCreation, StepProperties } from '../../props/step.interface';
import { statementStep } from '../../constants/step.constants';

export const AbsenceDate = ({ absence, setAbsence, handleChangeStep, infosEmployee }: StepProperties) => {
  const { error, triggerError, clearError } = useError();

  const [canSubmit, setCanSubmit] = useState(false);

  const verifyDates = async (value: AbsenceCreation, idEmployeeInformation?: string) => {
    const { dateDebut, dateFin, absenceType } = value;
    if (isInvalideDate(dateDebut)) return triggerError('Le format de la date de début est incorrect');
    if (isInvalideDate(dateFin)) return triggerError('Le format de la date de fin est incorrect');
    if (isToLate(dateDebut)) return triggerError('Vous ne pouvez pas déclarer une absence à plus de 2 ans');
    if (isToLate(dateFin)) return triggerError('Vous ne pouvez pas déclarer une absence à plus de 2 ans');
    if (isEmptyDate(dateDebut) || isEmptyDate(dateFin)) return;

    const momentDateStart = moment(dateDebut).startOf('day');
    const momentDateEnd = moment(dateFin).startOf('day');
    if (momentDateEnd.isBefore(momentDateStart))
      return triggerError('La date de fin doit être supérieure ou égale à la date de début');

    if (absenceType == null || idEmployeeInformation == null)
      return triggerError('Une erreur est survenue, sélectionnez de nouveau le salarié');

    const crossover = await checkAbsenceCrossover(value, {
      dateStart: dateDebut,
      dateEnd: dateFin,
      idEmployeeInformation: idEmployeeInformation,
    });

    if (crossover) return triggerError(crossover);
    else clearError();
  };

  const checkAbsenceCrossover = async (
    value: AbsenceCreation,
    params: { dateStart: string; dateEnd: string; idEmployeeInformation: string },
  ) => {
    const response = await verifyAbsenceDate({ ...value, ...params });

    if (response?.status === StatusCodes.OK) return;
    return response?.status === StatusCodes.CONFLICT
      ? generateCrossoverMessage(response.data)
      : 'Une erreur est survenue lors de la vérification';
  };

  useEffect(() => {
    if (!canSubmit || error || isEmptyDate(absence.dateDebut) || isEmptyDate(absence.dateFin))
      handleChangeStep(statementStep.Date);
    else handleChangeStep(statementStep.Document);
  }, [absence, error, canSubmit]);

  return (
    <div className="container-fluid">
      <div className="my-3 col-md-8 col-lg-6  text-center">
        <AbsenceDateRange
          error={error}
          value={{
            dateStart: absence.dateDebut,
            dateEnd: absence.dateFin,
            lastDayWork: absence.lastDayWork,
            absence: absence,
          }}
          onChange={(value) => {
            setAbsence((absence: AbsenceCreation) => ({
              ...absence,
              dateDebut: value.dateStart,
              dateFin: value.dateEnd,
              lastDayWork: value.lastDayWork,
              withNoDoc: false,
            }));
          }}
          callback={async () => verifyDates(absence, infosEmployee.id)}
          onChangeSubmitStatus={setCanSubmit}
        />
      </div>
    </div>
  );
};
