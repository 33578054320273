const sunsetEmber = {
  50: '#FFF2E8',
  100: '#FFE1D1',
  200: '#FFCCB3',
  300: '#FFB599',
  400: '#FF9F80',
  500: '#FF8039',
  600: '#E67133',
  700: '#CC612D',
  800: '#B35426',
  900: '#994520',
  950: '#663016',
};

export default sunsetEmber;
