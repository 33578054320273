import * as React from 'react';
import { ParcoursDetail, type ParcoursDetailProps } from '@/components/ParcoursDetail';
import { PageFormBody, PageFormHelperText } from '@/components/Page';

export interface ParcoursConfirmationStepProps {
  value: ParcoursDetailProps['parcours'];
}

export const ParcoursConfirmationStep = (props: ParcoursConfirmationStepProps) => {
  const { value } = props;

  return (
    <PageFormBody
      before={
        <PageFormHelperText whiteSpace={'pre-wrap'}>
          {`Maintenant que vous avez établi l’ensemble des règles de votre parcours, il vous reste à le valider et à le mettre en production

La mise en production d’un mail ne sera effective que pour les nouveaux cas repérés.`}
        </PageFormHelperText>
      }
    >
      <ParcoursDetail parcours={value} headerVariant="section" excludedSections={['active']} />
    </PageFormBody>
  );
};
