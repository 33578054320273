import * as React from 'react';
import classNames from 'clsx';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { PageSectionList, type PageSectionListProps } from '@/components/Page';

export interface ParcoursDetailSectionItem {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  avatar?: React.ReactNode;
  inset?: boolean;
}

const renderSectionItem = (sectionItem: ParcoursDetailSectionItem, sectionIndex: number) => {
  return (
    <ListItem key={sectionIndex}>
      {sectionItem.avatar == null ? undefined : <ListItemAvatar>{sectionItem.avatar}</ListItemAvatar>}
      <ListItemText
        inset={sectionItem.inset ?? sectionItem.avatar == null}
        primary={sectionItem.title}
        secondary={sectionItem.subtitle}
      />
    </ListItem>
  );
};

// Name and options
const componentName = 'ParcoursDetailSection';

// Component properties
export interface ParcoursDetailSectionProps extends PageSectionListProps {
  sectionItems?: Array<ParcoursDetailSectionItem>;
  sectionItemEmpty?: ParcoursDetailSectionItem;
  exclude?: boolean;
}

export function ParcoursDetailSection(props: ParcoursDetailSectionProps) {
  const { className, sectionItems, sectionItemEmpty, exclude, ...otherProps } = props;

  if (exclude) return null;

  return (
    <PageSectionList className={classNames(componentName, className)} {...otherProps}>
      {(sectionItems?.length ?? 0) === 0 && sectionItemEmpty != null
        ? renderSectionItem(sectionItemEmpty, 0)
        : undefined}
      {sectionItems?.map((sectionItem, sectionIndex) => renderSectionItem(sectionItem, sectionIndex))}

      {props.children}
    </PageSectionList>
  );
}
