import React from 'react';
import { KerijBox, KerijBoxType } from '../../../components/base';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';

export interface DeclarationDocumentNoFileProps {
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const DeclarationDocumentNoFile = ({ isActive, disabled, onClick }: DeclarationDocumentNoFileProps) => (
  <>
    <Spacer size="large" />
    <Button color="error" onClick={onClick} disabled={disabled}>
      {'Je n’ai pas de justificatif'}
    </Button>
    {isActive && (
      <div className="mt-3 container-file">
        <KerijBox
          message={
            <>
              <strong>Attention,</strong> vous avez besoin d’un justificatif pour bénéficier de vos indemnités. Il vous
              sera demandé dans les 48h suivant cette déclaration.
            </>
          }
          type={KerijBoxType.Warning}
        />
      </div>
    )}
  </>
);
