import React, { useEffect, useState } from 'react';
import { Spacer } from '@/components/Spacer';
import { CollaborateurSearchBarFilter } from './CollaborateurSearchBarFilters';
import { SearchField } from '@/components/DashboardRH/shared/components/Form';
import { Box } from '@mui/material';
import { FilterButton } from '@/components/FilterButton';
import { CollaborateurSearchFilter } from './data';

export const emptyNotificationSearchFilter: CollaborateurSearchFilter = {
  content: '',
  portfolio: false,
};

interface CollaborateurSearchBarProperties {
  disablePortfolio?: boolean;
  onSubmit?: (filters: CollaborateurSearchFilter) => void;
  onCancel?: () => void;
  onSearchFilterChanged?: (filters: CollaborateurSearchFilter) => void;
}

export const CollaborateurSearchBar = ({
  disablePortfolio,
  onSubmit,
  onCancel,
  onSearchFilterChanged,
}: CollaborateurSearchBarProperties): JSX.Element => {
  const [filters, setFilters] = useState<CollaborateurSearchFilter>(emptyNotificationSearchFilter);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onSearchFilterChanged?.(filters);
  }, [filters]);
  return (
    <>
      <Box ml={2.5} mr={2.5} display="flex">
        <Box>
          <SearchField
            placeholder="Par nom, par prénom ou par matricule"
            onChange={(searchString: string) => {
              const newFilters = {
                ...filters,
                content: searchString,
              };
              setFilters(newFilters);
              onSubmit?.(newFilters);
            }}
          />
        </Box>

        <Box>
          <FilterButton
            open={isOpen}
            onClick={() => {
              const initialValue = isOpen;
              setIsOpen(!initialValue);
              if (initialValue) onSubmit?.(filters);
            }}
          />

          <CollaborateurSearchBarFilter
            open={isOpen}
            value={filters}
            onChange={(value) => setFilters(value)}
            onSubmit={(value) => {
              onSubmit?.(value);
              setIsOpen(false);
            }}
            onCancel={() => {
              setFilters(emptyNotificationSearchFilter);
              onCancel?.();
              setIsOpen(false);
            }}
            disablePortfolio={disablePortfolio}
          />
        </Box>
        <Spacer />
      </Box>
    </>
  );
};
