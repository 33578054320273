/**
 * Opérateurs de règles
 */
const ParcoursRuleOperatorBase = {
  eq: '==',
  ne: '<>',
} as const;

export const ParcoursRuleOperatorComparable = {
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
  ...ParcoursRuleOperatorBase,
} as const;
export type ParcoursRuleOperatorComparable =
  (typeof ParcoursRuleOperatorComparable)[keyof typeof ParcoursRuleOperatorComparable];

export const ParcoursRuleOperatorIdentifiable = {
  in: '$in',
  ...ParcoursRuleOperatorBase,
} as const;
export type ParcoursRuleOperatorIdentifiable =
  (typeof ParcoursRuleOperatorIdentifiable)[keyof typeof ParcoursRuleOperatorIdentifiable];

export const ParcoursRuleOperator = {
  ...ParcoursRuleOperatorComparable,
  ...ParcoursRuleOperatorIdentifiable,
} as const;
export type ParcoursRuleOperator = (typeof ParcoursRuleOperator)[keyof typeof ParcoursRuleOperator];

// export type ParcoursRuleOperator = ParcoursRuleOperatorComparable | ParcoursRuleOperatorIdentifiable;
export const isParcoursRuleOperator = (value: unknown): value is ParcoursRuleOperator =>
  value != null &&
  (Object.values(ParcoursRuleOperatorComparable).includes(value as any) ||
    Object.values(ParcoursRuleOperatorIdentifiable).includes(value as any));

export const isParcoursRuleOperatorComparable = (value: unknown): value is ParcoursRuleOperatorComparable =>
  value != null && Object.values(ParcoursRuleOperatorComparable).includes(value as any);

export const isParcoursRuleOperatorIdentifiable = (value: unknown): value is ParcoursRuleOperatorIdentifiable =>
  value != null && Object.values(ParcoursRuleOperatorIdentifiable).includes(value as any);

export const ParcoursRuleOperatorLabel = {
  [ParcoursRuleOperatorBase.eq]: 'égale à',
  [ParcoursRuleOperatorBase.ne]: 'différente de',
  [ParcoursRuleOperatorComparable.gt]: 'supérieure à',
  [ParcoursRuleOperatorComparable.gte]: 'supérieure ou égal à',
  [ParcoursRuleOperatorComparable.lt]: 'inférieure à',
  [ParcoursRuleOperatorComparable.lte]: 'inférieure ou égal à',
  [ParcoursRuleOperatorIdentifiable.in]: 'contenue dans',
} as const;
