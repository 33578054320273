import * as React from 'react';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { withShortcuts } from './withShortcut';
import { withInlineTypes } from './withInlineTypes';
import { withHistory } from 'slate-history';

export function useTextEditor() {
  return React.useMemo(() => withShortcuts(withInlineTypes(withReact(withHistory(createEditor())))), []);
}
