import * as React from 'react';
import { Button } from '@/components/Button';
import { RHNotification, useAppDispatch, useAppSelector } from '@/store';
import { useNavigate } from 'react-router-dom';
import { PRO_URL } from '@/constants/urls.constants';
import isAuthorized from '@/hooks/isAuthorized';
import { Avatar, Box, buttonClasses, styled, Typography } from '@mui/material';
import { ListAltRounded } from '@mui/icons-material';

export function LayoutActions() {
  const location = window.location.pathname;
  const pathToActions = PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notificationTotal = useAppSelector(RHNotification.getTotal);
  const isActionsPage = React.useMemo(() => location === pathToActions, [location, pathToActions]);

  React.useEffect(() => {
    if (isAuthorized(window.location.pathname)) dispatch(RHNotification.updateTotal());
  }, []);

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(1.5),
        paddingTop: theme.spacing(5.5),
        paddingBottom: 0,
      })}
    >
      <Button
        startIcon={<ListAltRounded />}
        endIcon={
          notificationTotal > 0 ? (
            <Badge>
              <Typography fontSize={10} fontWeight={600} color="primary">
                {notificationTotal}
              </Typography>
            </Badge>
          ) : undefined
        }
        color={isActionsPage ? 'secondary' : 'primary'}
        variant="contained"
        sx={(theme) => ({
          width: '100%',
          boxShadow: theme.shadows[3],
          height: theme.spacing(6.25),
          [`& .${buttonClasses.endIcon}`]: {
            marginLeft: theme.spacing(1.25),
            marginRight: 0,
          },
          [`& .${buttonClasses.startIcon}`]: {
            marginRight: theme.spacing(1.25),
            marginLeft: 0,
          },
        })}
        onClick={() => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS)}
      >
        <Typography fontSize={12} fontWeight={600} textAlign={'left'}>
          Actions à réaliser
        </Typography>
      </Button>
    </Box>
  );
}

const Badge = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(2.5),
  width: 'auto',
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1.25),
  backgroundColor: theme.palette.common.white,
  border: 0,
}));
