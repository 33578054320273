const sageMeadow = {
  50: '#EDF7EE',
  100: '#D3EAD8',
  200: '#B8DDC1',
  300: '#9DCFAB',
  400: '#83C296',
  500: '#68B47F',
  600: '#559369',
  700: '#447354',
  800: '#33533E',
  900: '#233328',
  950: '#182219',
};

export default sageMeadow;
