import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { storeAuthData } from '@/utils/auth.utils';
import { UserTypes } from '@/types/userTypes.enum';
import { employeeConfirmCode, employeeSendCode, employeeSendCodeNoAuth } from '@/services/employee';
import { StatusCodes } from 'http-status-codes';
import { AccountContext, useAppDispatch, useAppSelector } from '@/store';

function Confirmation() {
  const dispatch = useAppDispatch();
  const [code, setCode] = useState('');
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.accountContext);
  const [timeLeft, setTimeLeft] = useState(0);
  const idBusiness =
    location.state?.employee_information?.business?.id ||
    (user.user ? user.user.employee_information?.business?.id : null);
  const handleChange = setCode;
  useEffect(() => {
    if (!location.state) {
      navigate('/signup');
    }
  }, []);

  const email = useMemo(() => location.state.mail ?? location.state.email, [location]);
  const phone = useMemo(() => location.state.telephone ?? location.state.phone, [location]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      const { data, status } = await employeeConfirmCode({
        idEmployeeLogin: location.state.id,
        codeValidation: code,
        email: location.state.mail,
        phone: location.state.telephone,
        idBusiness: idBusiness,
      });

      if (status === StatusCodes.OK) {
        const { token, exp, employee } = data;
        const document = location.state ? location.state.document : null;
        storeAuthData({ userType: UserTypes.EMPLOYEE, token, exp });

        if (!location.state?.page) {
          dispatch(AccountContext.actions.setUser(employee));
          navigate('/profilvalidation', {
            state: { document },
            replace: true,
          });
        } else {
          if (location.state.page === 'Login') {
            dispatch(AccountContext.actions.setUser(employee));
            navigate('/profilvalidation', {
              state: { document },
              replace: true,
            });
          } else if (location.state.page === 'LoginInformation') {
            navigate('/profilvalidation', {
              state: { document },
              replace: true,
            });
            dispatch(AccountContext.actions.setUser(employee));
          } else if (location.state.page) {
            dispatch(AccountContext.actions.setUser(employee));
            navigate('/profilvalidation', {
              state: { page: location.state.page },
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setResend = () => {
    setResendCode(true);
    if (email) setShowPhone(true);
    if (phone) setShowEmail(true);
    setTimeLeft(60);
  };

  const handleNoCode: React.MouseEventHandler<HTMLButtonElement> = async () => {
    if (user.user) {
      await employeeSendCode({ phone, email });
      setResend();
    } else if (phone && email) {
      await employeeSendCodeNoAuth({ phone, email });
      setResend();
    } else {
      navigate(location.state.location, { state: location.state });
    }
  };

  useEffect(() => {
    if (resendCode) {
      const timeoutId = setTimeout(() => setResendCode(false), 60000);
      return () => clearTimeout(timeoutId);
    }
  }, [resendCode]);

  useEffect(() => {
    if (resendCode && timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft((prevTime) => prevTime - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [resendCode, timeLeft]);

  return (
    <div>
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="form-legacy form-code mt-5 mb-5">
          <h3 style={{ whiteSpace: 'nowrap' }} className="question-form mb-5">
            Code de vérification
          </h3>

          <div className="msg-code">
            <em>Veuillez entrer le code de vérification reçu par mail et/ou sms</em>
          </div>

          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={4}
            renderSeparator={<span style={{ width: '10px' }}></span>}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={true}
            inputStyle={{
              width: '3.70rem',
            }}
            {...{ autocomplete: 'one-time-code' }}
          />
          <button className="btn-submit">Vérifier</button>
        </form>
        {resendCode && (
          <div>
            Votre code a été renvoyé {showEmail ? "à  l'adresse " + email : 'à ce numéro : ' + phone}
            {timeLeft > 0 && (
              <div>
                Temps restant avant de pouvoir envoyer un nouveau code : {timeLeft} seconde
                {timeLeft > 1 ? 's' : ''}
              </div>
            )}
          </div>
        )}
        <button
          type="button"
          className="btn btn-kerij-outline btn-sm mt-5"
          onClick={handleNoCode}
          disabled={resendCode}
        >
          Je n’ai pas reçu le code
        </button>

        {phone && email && !user.user && (
          <button
            type="button"
            style={{ margin: '0', marginTop: '1rem' }}
            className="btn btn-annuler btn-sm"
            onClick={() => navigate(location.state.location, { state: location.state })}
          >
            Modifier mes informations
          </button>
        )}
      </div>
    </div>
  );
}

export default Confirmation;
