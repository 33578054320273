import React, { useState, useEffect } from 'react';
import { Navbar } from '@/components/Navbar/Navbar';
import { NavbarMobile } from '@/components/Navbar/NavbarMobile';
import Footer from '../Footer/Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'popstate-direction';
import { useAppSelector } from '@/store';

function QuestionnaireVisiteFin() {
  const navigate = useNavigate();
  const state = useLocation();
  const user = useAppSelector((state) => state.accountContext.user);
  // const [etatRetourVisite, setEtatRetourVisite] = useState(state.state.value)
  const [buttonLabel, setButtonLabel] = useState("Revenir à l'accueil");
  useEffect(() => {
    setButtonLabel("Retour à l'accueil");
  }, []);

  const handleClick = () => {
    if (state.state) {
      if (state.state.etat === '3') {
        navigate('/declaration', { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    } else {
      navigate('/', { replace: true });
    }
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">Questionnaire validé</h3>

        <form className="form-legacy form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          {/* A afficher dans le cas 1 */}
          {state.state.value === '1' && (
            <div className="type1-at container-fluid">
              <h4 className="question-form mt-5 mb-5 text-center">
                Nous sommes ravis d’apprendre que vous allez mieux.
              </h4>
              <p>
                Nous allons programmer une visite de retour avec le Médecin du Travail et nous reprendrons contact avec
                vous à votre retour pour confirmer votre reprise d’activité.
              </p>
              <p>D’ici là, nous vous souhaitons un prompt rétablissement.</p>
              <p>{`L'équipe KERij`}</p>
            </div>
          )}

          {/* A afficher dans le cas 2 */}
          {state.state.value === '2' && (
            <div className="type1-at container-fluid">
              <h4 className="question-form mt-5 mb-5 text-center">
                Nous sommes désolés d’apprendre que votre état de santé ne s’améliore pas aussi vite que vous le
                souhaiteriez.
              </h4>
              <p>
                Nous vous informons que vous pouvez, si vous le souhaitez, bénéficier d’une visite de pré-reprise avec
                le Médecin du Travail en cliquant{' '}
                <Link className="" to={user ? '/rdv' : '/rdvlogin'}>
                  {' '}
                  en cliquant sur ce lien{' '}
                </Link>
              </p>
              <p>D’ici là, nous vous souhaitons un prompt rétablissement.</p>
              <p>{`L'équipe KERij`}</p>
            </div>
          )}
          {/* A afficher dans le cas 3 */}
          {state.state.value === '3' && (
            <div className="type1-at container-fluid">
              <h4 className="question-form mt-5 mb-5 text-center">
                Nous sommes désolés d’apprendre que votre état de santé ne s’améliore pas aussi vite que vous le
                souhaiteriez.
              </h4>
              <p>
                Nous vous informons que vous pouvez, si vous le souhaitez, bénéficier d’une visite de pré-reprise avec
                le Médecin du Travail en cliquant{' '}
                <Link className="" to={user ? '/rdv' : '/rdvlogin'}>
                  {' '}
                  en cliquant sur ce lien{' '}
                </Link>
              </p>
              <p>D’ici là, nous vous souhaitons un prompt rétablissement.</p>
              <p>{`L'équipe KERij`}</p>
            </div>
          )}
        </form>
        <div className="position-relative col-12 col-md-8 mt-1 mb-5 text-center">
          <button type="button" className="btn btn-kerij col-6 col-lg-3" onClick={handleClick}>
            {buttonLabel}
          </button>
        </div>
      </div>
      <NavbarMobile />
      <Footer />
    </div>
  );
}

export default QuestionnaireVisiteFin;
