import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImgRdvEntretienLiaison from '@/assets/img-declaration-services.svg';
import ImgRdvEntretienRetour from '@/assets/img-rdv-managerial.svg';
import ImgRdvMedical from '@/assets/img-rdv-visite.svg';
import ImgRdvMedicalReprise from '@/assets/img-visite-prereprise.svg';
import { ParcoursPublicQuery } from '@/query';
import { RdvUIModal } from './RdvUIModal';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import { ParcoursPublic } from '@/types';
import { ActionButton } from '@/components/ActionButton';
import { Spacer } from '@/components/Spacer';
import { Box, Paper, styled, Tooltip, Typography } from '@mui/material';
import { useToast } from '@/components/Toast';
import { StatusCodes } from 'http-status-codes';

export function RdvUI() {
  const navigate = useNavigate();
  const toast = useToast();

  const query = ParcoursPublicQuery.useIndex();
  const parcoursPublicTriggerMutation = ParcoursPublicQuery.useTrigger();
  const confirmDialogController = useMUIDialogController();

  const [submitting, setSubmitting] = useState(false);
  const [parcours, setParcours] = useState<ParcoursPublic>();

  async function triggerParcours() {
    setSubmitting(true);
    if (parcours?.parcours_id)
      try {
        const { status } = await parcoursPublicTriggerMutation.mutateAsync({ parcours_id: parcours.parcours_id });

        if (status === StatusCodes.OK)
          toast.present({ message: 'Votre demande a déjà été prise en compte pour ce rendez-vous', severity: 'error' });
        else if (status === StatusCodes.CREATED)
          toast.present({ message: 'Votre demande a bien été prise en compte', severity: 'success' });
        else toast.present({ message: 'Une erreur est survenue, veuillez nous excusez', severity: 'error' });
      } catch {
        toast.present({ message: 'Une erreur est survenue, veuillez nous excusez', severity: 'error' });
      }
    confirmDialogController.control.setOpen(false);
    setSubmitting(false);
  }

  useEffect(() => {
    if (localStorage.getItem('access_token_employee')) query.refetch();
    else navigate('/rdvlogin');
  }, []);

  const renderAction = (value: ParcoursPublic) => {
    return (
      <Box mt="auto">
        <ActionButton
          actionName="next"
          label="Je fais une demande"
          onClick={() => {
            setParcours(value);
            confirmDialogController.control.setOpen(true);
          }}
          disabled={value.parcours_id == null}
        />
      </Box>
    );
  };

  return (
    <>
      <Spacer size="large" />
      <div className="container-fluid">
        <h1 className="question-form text-center">Quel type de rendez-vous désirez-vous ?</h1>
        <Spacer size="large" />

        <div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-4 g-4">
          {query.data?.map((value) => (
            <>
              <div className="col car-container">
                <PaperCard>
                  <img alt={value.title} className="img-rdv" src={renderParcoursIcon(value.code)} />
                  <Spacer />

                  <div className="card-body d-flex flex-column">
                    <Typography fontSize={20} fontWeight={600} color="primary">
                      {value.title}
                    </Typography>
                    <Spacer size="small" />

                    {value.description.map((line, key) => (
                      <>
                        <Spacer />
                        <Typography key={key} fontSize={14} color="primary">
                          {line}
                        </Typography>
                      </>
                    ))}

                    <Spacer />

                    {value.parcours_id == null && (
                      <Tooltip
                        title={"Cet entretien n'a pas encore été paramétré par votre entreprise"}
                        sx={{ position: 'relative' }}
                      >
                        {renderAction(value)}
                      </Tooltip>
                    )}
                    {value.parcours_id != null && renderAction(value)}
                  </div>
                </PaperCard>
              </div>
            </>
          ))}
        </div>

        <RdvUIModal
          value={parcours}
          onSubmit={triggerParcours}
          {...confirmDialogController.getRootProps()}
          disabled={submitting}
        />
      </div>
    </>
  );
}

const renderParcoursIcon = (code: ParcoursPublic['code']) => {
  switch (code) {
    case 'entretien_de_liaison': {
      return ImgRdvEntretienLiaison;
    }
    case 'entretien_de_retour_a_l_emploi': {
      return ImgRdvEntretienRetour;
    }
    case 'visite_medicale_de_sante_au_travail': {
      return ImgRdvMedical;
    }
    case 'visite_medicale_de_pre_reprise': {
      return ImgRdvMedicalReprise;
    }
    default: {
      return ImgRdvEntretienLiaison;
    }
  }
};

/**
 * @deprecated
 * Utiliser une vraie Card (
 *
 * Volonté initiale : retirer bootstrap sur des div
 */
const PaperCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  border: theme.shape.borderColorVariant,
  borderWidth: 1,
  borderStyle: 'solid',
  textAlign: 'center',
  height: '100%',
}));
