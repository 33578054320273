import { EmployeeContactType, EmployeeContactTypeModule } from '@/types';
import { useQuery } from 'react-query';

export const EmployeeContactQuery = (() => {
  const useSelectItems = () =>
    useQuery('employeeContactTypeSelectItems', async () => {
      return Object.values(EmployeeContactType)
        .map((contactType) => ({
          label: EmployeeContactTypeModule.format(contactType),
          value: contactType,
        }))
        .sort((a, b) => a.label?.charCodeAt(0) - b.label?.charCodeAt(0)) as Array<{ label: string; value: string }>;
    });

  return {
    useSelectItems,
  };
})();
