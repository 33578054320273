import { parcoursPublicCreate, parcoursPublicFindAll } from '@/services/employee/parcoursPublic';
import { useMutation, useQuery } from 'react-query';

export const ParcoursPublicQuery = (() => {
  const useIndex = () =>
    useQuery('parcoursPublicIndex', async () => {
      return parcoursPublicFindAll();
    });

  const useTrigger = () => {
    return useMutation(async (data: parcoursPublicCreate.Parameters) => {
      return await parcoursPublicCreate(data);
    });
  };

  return {
    useIndex,
    useTrigger,
  };
})();
