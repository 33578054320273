import { colors } from '../../../../../../constants';
import React, { ReactElement } from 'react';

interface ContentStructureInterface {
  title?: string;
  description: React.ReactNode;
  buttons?: ReactElement;
}

export const ContentStructure = ({ title, description, buttons }: ContentStructureInterface) => (
  <div className="gap-3 flex-column" style={{ display: 'flex' }}>
    {title && <span style={{ fontWeight: 'bold', color: colors.darkblue }}>{title}</span>}
    <span style={{ color: colors.darkblue, fontSize: '14px' }}>{description}</span>
    {buttons && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {buttons}
      </div>
    )}
  </div>
);
