import * as React from 'react';
import { ParcoursInput, ParcoursInputRule } from './ParcoursInput';
import {
  isParcoursRuleKey,
  ParcoursRule,
  ParcoursRuleKey,
  ParcoursRuleKeyDescription,
  ParcoursRuleKeyLabel,
  ParcoursRuleOperator,
} from '@/types';
import { AppSelect } from '@/components/AppSelect';
import { KerijBox } from '../base';

export interface ParcoursRulesInputProps {
  value: Array<ParcoursRule>;
  onChange?: (value: Array<ParcoursRule>) => void;
  onEdit?: (step: 'start' | 'end') => void;
}

export function ParcoursRulesInput(props: ParcoursRulesInputProps) {
  const { value, onChange, onEdit } = props;

  const excludedRules: Set<ParcoursRuleKey> = new Set([
    ParcoursRuleKey.AbsenceDateEnd,
    ParcoursRuleKey.AbsenceDateStart,
    ParcoursRuleKey.AbsenceParentDuration,
  ]);

  const [addValue, setAddValue] = React.useState<ParcoursRuleKey | null>(null);

  const [rulesValue, setRulesValue] = React.useState<Array<ParcoursInputRule>>(
    value.map((_rule, index) => {
      const key = Object.keys(_rule)[0] as ParcoursRuleKey;
      const operator = Object.keys(_rule[key] as any)[0] as ParcoursRuleOperator;
      return {
        key,
        order: index + 1,
        operator,
        value: (_rule as any)[key]?.[operator],
      };
    }),
  );

  const activesRulesKey = React.useMemo<Array<ParcoursRuleKey>>(
    () => rulesValue.flatMap((rule) => rule.key),
    [rulesValue],
  );

  const addedRulesKey = React.useMemo<Array<ParcoursRuleKey>>(
    () => value.flatMap((rule) => Object.keys(rule)) as ParcoursRuleKey[],
    [value],
  );

  const setOrder = (values: Array<ParcoursInputRule>) =>
    values.length === 0 ? 1 : (values[values.length - 1]?.order ?? 0) + 1;

  React.useEffect(() => {
    setRulesValue(
      value.map((_rule, index) => {
        const key = Object.keys(_rule)[0] as ParcoursRuleKey;
        const operator = Object.keys(_rule[key] as any)[0] as ParcoursRuleOperator;
        return {
          key,
          order: index + 1,
          operator,
          value: (_rule as any)[key]?.[operator],
        };
      }),
    );
  }, [value]);
  return (
    <>
      {rulesValue.map((rule, index) => (
        <ParcoursInput
          scriptKeys={addedRulesKey}
          adding={addValue != null}
          value={rule}
          key={index}
          onDelete={(_value) => {
            if (_value.key === addValue) {
              onEdit?.('end');
              setAddValue(null);
            }
            const newValues = removeKey(value, _value.key);
            onChange?.(newValues);
          }}
          onChange={(_value) => {
            const rules = rulesValue.filter((rule) => rule.key != _value.key);
            setRulesValue([...rules, _value].sort((a, b) => a.order - b.order));
          }}
          onConfirm={(_value, options) => {
            const durationValue = options?.duration;
            const affectedValue = durationValue
              ? {
                  ...(_value.value as any),
                  since: durationValue,
                  sinceUnit: 'days',
                }
              : _value.value;

            if (_value.operator) onChange?.([...value, { [_value.key]: { [_value.operator]: affectedValue } }]);
            onEdit?.('end');
            setAddValue(null);
          }}
        />
      ))}

      <AppSelect
        value={''}
        FormControlProps={{
          sx: (theme) => ({ maxWidth: theme.spacing(36) }),
          style: { marginTop: 12 },
        }}
        label={'Ajouter un critère'}
        placeholder="Sélectionnez votre critère"
        disabled={addValue != null}
        items={(Object.entries(ParcoursRuleKeyLabel) as Array<[keyof typeof ParcoursRuleKeyLabel, string]>)
          // eslint-disable-next-line security/detect-object-injection
          .map(([key, label]) => ({ label: label, value: key, tooltip: ParcoursRuleKeyDescription[key] }))
          .filter(
            (rule) =>
              isParcoursRuleKey(rule.value) && !activesRulesKey.includes(rule.value) && !excludedRules.has(rule.value),
          )}
        onChange={(e, _value) => {
          const newValue = isParcoursRuleKey(_value) ? _value : undefined;
          if (newValue) {
            setRulesValue((rules) => [...rules, { order: setOrder(rules), key: newValue }]);
            setAddValue(newValue);
            onEdit?.('start');
          }
        }}
        noHelper
      />
    </>
  );
}

function removeKey(values: ParcoursRule[], key: ParcoursRuleKey): ParcoursRule[] {
  const dup = values;
  const indexOfElement = dup.findIndex((value) => {
    const keyValue = Object.keys(value)[0];
    return key === keyValue;
  });
  return [...dup.filter((_, i) => i < indexOfElement), ...dup.filter((_, i) => i > indexOfElement)];
}
