const royalLavender = {
  50: '#F9F9FF',
  100: '#F0EEF9',
  200: '#E5E0F7',
  300: '#DAD6FD',
  400: '#C4BEEC',
  500: '#A9A4CF',
  600: '#807BA9',
  700: '#555183',
  800: '#342F6C',
  900: '#1E1A64',
  950: '#0E0653',
};

export default royalLavender;
