import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../DashboardRH.css';
import { NotConnectedRH } from '../shared/components/NotConnectedRH';
import { SocketContext } from '../../../contexts/socket.context';
import { PRO_URL } from '../../../constants/urls.constants';
import { fetchRhAction } from '../../../services/rh';
import {
  RHNotificationAssociationType,
  RHNotificationParcours,
  type RHNotification as RHNotificationType,
} from '@/types';
import {
  NotificationSearchBar,
  NotificationSearchFilter,
  NotificationsType,
} from './components/Notifications/NotificationSearchBar';
import ExtractionCard from './components/Notifications/ExtractionCard';
import { notificationType } from '../../../constants/notifications.constants';
import UpdateDocumentCard from './components/Notifications/UpdateDocumentCard';
import NotificationCard from './components/Notifications/NotificationCard';
import { Card, CircularProgress, Skeleton, Typography } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import { RHAccountContext, RHNotification, useAppDispatch, useAppSelector } from '@/store';
import { MissingDocumentActions, MissingDocumentContent } from './components/Notifications/CardContent/MissingDocument';
import {
  PathologyOverLimitActions,
  PathologyOverLimitContent,
} from './components/Notifications/CardContent/PathologyOverLimit';
import {
  PendingValidationDocumentContent,
  PendingValidationDocumentActions,
} from './components/Notifications/CardContent/PendingValidationDocument';
import { BackToWorkContent, BackToWorkActions } from './components/Notifications/CardContent/BackToWork';
import useDebounce from '../../../hooks/useDebounce';
import { BusinessEntry } from '@/store/slice/rhNavbarContext';
import { PermissionBox } from '@/components/PermissionBox';
import { ActionCardParcoursActions, ActionCardParcoursContent } from '../ActionCardParcours';
import { ListCardHeader } from '../ListCard';
import {
  IntegrationFailedActions,
  IntegrationFailedContent,
} from './components/Notifications/CardContent/IntegrationFailed';
import { RHPage } from '@/components/RHPage';

const Actions = () => {
  const rh = useAppSelector(RHAccountContext.getProfile);
  const location = useLocation();
  const navigate = useNavigate();
  const dataNotif = useAppSelector(RHNotification.getList);
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const [isPortfolioFilter, setIsPortfolioFilter] = useState(false);
  const [typeFilters, setTypeFilters] = useState<{ [K in NotificationsType]?: boolean }>({
    [NotificationsType.MissingDocument]: false,
    [NotificationsType.VerifyDocument]: false,
    [NotificationsType.AbsenceEnd]: false,
    [NotificationsType.Pathologie]: false,
    [NotificationsType.IntegrationFailed]: false,
  });
  const [canFilter, setCanFilter] = useState(false);

  const searchDebound = useDebounce(search, 500);
  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext?.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext?.selectedIdBusiness);

  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [isWaitingNotification, setIsWaitingNotification] = useState(true);

  /* Filtre */

  const socketClient = useContext(SocketContext);
  const GetDataNotif = async (
    search: string,
    listSelectBusiness: BusinessEntry[],
    selectedIdBusiness: string | null,
    portfolio: 'true' | undefined,
    offset: number,
    limit: number,
    isLoadMore?: boolean,
  ) => {
    if (selectedIdBusiness) {
      setIsLoadingNotifications(isLoadMore ?? false);
      const response = await fetchRhAction({
        type: Object.entries(typeFilters)
          .filter(([, value]) => value)
          .map(([key]) => key as NotificationsType),
        search: search,
        idBusinesses: JSON.stringify(
          listSelectBusiness.filter((value: any) => value.isChecked).map((value: any) => value.id),
        ),
        idBusiness: selectedIdBusiness,
        portfolio: portfolio,
        offset: isLoadMore ? offset : 0,
        limit: limit,
      });
      setIsLoadingNotifications(false);
      if (response.status === StatusCodes.OK) {
        isLoadMore
          ? dispatch(RHNotification.actions.append(response.data.actions))
          : dispatch(RHNotification.actions.initialize(response.data.actions));
      }
    }
  };

  const handleScroll = () => {
    const querySelector = document.querySelector('#actions');
    if (
      (location.pathname === PRO_URL.PREFIXE_PRO + PRO_URL.ACTIONS || location.pathname === PRO_URL.PRO) &&
      querySelector &&
      querySelector.getBoundingClientRect().bottom <= window.innerHeight
    ) {
      setOffset(dataNotif.length);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [dataNotif, location]);

  const getPortfolioFilter = async () => {
    if (canFilter === false) {
      const elements = await fetchRhAction({
        type: Object.entries(typeFilters)
          .filter(([, value]) => value)
          .map(([key]) => key as NotificationsType),
        search: search,
        idBusinesses: JSON.stringify(
          listSelectBusiness.filter((value: any) => value.isChecked).map((value: any) => value.id),
        ),
        idBusiness: selectedIdBusiness ?? undefined,
        portfolio: 'true',
        offset: 0,
        limit: 1,
      });

      if (elements?.data?.actions && elements.data.actions.length > 0) setCanFilter(true);
    }

    refreshNotificationList({ portfolio: isPortfolioFilter ? 'true' : undefined });
  };

  useEffect(() => {
    getPortfolioFilter();
  }, [listSelectBusiness, selectedIdBusiness, searchDebound]);

  useEffect(() => {
    offset > 0 && refreshNotificationList({ isLoadMore: true, portfolio: isPortfolioFilter ? 'true' : undefined });
  }, [offset]);

  const handleAfterDeleteAction = ({ idNotification }: any) => {
    dispatch(
      RHNotification.actions.initialize(dataNotif.filter((value: RHNotificationType) => value.id !== idNotification)),
    );
  };

  const getLastDataNotif = async (search: string, selectedIdBusiness: string | null) => {
    if (selectedIdBusiness) {
      setIsLoadingNotifications(true);
      const response = await fetchRhAction({
        type: Object.entries(typeFilters)
          .filter(([, value]) => value)
          .map(([key]) => key as NotificationsType),
        search: search,
        idBusinesses: rh?.rh_information?.business?.id,
        idBusiness: selectedIdBusiness,
        portfolio: isPortfolioFilter ? 'true' : undefined,
        offset: 0,
        limit: 1,
      });
      if (response.status === StatusCodes.OK) {
        dispatch(RHNotification.actions.initialize(response.data.actions));
      }
      setIsLoadingNotifications(false);
    }
  };

  const handleAfterAddAction = () => {
    getLastDataNotif(search, selectedIdBusiness);
  };

  useEffect(() => {
    socketClient?.connect();
    socketClient?.afterDeleteEvent(handleAfterDeleteAction);
    return () => {
      socketClient?.disconnect();
    };
  }, [socketClient]);

  // TODO: to be verified
  // useEffect(() => {
  //   socketClient?.afterAddEvent(handleAfterAddAction);

  //   socketClient?.afterUpdateEvent(refreshNotificationList);

  //   return () => {
  //     socketClient?.unsubscribeAfterAddEvent(handleAfterAddAction);
  //     socketClient?.unsubscribeAfterUpdateEvent(refreshNotificationList);
  //   };
  // }, [socketClient, listSelectBusiness, selectedIdBusiness]);

  useEffect(() => {
    if (!localStorage.getItem('access_token_rh')) {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.LOGIN);
    }
  }, [localStorage.getItem('access_token_rh')]);

  useEffect(() => {
    refreshNotificationList({ portfolio: isPortfolioFilter ? 'true' : undefined });
  }, [isPortfolioFilter, typeFilters]);

  const refreshNotificationList = async (options?: { isLoadMore?: boolean; portfolio?: 'true' }) => {
    if (options?.isLoadMore !== true) setOffset(0);
    setIsWaitingNotification(options?.isLoadMore !== true);
    await GetDataNotif(
      search,
      listSelectBusiness,
      selectedIdBusiness,
      options?.portfolio,
      offset,
      limit,
      options?.isLoadMore,
    );
    setTimeout(() => setIsWaitingNotification(false), 300);
  };

  function onActionDone(rhNotification: RHNotificationType) {
    dispatch(RHNotification.actions.remove(rhNotification));
    dispatch<any>(RHNotification.actions.reset());
    dispatch<any>(RHNotification.updateTotal());
    refreshNotificationList({ portfolio: isPortfolioFilter ? 'true' : undefined });
  }

  if (!rh) return <NotConnectedRH />;
  return (
    <PermissionBox id="actions" scope="absence-management" action="edit">
      <RHPage
        title="Actions à réaliser"
        topElement={
          <NotificationSearchBar
            disablePortfolio={!canFilter}
            onSubmit={(filters: NotificationSearchFilter) => {
              setSearch(filters.content);
              setIsPortfolioFilter(filters.portfolio ?? false);
              setTypeFilters({
                [NotificationsType.MissingDocument]: filters.type[NotificationsType.MissingDocument].checked,
                [NotificationsType.VerifyDocument]: filters.type[NotificationsType.VerifyDocument].checked,
                [NotificationsType.AbsenceEnd]: filters.type[NotificationsType.AbsenceEnd].checked,
                [NotificationsType.Pathologie]: filters.type[NotificationsType.Pathologie].checked,
                [NotificationsType.IntegrationFailed]: filters.type[NotificationsType.IntegrationFailed].checked,
              });
            }}
            onCancel={() => {
              setIsPortfolioFilter(false);
              setTypeFilters({
                [NotificationsType.MissingDocument]: false,
                [NotificationsType.VerifyDocument]: false,
                [NotificationsType.AbsenceEnd]: false,
                [NotificationsType.Pathologie]: false,
              });
            }}
          />
        }
      >
        {/*Si aucune actions}*/}
        {dataNotif.length === 0 && !isLoadingNotifications && !isWaitingNotification && (
          <div className="text-center mt-5 mb-3 col-12 col-sm-10">
            <b>Aucune action à effectuer</b>
          </div>
        )}

        {/* Liste des dossiers avec actions */}
        {dataNotif.length > 0 &&
          !isWaitingNotification &&
          dataNotif.map((rhNotification: RHNotificationType, index) => {
            if (rhNotification.association_type === RHNotificationAssociationType.Parcours) {
              return (
                <NotificationCard
                  key={index}
                  rhNotification={rhNotification}
                  actions={
                    <ActionCardParcoursActions
                      rhNotification={rhNotification as RHNotificationParcours}
                      onAction={() => onActionDone(rhNotification)}
                    />
                  }
                >
                  <ActionCardParcoursContent rhNotification={rhNotification as RHNotificationParcours} />
                </NotificationCard>
              );
            }

            switch (rhNotification.type_notification) {
              case notificationType.TYPE_EXTRACTION_ABSENCES: {
                return <ExtractionCard key={index} rhNotification={rhNotification} onClick={() => ({})} />;
              }
              case notificationType.TYPE_UPDATE_DOCUMENT: {
                return <UpdateDocumentCard key={index} rhNotification={rhNotification} />;
              }
              case notificationType.TYPE_DOCUMENT_MANQUANT: {
                return (
                  <NotificationCard
                    key={index}
                    rhNotification={rhNotification}
                    actions={
                      <MissingDocumentActions
                        rhNotification={rhNotification}
                        onActionDone={() => onActionDone(rhNotification)}
                      />
                    }
                  >
                    <MissingDocumentContent />
                  </NotificationCard>
                );
              }
              case notificationType.TYPE_DOCUMENT_PENDING_VALIDATION: {
                return (
                  <NotificationCard
                    key={index}
                    rhNotification={rhNotification}
                    actions={
                      <PendingValidationDocumentActions
                        rhNotification={rhNotification}
                        onVerifyDocument={() => onActionDone(rhNotification)}
                      />
                    }
                  >
                    <PendingValidationDocumentContent />
                  </NotificationCard>
                );
              }
              case notificationType.TYPE_RETOUR_EMPLOI: {
                return (
                  <NotificationCard
                    key={index}
                    rhNotification={rhNotification}
                    actions={
                      <BackToWorkActions
                        rhNotification={rhNotification}
                        onActionDone={() => onActionDone(rhNotification)}
                      />
                    }
                  >
                    <BackToWorkContent />
                  </NotificationCard>
                );
              }
              case notificationType.TYPE_DEPASSEMENT_CONGE_PATHOLOGIQUE: {
                return (
                  <NotificationCard
                    key={index}
                    rhNotification={rhNotification}
                    actions={
                      <PathologyOverLimitActions
                        rhNotification={rhNotification}
                        onActionDone={() => onActionDone(rhNotification)}
                      />
                    }
                  >
                    <PathologyOverLimitContent />
                  </NotificationCard>
                );
              }
              case notificationType.TYPE_ERROR_EXPORT: {
                return (
                  <NotificationCard
                    key={index}
                    rhNotification={rhNotification}
                    actions={
                      <IntegrationFailedActions
                        rhNotification={rhNotification}
                        onActionDone={() => onActionDone(rhNotification)}
                      />
                    }
                  >
                    <IntegrationFailedContent rhNotification={rhNotification} />
                  </NotificationCard>
                );
              }
              default: {
                return (
                  <NotificationCard key={index} rhNotification={rhNotification}>
                    {rhNotification.type_notification}
                    <br />
                    (Ce contenu ne peut être affiché)
                  </NotificationCard>
                );
              }
            }
          })}
        {isWaitingNotification && (
          <Card className="mb-4 w-100">
            <ListCardHeader>
              <Typography variant="subtitle2" fontWeight="bold" ml={1}>
                Chargement en cours...
              </Typography>
            </ListCardHeader>
            <Skeleton variant="rectangular" height={'12rem'} sx={{ bgcolor: 'rgba(13, 6, 78, 0.01)' }} />
          </Card>
        )}
        {isLoadingNotifications && (
          <div className={'text-center'}>
            {' '}
            <CircularProgress />
          </div>
        )}
      </RHPage>
    </PermissionBox>
  );
};

export default Actions;
