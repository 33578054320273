import React from 'react';

export interface AbsenceDateRangeInputProps {
  label: string;
  name: string;
  min?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (newValue?: string) => void;
}

export function AbsenceDateRangeInput(props: AbsenceDateRangeInputProps) {
  const { label, disabled, onChange, ...othersPros } = props;

  return (
    <label className="form-label col-12">
      {label}

      <input
        className="form-control form-date"
        type="date"
        style={{ maxWidth: 'inherit' }}
        {...othersPros}
        readOnly={disabled}
        onChange={(event) => onChange?.(event.target.value)}
        onBlur={(event) => onChange?.(event.target.value)}
        required
      />
    </label>
  );
}
