/**
 * @deprecated Use MUI Theme https://gitlab.com/withbot/kerij/-/blob/fd64b9bf1088de2bd478f562fdb22c9e577ef8a9/frontend/src/components/ThemeComponent/config/DefaultTheme.ts
 */
export const colors = {
  primary: '#0F075B',
  secondary: '#40C9C9',
  darkblue: '#0E0653',
  white: '#ffff',
  error: '#F02D3A',
  warning: '#EA9010',
  success: '#98CE00',
};
