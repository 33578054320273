import './Button.css';
import React, { ForwardedRef } from 'react';
import ReactLoading from 'react-loading';
import classNames from 'clsx';
import { Button as MUIButton, ButtonProps as MUIButtonProps, Typography } from '@mui/material';

// Name and options
const componentName = 'KRJButton';

const buttonPropsMap: Record<string, MUIButtonProps> = {
  default: {
    variant: 'outlined', // If not specified button is outlined
  },
  primary: {
    color: 'secondary', // MUI color
    variant: 'contained',
  },
  secondary: {
    color: 'primary', // MUI color
    variant: 'outlined',
  },
  tertiary: {
    variant: 'text', // MUI color
    color: 'primary',
  },
};

export interface ButtonProps extends MUIButtonProps {
  submitting?: boolean;
}

export const Button = React.forwardRef(function Button(props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
  const { submitting, children, className, color, ...otherProps } = props;
  // eslint-disable-next-line security/detect-object-injection
  const buttonProps = color == null ? undefined : buttonPropsMap[color] ?? { color };
  return (
    <MUIButton
      className={classNames(componentName, `${componentName}--${color}`, className)}
      ref={ref}
      {...buttonPropsMap.default}
      {...buttonProps}
      {...otherProps}
    >
      {submitting ? (
        <ReactLoading className={`${componentName}__Loader`} type="bubbles" color="primary" />
      ) : (
        <Typography fontSize={12} width={'100%'}>
          {children}
        </Typography>
      )}
    </MUIButton>
  );
});
