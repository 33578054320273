import React, { useState } from 'react';
import { ParcoursRulesInput, ParcoursRulesInputProps } from '@/components/ParcoursInput';
import { ParcoursRule } from '@/types';
import { PageFormBody, PageFormHelperText } from '@/components/Page';

export interface ParcoursRulesStepProps {
  value: Array<ParcoursRule>;
  onChange: (value: Array<ParcoursRule>) => void;
  onEdit?: ParcoursRulesInputProps['onEdit'];
}

export const ParcoursRulesStep = (props: ParcoursRulesStepProps) => {
  const { value, onChange, onEdit } = props;
  const [model, setModel] = useState(value);

  return (
    <PageFormBody
      spacing={3}
      before={
        <PageFormHelperText whiteSpace={'pre-wrap'}>
          {`Dans cette étape, vous allez définir le contexte dans lequel nous devons adresser un email.`}
        </PageFormHelperText>
      }
    >
      <ParcoursRulesInput
        value={model}
        onChange={(newValue) => {
          setModel(newValue);
          onChange(newValue);
        }}
        onEdit={onEdit}
      />
    </PageFormBody>
  );
};
