import * as React from 'react';
import classNames from 'clsx';
import { Box, Stack, type BoxProps } from '@mui/material';

// Name and options
const componentName = 'PageFormBody';

// Component properties
export interface PageFormBodyProps extends BoxProps {
  before?: React.ReactNode;
  spacing?: number;
}

/**
 * @example
 * <PageFormBody
 *   before={'This is a description text'}
 * >
 *   <FormField />
 *   <FormField />
 * </PageFormBody>
 * @param props
 * @returns
 */
export function PageFormBody(props: PageFormBodyProps) {
  const { className, before, spacing, ...otherProps } = props;
  return (
    <Box className={classNames(componentName, className)} {...otherProps}>
      {before}
      <Stack
        direction="column"
        spacing={spacing ?? 4}
        paddingTop={spacing ?? 4}
        paddingBottom={spacing ?? 4}
        className={`${componentName}__Fields`}
      >
        {props.children}
      </Stack>
    </Box>
  );
}
