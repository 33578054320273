import * as React from 'react';
import { Parcours } from '@/types';
import { ParcoursWhenInput } from '@/components/ParcoursInput';
import { PageFormBody, PageFormHelperText } from '@/components/Page';

export interface ParcoursWhenStepProps {
  value: Parcours['script']['when'];
  onChange: (value: Parcours['script']['when']) => void;
}

export const ParcoursWhenStep = (props: ParcoursWhenStepProps) => {
  const { value, onChange } = props;

  return (
    <PageFormBody
      before={
        <PageFormHelperText whiteSpace={'pre-wrap'}>
          {`Vous avez défini l’ensemble des conditions d’envoi du mail, il vous reste à définir le moment opportun de son envoi.

Lorsque vous souhaitez adresser le même mail, plusieurs fois, vous pouvez reprendre l’ensemble des éléments et décaler uniquement la date d’envoi.`}
        </PageFormHelperText>
      }
    >
      <ParcoursWhenInput
        value={value}
        onChange={(value) => {
          onChange?.(value);
        }}
      />
    </PageFormBody>
  );
};
