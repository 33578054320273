import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { KerijBox, KerijBoxType } from '../../../../components/base';
import { useError } from '@/shared/useError';
import { DateStartInput } from '@/features/dashboard-rh/create-absence-rh/components/Date/common';
import { AccountContext, useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';

const getDateDebutInitValue = (document: any) => {
  if (document.dateDebut) {
    return document.dateDebut;
  } else if (['1', '2', '3'].includes(document.absenceType.value) && document.period && document.period.length > 0) {
    return document.period.at(0).dateDebut;
  } else {
    return '';
  }
};

const getDateFinInitValue = (document: any) => {
  if (document.dateFin) {
    return document.dateFin;
  } else if (['1', '2', '3'].includes(document.absenceType.value) && document.period && document.period.length > 0) {
    return document.period.at(0).dateFin;
  } else {
    return '';
  }
};

export const HospiDate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const document = location.state.document || {};
  const user = useAppSelector(AccountContext.getUser);

  const [dateDebut, setDateDebut] = useState<string | undefined>(getDateDebutInitValue(document));
  const [dateFin, setDateFin] = useState<string | undefined>(getDateFinInitValue(document));
  const { error, clearError, triggerError } = useError();

  const [lastDayWork, setLastDayWork] = useState<string | undefined>(document.lastDayWork);
  const [isWorkDay, setIsWorkDay] = useState<boolean>(document.isWorkDay || false);

  const [isShownDureeHospi, setIsShownDureeHospi] = useState<boolean>(document.isShownDureeHospi || false);
  const [nbJoursHospi, setNbJoursHospi] = useState<number>(document.nbJoursHospi || 0);

  useEffect(() => {
    handleOnChangeHospiDate();
  }, [dateDebut, nbJoursHospi]);

  const handleLastDayWorkChange = (value: string | undefined) => {
    setLastDayWork(value);
    clearError();
  };
  const handleDateStartChange = (value: string | undefined) => {
    setDateDebut(value);
    clearError();
  };

  const handleOnChangeHospiDate = () => {
    clearError();
    if (
      moment(dateDebut).isBefore(moment().subtract(7, 'days').format('YYYY-MM-DD')) &&
      dateDebut !== moment().format('YYYY-MM-DD')
    ) {
      triggerError(
        `Votre déclaration aurait du être effectuée au plus tard 7 jours après la date de début, veuillez vous rapprocher de votre service Ressources Humaines`,
      );
      return;
    }
    if (nbJoursHospi > 0) {
      setDateFin(
        moment(dateDebut)
          .add(nbJoursHospi - 1, 'days')
          .format('YYYY-MM-DD'),
      );
    } else setDateFin('');
  };

  const handleClick = async () => {
    const documentValue = {
      ...document,
      period: [{ dateDebut: dateDebut, dateFin: dateFin }],
      dateNaissance: null,
      lastDayWork: lastDayWork,
      isLinkHospi: null,
      isWorkDay: isWorkDay,
      idAbsenceHospi: null,
      dateDebut: dateDebut,
      dateFin,
      nbJoursHospi: nbJoursHospi || null,
      isShownDureeHospi: isShownDureeHospi || null,
      optionnal: null,
      dateEndReduction: null,
      hoursReduction: null,
      dateEndPromo: null,
    };
    navigate('/declaration-document', {
      state: {
        document: {
          ...documentValue,
        },
      },
    });
  };
  return (
    <div className="container-fluid">
      <h3 className="question-form mt-5 mb-5 text-center">Quelle est la période de votre hospitalisation ?</h3>
      <div className="mb-3 col-xl-4 col-12">
        <form className="form-legacy" onSubmit={handleClick} style={{ padding: 0, margin: 'auto' }}>
          <label className="col-12">
            {`1er jour d'hospitalisation`}
            <input
              className="form-control form-date"
              style={{ maxWidth: 'inherit' }}
              name="lastDayWork"
              type="date"
              placeholder="Date de début"
              value={lastDayWork}
              onChange={(event) => handleLastDayWorkChange(event.target.value)}
              onBlur={(event) => handleLastDayWorkChange(event.target.value)}
              required
            />
          </label>
          {lastDayWork && user && (
            <DateStartInput
              lastDayWork={lastDayWork}
              employeeTarget="self"
              employeeTimePackage={user.employee_information.time_package}
              onChange={(dateDebut) => handleDateStartChange(dateDebut)}
            />
          )}
          {dateDebut ? (
            <div
              className="mb-3"
              style={{
                display: 'flex',
                flexBasis: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="mt-3" style={{ fontWeight: '800' }}>
                {`La durée d'hospitalisation est-elle définie ?`}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexBasis: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexBasis: 'row',
                    alignItems: 'center',
                    marginRight: 30,
                  }}
                  onClick={() => {
                    setIsShownDureeHospi(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={isShownDureeHospi ? faCircleCheck : faCircle}
                    style={{ color: '#40C9C9', marginRight: 5 }}
                  ></FontAwesomeIcon>
                  <div style={{ fontWeight: '800' }}>Oui</div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexBasis: 'row',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setIsShownDureeHospi(false);
                    setDateFin('');
                    setNbJoursHospi(0);
                  }}
                >
                  <FontAwesomeIcon
                    icon={isShownDureeHospi ? faCircle : faCircleCheck}
                    style={{ color: '#40C9C9', marginRight: 5 }}
                  ></FontAwesomeIcon>
                  <div style={{ fontWeight: '800' }}>Non</div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {isShownDureeHospi ? (
            <div>
              <label className="card-text text-center col-12 contacts-libelle mb-3">
                Combien de jours êtes-vous hospitalisé ?
                <input
                  type="number"
                  className="card-text col-12 text-start"
                  name="nbJoursHospi"
                  value={nbJoursHospi}
                  onChange={(event) => setNbJoursHospi(Number.parseInt(event.target.value))}
                  placeholder=""
                ></input>
              </label>
              {dateFin && (
                <div className="text-center mb-3">
                  <b>Votre date de sortie est prévue le {moment(dateFin).format('DD/MM/YYYY')}</b>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {error && <KerijBox message={error} type={KerijBoxType.Error} />}

          <Spacer size="medium" />
          <Button color="primary" type="submit" disabled={!(!error && dateDebut)}>
            Suivant
          </Button>
          <Spacer />
          <Button
            color="secondary"
            onClick={() => {
              if (
                document.absenceType.value + document.absenceType1.value === '32' ||
                document.absenceType.value + document.absenceType1.value === '33' ||
                document.absenceType.value + document.absenceType1.value === '41' ||
                document.absenceType.value + document.absenceType1.value === '42'
              ) {
                navigate('/declaration-type-1', {
                  state: location.state,
                });
              } else if (document.absenceType.value === '2') {
                navigate('/declaration', {
                  state: location.state,
                });
              } else {
                navigate('/declaration-type-2', {
                  state: location.state,
                });
              }
            }}
          >
            Précédent
          </Button>
        </form>
      </div>
    </div>
  );
};
