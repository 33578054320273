const infernoRed = {
  50: '#FFECEB',
  100: '#FFCFCF',
  200: '#FFAAA6',
  300: '#FF7F72',
  400: '#FF5B4D',
  500: '#FF2E00',
  600: '#E62800',
  700: '#CC2300',
  800: '#B21D00',
  900: '#991800',
  950: '#660F00',
};

export default infernoRed;
