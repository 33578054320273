import * as React from 'react';
import classNames from 'clsx';
import { Parcours } from '@/types';
import { Check, Block } from '@/components/AppIcon';

const iconStyle = {
  marginBottom: '-0.1em',
  verticalAlign: 'baseline',
};

// Name and options
const componentName = 'ParcoursStatusText';

// Component properties
export interface ParcoursStatusTextProps extends React.ComponentProps<'span'> {
  parcours: Pick<Parcours, 'active'>;
}

export function ParcoursStatusText(props: ParcoursStatusTextProps) {
  const { className, parcours, ...otherProps } = props;
  return (
    <span className={classNames(componentName, className)} {...otherProps}>
      {parcours.active ? (
        <>
          Actif <Check style={iconStyle} />
        </>
      ) : (
        <>
          Inactif <Block style={iconStyle} />
        </>
      )}
    </span>
  );
}
