import React from 'react';
import { QUESTIONNAIRE_SUIVI_CHOICES } from './data';
import { Absence } from '@/types/absences.type';

interface QuestionnaireSuiviFormProperties {
  value: string;
  absence?: Absence;
  onChange: (value: string) => void;
}

export function QuestionnaireSuiviForm({ value, absence, onChange }: QuestionnaireSuiviFormProperties) {
  return (
    <form className="form-legacy form-declaration form-declaration col-lg-4" style={{ marginBottom: '1rem' }}>
      <label>
        <select
          className="form-select short-text"
          name="absence"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          required
        >
          {/* Cas 1 : On valide c'est tout */}
          <option value={QUESTIONNAIRE_SUIVI_CHOICES.BACK_CORRECT_DATE}>Je suis revenu(e) à la date indiquée</option>
          {/* Cas 2 : On demande à quelle date ? */}
          {absence && absence.date_start && (
            <option value={QUESTIONNAIRE_SUIVI_CHOICES.BACK_OTHER_DATE}>
              Je suis revenu(e) avant la date indiquée
            </option>
          )}
          {/* <option value={3}>Mon médecin recommande un mi-temps thérapeutique</option> */}
          {/* Cas 3 : On redirige vers la déclaration d'une absence */}
          <option value={QUESTIONNAIRE_SUIVI_CHOICES.BACK_PROLONGATION}>Mon absence est prolongée</option>
        </select>
      </label>
    </form>
  );
}
