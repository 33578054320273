import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'popstate-direction';

function RdvFinUI() {
  const navigate = useNavigate();
  const state = useLocation();

  const handleClick = () => {
    if (state.state) {
      if (state.state.etat === '3') {
        navigate('/declaration', { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    } else {
      navigate('/', { replace: true });
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">Votre demande de rendez-vous a bien été pris en compte</h3>

        <form className="form-legacy form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          <div className="col-12">
            <p>
              {' '}
              Les Ressources Humaines ont été informées de votre demande <br />
              <br />
            </p>
          </div>
        </form>
        <div className="position-relative col-12 col-md-8 mt-5 text-center">
          <button type="button" className="btn btn-kerij col-6 col-lg-3" onClick={handleClick}>
            {` Revenir à l'accueil`}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RdvFinUI;
