import { AbsenceCode, AbsenceCodeLabel } from './AbsenceCode';
import { ParcoursRuleComparableKey, ParcoursRuleIdentifiableKey, ParcoursRuleKey } from './ParcoursRuleKey';
import { ParcoursRuleOperatorComparable, ParcoursRuleOperatorIdentifiable } from './ParcoursRuleOperator';
import { ParcoursRuleValue } from './ParcoursRuleValue';

export type ParcoursRule = {
  [K in ParcoursRuleComparableKey]?: { [Op in ParcoursRuleOperatorComparable]: ParcoursRuleValue };
} & {
  [K in ParcoursRuleIdentifiableKey]?: { [Op in ParcoursRuleOperatorIdentifiable]: ParcoursRuleValue };
};

export const ParcoursRuleType = {
  [ParcoursRuleKey.AbsenceDateStart]: { type: 'date' },
  [ParcoursRuleKey.AbsenceDateEnd]: { type: 'date' },
  [ParcoursRuleKey.AbsenceCount]: { type: 'duration' },
  [ParcoursRuleKey.AbsenceTotalDuration]: { type: 'duration' },
  [ParcoursRuleKey.AbsenceParentDuration]: { type: 'number' },
  [ParcoursRuleKey.InternalCodeAbsence]: {
    type: 'enum',
    items: Object.values(AbsenceCode).map((value) => ({
      label: AbsenceCodeLabel[value],
      value,
    })),
  },
} as const;
export type ParcoursRuleType = (typeof ParcoursRuleType)[keyof typeof ParcoursRuleType];
