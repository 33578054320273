export const ParcoursRuleComparableKey = {
  AbsenceDateStart: '$absence.date_start$',
  AbsenceDateEnd: '$absence.date_end$',
  AbsenceTotalDuration: 'absence_total_duration',
  AbsenceParentDuration: 'absence_parent_duration',
  AbsenceCount: 'absence_count',
} as const;
export type ParcoursRuleComparableKey = (typeof ParcoursRuleComparableKey)[keyof typeof ParcoursRuleComparableKey];
export const isParcoursRuleComparableKey = (value: unknown): value is ParcoursRuleComparableKey =>
  value != null && Object.values(ParcoursRuleComparableKey).includes(value as any);

export const ParcoursRuleIdentifiableKey = {
  InternalCodeAbsence: 'absence_code',
} as const;
export type ParcoursRuleIdentifiableKey =
  (typeof ParcoursRuleIdentifiableKey)[keyof typeof ParcoursRuleIdentifiableKey];
export const isParcoursRuleIdentifiableKey = (value: unknown): value is ParcoursRuleIdentifiableKey =>
  value != null && Object.values(ParcoursRuleIdentifiableKey).includes(value as any);

export const ParcoursRuleKey = {
  ...ParcoursRuleComparableKey,
  ...ParcoursRuleIdentifiableKey,
} as const;
export type ParcoursRuleKey = (typeof ParcoursRuleKey)[keyof typeof ParcoursRuleKey];
export const isParcoursRuleKey = (value: unknown): value is ParcoursRuleKey =>
  value != null && Object.values(ParcoursRuleKey).includes(value as any);

export const ParcoursRuleKeyLabel = {
  // 🟠 Attention on ne met que du féminin car on ne gère pas les genres dans le code
  [ParcoursRuleKey.AbsenceDateStart]: "Date de début de l'absence",
  [ParcoursRuleKey.AbsenceDateEnd]: "Date de fin de l'absence",
  [ParcoursRuleKey.AbsenceTotalDuration]: 'Durée d’absence en J​',
  [ParcoursRuleKey.AbsenceParentDuration]: 'Durée d’absence continue',
  [ParcoursRuleKey.AbsenceCount]: "Quantité totale d'absences",
  [ParcoursRuleKey.InternalCodeAbsence]: 'Nature d’absence',
} satisfies Partial<Record<ParcoursRuleKey, string>>;

export const ParcoursRuleKeyDescription = {
  [ParcoursRuleKey.AbsenceDateStart]: '',
  [ParcoursRuleKey.AbsenceDateEnd]: '',
  [ParcoursRuleKey.AbsenceTotalDuration]:
    'Le critère “durée” vous permet d’établir un scénario qui tient compte de la durée d’une absence qu’elle soit continue ou discontinue',
  [ParcoursRuleKey.AbsenceParentDuration]: '',
  [ParcoursRuleKey.AbsenceCount]:
    'Le critère “fréquence” vous permet d’établir un scénario qui tient compte uniquement des fréquences (par exemple, pour repérer une succession d’arrêts courts qui peuvent être le signe d’un problème personnel ou professionnel)​',
  [ParcoursRuleKey.InternalCodeAbsence]:
    'Le critère “nature d’absence” permet de préciser les absences que vous souhaitez cibler. Par exemple, un collaborateur qui aurait plus de 3 accidents de travail déclaré sur 90 Jours.',
} satisfies Partial<Record<ParcoursRuleKey, string>>;
