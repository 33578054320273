import React from 'react';
import { AbsencesDeleteOptionsItem } from './data';
import { Typography } from '@mui/material';
import { AppInput } from '@/components/AppInput';

interface AbsencesDeleteCausePickerLabelProps {
  item: AbsencesDeleteOptionsItem;
  value: string | null;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const AbsencesDeleteCausePickerLabel = ({
  item,
  value,
  disabled,
  onChange,
}: AbsencesDeleteCausePickerLabelProps) => {
  return (
    <>
      {item.input ? (
        <>
          <div className="AbsencesDeleteCausePickerLabel">
            <Typography fontSize={14} color="primary" margin="auto" mr={0.5}>
              {item.label}
            </Typography>
            <AppInput
              name="inputCause"
              className="m-auto"
              sx={{ fontSize: 14, minWidth: '20rem' }}
              value={value ?? undefined}
              onChange={({ target }) => onChange(target.value)}
              onBlur={({ target }) => onChange(target.value)}
              disabled={disabled}
              placeholder="Indiquez la raison ici..."
            ></AppInput>
          </div>
        </>
      ) : (
        <Typography fontSize={14} color="primary">
          {item.label}
        </Typography>
      )}
    </>
  );
};
