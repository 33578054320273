import * as React from 'react';
import classNames from 'clsx';
import { Parcours } from '@/types/Parcours';
import { ParcoursListItem, type ParcoursListItemProps } from './ParcoursListItem';
import { PageSectionList, type PageSectionListProps } from '@/components/Page';

const componentName = 'ParcoursList';

export interface ParcoursListProps extends PageSectionListProps {
  items?: Array<Parcours>;
  onItemAction?: ParcoursListItemProps['onAction'];
}

export function ParcoursList(props: ParcoursListProps) {
  const { className, items, onItemAction, ...otherProps } = props;

  return items == null ? (
    <></>
  ) : (
    <PageSectionList className={classNames(componentName, className)} {...otherProps}>
      {items.map((item) => (
        <ParcoursListItem key={item.id} parcours={item} onAction={onItemAction} />
      ))}
    </PageSectionList>
  );
}
