import React, { useState } from 'react';
import CustomModal from '../../base/CustomModal';
import KerijButton from '../../base/button/kerijButton';
import ReactLoading from 'react-loading';
import { EmployeeInformation } from '../../../types/user.interface';
import { updateEmployeeNotification } from '../../../services/rh/employees';
import { notifications } from '../../../constants/notifications.constants';
import { StatusCodes } from 'http-status-codes';
import { useToast } from '@/components/Toast';
import { ActionButton } from '@/components/ActionButton';

interface NotificationManagementModalProperties {
  visible: boolean;
  handleCloseModal?: () => void;
  employeeInformation: EmployeeInformation;
  onUpdated?: (employeeInformation: EmployeeInformation) => void;
}

const NotificationManagementModal = ({
  visible,
  handleCloseModal,
  employeeInformation,
  onUpdated,
}: NotificationManagementModalProperties) => {
  const toast = useToast();
  const [notif, setNotif] = useState<string | null>(employeeInformation?.notification);
  const [raisonNotif, setRaisonNotif] = useState<string | null>();
  const [raisonNotifLabel, setRaisonNotifLabel] = useState<string | null>('');
  const [isLoading, setIsLoading] = useState(false);

  const raisons = [
    { value: 1, label: 'Raison de santé' },
    { value: 2, label: 'A la demande du salarié' },
    { value: 3, label: 'Autre' },
  ];

  return (
    <CustomModal
      open={visible}
      onClose={() => {
        handleCloseModal?.();
        setNotif(null);
        setRaisonNotif(null);
        setRaisonNotifLabel('');
      }}
      renderHeader={() => <> Gérer les notifications</>}
      renderFooter={() => (
        <ActionButton
          actionName="validate"
          onClick={() => {
            setIsLoading(true);
            updateEmployeeNotification({
              idEmployeeInformation: employeeInformation.id,
              isNotif: notif as string,
              reason: raisonNotifLabel as string,
              idBusiness: employeeInformation?.business.id,
            }).then((value) => {
              if (value.status === StatusCodes.OK) {
                setIsLoading(false);
                onUpdated?.(value.data);
                handleCloseModal?.();
                toast.present({ message: 'Les notifications ont été mises à jour', severity: 'success' });
              }
            });
          }}
        />
      )}
      renderContent={() => (
        <>
          <div className="card-actions-rh-header-modal row text-start p-2 mb-3">
            <div className="col-12 row text-start">
              <div className="col-3">
                <strong>
                  {employeeInformation?.firstname?.at(0)?.toUpperCase() +
                    employeeInformation?.firstname.slice(1).toLowerCase() +
                    ' ' +
                    employeeInformation?.lastname}
                </strong>
                <p className="no-p-margin">{employeeInformation?.business?.name}</p>
              </div>
            </div>
          </div>
          <label className="col-12 row">
            <select
              className="form-select"
              value={notif as string}
              onChange={(e) => {
                setNotif(e.target.value);
                if (e.target.value === '2') {
                  setRaisonNotif('1');
                  setRaisonNotifLabel('Raison 1');
                }
                if (e.target.value === '3') {
                  setRaisonNotif('1');
                  setRaisonNotifLabel('Raison 1');
                } else {
                  // setDataNotif()
                  setRaisonNotifLabel(null);
                }
              }}
              required
            >
              <option value={notifications.CODE_ALLNOTIFICATIONS}>Activer toutes les notifications</option>
              <option value={notifications.CODE_ONLYLEAVING}>
                {'Activer uniquement les notifications administratives'}
              </option>
              <option value={notifications.CODE_NONOTIFICATIONS}>Désactiver toutes les notifications</option>
            </select>
          </label>
          {notif === '2' && (
            <div className="type1-at container-fluid">
              <h4 className="question-form mt-3 mb-3 text-center">
                {"Pour quelle raison souhaitez-vous notifier le   salarié uniquement sur le suivi d'absence ?"}
              </h4>

              <form className="form-legacy mb-5 col-lg-10">
                <label className="col-12">
                  <select
                    className="form-select"
                    name="raisonNotif"
                    value={raisonNotif as string}
                    onChange={(e) => {
                      setRaisonNotif(e.target.value);
                      if (e.target.value !== '3') {
                        const index = (e.nativeEvent.target as HTMLSelectElement).selectedIndex;
                        setRaisonNotifLabel((e.nativeEvent.target as HTMLSelectElement)[index]?.innerHTML);
                      } else {
                        setRaisonNotifLabel('');
                      }
                    }}
                  >
                    {raisons.map((raison) => (
                      <option key={raison.value} value={raison.value}>
                        {raison.label}
                      </option>
                    ))}
                  </select>
                </label>
                {raisonNotif === '3' && (
                  <input
                    type="text"
                    className="card-text col-12 text-start"
                    name=""
                    value={raisonNotifLabel as string}
                    placeholder="Expliquer la raison..."
                    onChange={(e) => setRaisonNotifLabel(e.target.value)}
                  ></input>
                )}
              </form>
            </div>
          )}

          {notif === '3' && (
            <div className="type1-at container-fluid">
              <h4 className="question-form mt-3 mb-3 text-center">
                Pour quelle raison souhaitez-vous ne plus notifier le salarié ?
              </h4>

              <form className="form-legacy mb-5 col-lg-10">
                <label className="col-12">
                  <select
                    className="form-select"
                    name="raisonNotif"
                    value={raisonNotif as string}
                    onChange={(e) => {
                      setRaisonNotif(e.target.value);
                      if (e.target.value !== '3') {
                        const index = (e.nativeEvent.target as HTMLSelectElement).selectedIndex;
                        setRaisonNotifLabel((e.nativeEvent.target as HTMLSelectElement)[index]?.innerHTML);
                      }
                    }}
                    required
                  >
                    {raisons.map((raison) => (
                      <option key={raison.value} value={raison.value}>
                        {raison.label}
                      </option>
                    ))}
                  </select>
                </label>
                {raisonNotif === '3' && (
                  <input
                    type="text"
                    className="card-text col-12 text-start"
                    name=""
                    value={raisonNotifLabel as string}
                    placeholder="Expliquer la raison..."
                    onChange={(e) => setRaisonNotifLabel(e.target.value)}
                  ></input>
                )}
              </form>
            </div>
          )}
          {isLoading && (
            <CustomModal
              open={isLoading}
              renderContent={() => (
                <div className="container-fluid container-loading">
                  <h2>Veuillez patienter</h2>
                  <div className="container-load">
                    <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
                  </div>
                </div>
              )}
            ></CustomModal>
          )}
        </>
      )}
    />
  );
};

export default NotificationManagementModal;
