import { InputBase, styled } from '@mui/material';

export const AppInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  color: theme.palette.text.primary,
  position: 'relative',
  border: `1px solid`,
  borderColor: theme.palette.primaryBackground.dark,
  backgroundColor: theme.palette.common.white,
  fontSize: '1rem',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  '&:focus-within': {
    boxShadow: theme.shadows[1],
  },
  '& .MuiInputBase-input': {
    padding: '0.25em 0',
    outline: 'none',
  },
}));
