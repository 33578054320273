import axios from '@/lib/axios';
import { varToStringParams } from '../../shared/utils/paths';

interface RetrieveEmployeeRequest {
  id: string;
}

export const retrieveEmployee = async ({ id }: RetrieveEmployeeRequest): Promise<any> => {
  return await axios.get(`/api/rh/employee/${id}`);
};

interface CreateEmployeeAccountRequest {
  email: string;
  phone: string;
  idEmployeeInformation: string;
  idBusiness: string;
}

export const createEmployeeAccount = async (employee: CreateEmployeeAccountRequest): Promise<any> => {
  return await axios.post('/api/rh/create_employee_account', employee);
};

export const verifyEmployeeEmail = async (email: string): Promise<any> => {
  return await axios.post('/api/rh/verify_email_employee', { email });
};

export const verifyEmployeePhone = async (phone: string): Promise<any> => {
  return await axios.post('/api/rh/verify_phone_employee', { phone });
};

interface UpdateEmployeeNotificationRequest {
  idEmployeeInformation: string;
  isNotif: string;
  reason: string;
  idBusiness: string;
}

export const updateEmployeeNotification = async (notification: UpdateEmployeeNotificationRequest): Promise<any> => {
  return await axios.put('/api/rh/update_notification_employee', notification);
};

interface EmployeeContactRequest {
  params: {
    [idEmployeeInformation: string]: string;
  };
}

export const retrieveEmployeeContacts = async (payload: EmployeeContactRequest | any): Promise<any> => {
  const query = varToStringParams({
    variablesArray: Object.keys(payload.params).map((key) => ({
      key: key,
      value: payload.params[key],
    })),
  });
  return await axios.get(`/api/rh/employee_contact${query}`);
};

export interface ISearchEmployeeParams {
  firstname?: string;
  lastname?: string;
  description?: string;
  portfolio?: 'true';
  offset?: number;
  limit?: number;
}

export const rhSearchEmployee = async (params: ISearchEmployeeParams): Promise<any> => {
  const query = varToStringParams({
    variablesArray: [
      {
        key: 'firstname',
        value: params.firstname,
      },
      {
        key: 'lastname',
        value: params.lastname,
      },
      {
        key: 'description',
        value: params.description,
      },
      {
        key: 'portfolio',
        value: params.portfolio,
      },
      {
        key: 'offset',
        value: params.offset,
      },
      {
        key: 'limit',
        value: params.limit,
      },
    ],
  });
  return await axios.get(`/api/rh/employees${query}`);
};

export const addEmployeeContact = async (data: any) => {
  return await axios.put('/api/rh/employee_contact', data);
};

export const validatePreferencesEmployee = async (token: string, data: any): Promise<any> => {
  return await axios.post('/api/rh/validate_preferences_employee', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const validateResponseVisitEmployee = async (token: string, data: any): Promise<any> => {
  return await axios.post('/api/rh/validate_salarie_visite_response', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
