import React, { useMemo } from 'react';
import { Box, Divider } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { type RHStep } from './data';
import { ActionButton, ActionButtonProps } from '@/components/ActionButton';
import { PageTitle } from '@/components/Page';

const componentName = 'RHStepperContent';

export interface RHStepperContentProps {
  activeStep: number;
  steps: Array<RHStep>;
  disabled?: boolean;
  onNext: () => void;
  onBack: () => void;
  submitProps: ActionButtonProps & { onSubmit?: () => void };
  alternativeSubmitProps?: ActionButtonProps & { onSubmit?: () => void };
}

export function RHStepperContent(props: RHStepperContentProps) {
  const {
    steps,
    activeStep,
    disabled,
    onNext,
    onBack,
    alternativeSubmitProps: { onSubmit: onAlternativeSubmit, ...alternativeSubmitProps } = {},
    submitProps: { onSubmit, ...submitProps } = {},
  } = props;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const stepCurrent = steps.at(activeStep);
  const handleNext = (alternative?: boolean) => {
    scrollToTop();
    const isSubmit = activeStep + 1 === steps.length;
    if (isSubmit) alternative ? onAlternativeSubmit?.() : onSubmit?.();
    else {
      onNext();
    }
  };

  const handleBack = () => {
    scrollToTop();
    onBack();
  };

  const isSubmitStep = useMemo(() => activeStep === steps.length - 1, [activeStep, steps.length]);

  return (
    <Box className={componentName}>
      {stepCurrent?.title ? (
        <PageTitle
          paddingTop={2.5}
          paddingBottom={2.5}
          title={stepCurrent?.title}
          subtitle={stepCurrent?.subtitle}
          avatar={stepCurrent?.avatar}
          divider
        />
      ) : null}
      <Box marginTop={2.5} marginBottom={2.5}>
        {stepCurrent?.children}
      </Box>
      <Box textAlign="right">
        <Spacer mt={2} />
        <Divider />
        <Spacer size="small" />

        <ActionButton
          variant="text"
          actionName="previous"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        />

        {isSubmitStep && alternativeSubmitProps != null && (
          <ActionButton
            disabled={disabled}
            onClick={() => handleNext(true)}
            sx={{ mr: 1 }}
            {...alternativeSubmitProps}
          />
        )}

        <ActionButton
          variant="contained"
          actionName={isSubmitStep ? 'finish' : 'next'}
          disabled={disabled}
          onClick={() => handleNext()}
          sx={{ mr: 1 }}
          label={isSubmitStep ? submitProps.label : undefined}
        />
      </Box>
    </Box>
  );
}
