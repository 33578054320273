import { z } from 'zod';
import { ParcoursAction, ParcoursActionName } from './ParcoursAction';
import { ParcoursRule } from './ParcoursRule';

export const ParcoursScriptReferenceDate = z.enum(['parcoursTriggeredAt', 'absenceLastDateEnd'] as const, {
  description: 'Reference date for script action trigger',
});
export type ParcoursScriptReferenceDate = z.infer<typeof ParcoursScriptReferenceDate>;

export const ParcoursScriptWhen = z.object({
  reference_date: ParcoursScriptReferenceDate,
  delay: z.number({ description: 'Delay from date in milliseconds' }).optional(),
});
export interface ParcoursScriptWhen extends z.infer<typeof ParcoursScriptWhen> {}

export interface ParcoursScript {
  $schema?: string | null;
  version: number;
  rule: {
    where: Array<ParcoursRule>;
  };
  action?: Record<ParcoursActionName, ParcoursAction>;
  when?: ParcoursScriptWhen;
}
