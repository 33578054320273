import React, { useState } from 'react';
import { ContentStructure } from './common';
import { RHNotification } from '@/types/rh.interface';

import { DeleteAbsenceModal } from '../ActionCardModal/DeleteAbsenceModal';
import { deleteAbsence } from '@/services/rh/absences/service';
import { Button } from '@/components/Button';
import { useToast } from '@/components/Toast';

export interface PathologyOverLimitActionsProps {
  rhNotification: RHNotification;
  onActionDone: () => void;
}

export const PathologyOverLimitContent = () => {
  return (
    <ContentStructure
      title={'Dépassement de congé pathologique'}
      description={`Cette demande de congé pathologique dépasse la limite autorisée de 14 j.

        Après renseignements auprès de la CPAM concernée, veuillez scinder cette déclaration en 2 arrêts distincts.`}
    />
  );
};

export const PathologyOverLimitActions = ({ rhNotification, onActionDone }: PathologyOverLimitActionsProps) => {
  const toast = useToast();

  const [isVisible, setIsVisible] = useState(false);
  return (
    <div>
      <Button onClick={() => setIsVisible(true)}>{`Gérer le dépassement`}</Button>
      <DeleteAbsenceModal
        visible={isVisible}
        setVisible={setIsVisible}
        onDeleteAbsence={async (description) => {
          if (rhNotification.absence)
            await deleteAbsence({
              params: {
                idAbsence: rhNotification.absence.id,
              },
              body: {
                action: 'delete',
                description: description,
              },
            });
          toast.present({ message: `Absence supprimée`, severity: 'success' });
          onActionDone();
          setIsVisible(false);
        }}
      />
    </div>
  );
};
