import React, { useState } from 'react';
import { defaultParcoursScriptAction, ParcoursActionsInput } from '@/components/ParcoursInput';
import { Parcours } from '@/types';
import { PageFormBody, PageFormHelperText } from '@/components/Page';

export interface ParcoursActionStepProps {
  value?: Parcours['script']['action'];
  onChange?: (value: Parcours['script']['action']) => void;
}

export const ParcoursActionStep = (props: ParcoursActionStepProps) => {
  const { value, onChange } = props;
  const [model, setModel] = useState(value ?? defaultParcoursScriptAction);

  return (
    <PageFormBody
      before={
        <PageFormHelperText>
          <p>Le corps du mail contient 3 parties:</p>
          <ul>
            <li>Un objet qui reprend le titre de votre parcours</li>
            <li>
              Un champ réservé à KERij qui permettra d’identifier le collaborateur pour lequel le message est généré
            </li>
            <li>
              Un cadre libre qui vous permet d’expliquer l’objet du mail, ce qui est attendu par les destinataires et
              d’ajouter des liens HTML
            </li>
          </ul>
        </PageFormHelperText>
      }
    >
      <ParcoursActionsInput
        value={model}
        onChange={(e, value) => {
          setModel(value);
          onChange?.(value);
        }}
      />
    </PageFormBody>
  );
};
