export enum PRO_URL {
  PREFIXE_PRO = '/pro/',
  PRO = '/pro',
  ACCUEIL = 'accueil',
  ABSENCES = 'absences',
  ABSENCE_HISTORY = 'historique_des_absences',
  PROGRESS_ABSENCE = 'absences_en_cours',
  PROFIL_SALARIE = 'profilsalarie',
  ACTIONS = 'actions',
  EXTRACTED_ABSENCES = 'absencesextract',
  STATS = 'stats',
  STATS_1 = 'stats/1',
  LEGAL_INTERVIEW = 'entretienslegaux',
  LIST_LIAISON_INTERVIEW = 'listentretienliaison',
  LIST_RETURN_INTERVIEW = 'listentretienreturn',
  TOOLS = 'tools',
  LOGIN = 'login',
  PASSWORD = 'password',
  CONFIRMATION = 'confirmation',
  SERVICE_ANIMATION = 'service-animation',
  STATS_EN_COURS = 'stats/encoursglobal',
  STATS_GENERAL_TREND = 'stats/tendancegenerale',
  STATS_CHART_ARRET = 'stats/linechartarret',
  CHART_HOSPITALIZATION = 'stats/linecharthospitalisation',
  CHART_PARENTALITY = 'stats/linechartparentalite',
  CHART_TAUX = 'stats/radarcharttaux',
  CHART_PREVALENCE = 'stats/radarchartprevalence',
  CHART_OCCURENCE = 'stats/radarchartoccurrence',
  CHART_GRAVITE = 'stats/barchartgravite',
  VALIDATION_PROFIL = 'validationprofil',
  DETAILS_ABSENCE = 'detailsabsence',
  PREFERENCES_EMPLOYEE = 'preferencesemployee',
  DATE_CHOICE_MANAGER = 'choixdatemanager',
  DATE_CHOICE_MANAGER_END = 'choixdatemanagerfin',
  DATE_CHOICE_EMPLOYEE = 'choixdatesalarie',
  DATE_CHOICE_SST = 'choixdatesst',
  CREATE_HR_LEAVE = 'creerarret',
  EDIT_HR_LEAVE = 'editarrets',
  DELETE_HR_LEAVE = 'deletearrets',
  DATE_CHOICE_EMPLOYEE_END = 'choixdatesalariefin',
  DECLARATION_END_HR = 'declaration-end-hr',
  PROFIL = 'profil',
  ENTRETIEN_LEGAL = 'entretiens-legal',
  COLLABORATEURS = 'collaborateurs',
  PARCOURS = 'parcours',
  PARCOURS_CREATE = 'parcours/create',
  PARCOURS_EDIT = 'parcours/edit',
  PARCOURS_DETAIL = 'parcours/detail',
}

export enum ADMIN_URL {
  ADMIN = 'admin',
  USERS = 'admin/users',
  ROLES = 'admin/roles',
  ADD_SERVICE = 'admin/addservice',
  FILES = 'admin/files',
  SETTINGS = 'admin/settings',
  SERVICES = 'admin/admin_service',
  CORRESP_TABLE = 'admin/corresp_table',
}

export const ExternalURL = {
  Bot: 'https://prod.kerij-application.captive.dev',
} as const;
