import React, { ForwardedRef } from 'react';
import { AlertProps as MUIAlerteProps, Avatar, styled, Alert as MUIAlert } from '@mui/material';
import { CheckRounded as Check, InfoRounded } from '@mui/icons-material';

export interface AlertProps extends Omit<React.ComponentProps<typeof Alert>, 'severity' | 'sx' | 'icon'> {
  severity?: 'success' | 'error';
}

export const Alert = React.forwardRef(function Alert(props: MUIAlerteProps, ref: ForwardedRef<HTMLDivElement>) {
  const { children, severity, ...othersProps } = props;

  return (
    <ToastAlert
      ref={ref}
      icon={severity === 'success' ? <CheckRounded /> : <InfoRounded />}
      severity={severity}
      sx={(theme) => ({
        '& .MuiAlert-icon': {
          '& .MuiSvgIcon-root': {
            padding: severity === 'success' ? 0.25 : 0,
            color: severity === 'success' ? theme.palette.primaryBackground.contrastText : theme.palette.error.main,
          },
        },
      })}
      {...othersProps}
    >
      {children}
    </ToastAlert>
  );
});

const CheckRounded = () => (
  <InfoAvatar sx={(theme) => ({ background: theme.palette.secondary.light })}>
    <Check />
  </InfoAvatar>
);
const InfoAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  fontSize: 24,
  marginRight: 10,
  padding: 2,
  fontWeight: 'bolder',
  width: 24,
  height: 24,
}));

const ToastAlert = styled(MUIAlert)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[4],
  border: theme.shape.borderColorVariant,
  width: '100%',
  '& .MuiAlert-icon': {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 10,
    margin: 0,
  },
  '& .MuiAlert-message': {
    paddingTop: 16,
    paddingBottom: 16,
    fontSize: 14,
  },
  '& .MuiAlert-action': {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 64,
    paddingRight: 20,
    fontSize: 14,
    '& .MuiButtonBase-root': {
      padding: 0.5,
      marginRight: 2,
      color: theme.palette.grey[700],
    },
  },
}));
