import axios from '@/lib/axios';
import { Parcours } from '@/types/Parcours';
import { AxiosResponse } from 'axios';

export const parcoursUpdate = async (params: parcoursUpdate.Parameters) => {
  return await axios.post(`/api/rh/parcours/${params.id}`, params);
};

export namespace parcoursUpdate {
  export type Parameters = {
    id: Parcours['id'];
    name?: Parcours['name'];
    legal?: Parcours['legal'];
    script?: Parcours['script'];
    active?: Parcours['active'];
  };
  export type Return = AxiosResponse;
}
