import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import axios from '@/lib/axios';
import { StatusCodes } from 'http-status-codes';
import { fetchRhProfile } from '@/services/rh';
import { RHAccountContext, useAppDispatch } from '@/store';
import { PRO_URL } from '@/constants/urls.constants';

function ConfirmationRH() {
  const dispatch = useAppDispatch();
  const [code, setCode] = useState('');
  const [error, setError] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = setCode;
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/rh/confirm_code', {
        codeValidation: code,
        password: location.state?.password,
      });
      if (response.status === StatusCodes.OK) {
        const rhProfileResponse = await fetchRhProfile();
        dispatch(RHAccountContext.actions.setRh(rhProfileResponse.data.rh));
        navigate(PRO_URL.PREFIXE_PRO + PRO_URL.VALIDATION_PROFIL);
      }
    } catch (error) {
      alert(error);
    }
  };
  const handleResendClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const response = await axios.post('/api/rh/send_code');

    if (response.status === StatusCodes.OK) {
      setError(2);
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.CONFIRMATION, {
        state: {
          id_rh_login: location.state?.id_rh_login,
          password: location.state?.password,
        },
      });
    } else {
      setError(1);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="form-legacy form-code mt-5 mb-5">
          <h3 className="question-form mb-5">Code de vérification</h3>

          <div className="msg-code">
            <em>Veuillez entrer le code de vérification reçu par email</em>
          </div>
          {/* <div className="msg-code"><em>Code envoyé au 06 ** ** ** 73</em> </div> */}

          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={4}
            renderSeparator={<span style={{ width: '10px' }}></span>}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={true}
            inputStyle={{
              width: '3.70rem',
            }}
            {...{ autocomplete: 'one-time-code' }}
          />
          {error === 1 && (
            <div className="col-12 mb-3" style={{ color: 'red' }}>
              Une erreur est survenue, veuillez réessayer.
            </div>
          )}
          <button className="btn-submit">Vérifier</button>
        </form>

        <div className="col-6 text-center">
          <button className="btn-kerij-outline btn-sm" onClick={handleResendClick}>
            {`Je n'ai pas reçu de code`}
          </button>
          {error === 2 && (
            <div className="mt-3" style={{ color: 'green' }}>
              {' '}
              Un code vous a été renvoyé à votre adresse mail
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfirmationRH;
