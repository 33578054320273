import * as React from 'react';
import classNames from 'clsx';
import { Tab, type IconProps } from '@mui/material';
import { ParcoursRule } from '@/types';
import { Category, Start, Summarize, Timelapse, Timer } from '@mui/icons-material';

const getKey = (parcoursRule: ParcoursRule): keyof ParcoursRule =>
  // @ts-ignore We know what we are doing
  Object.keys(parcoursRule)[0];
const iconMap: Record<keyof ParcoursRule, React.ReactElement> = {
  absence_code: <Category />,
  absence_parent_duration: <Timelapse />,
  absence_count: <Summarize />,
  absence_total_duration: <Timer />,
  '$absence.date_end$': <Tab />,
  '$absence.date_start$': <Start />,
};

const componentName = 'ParcoursRuleIcon';

export interface ParcoursRuleIconProps extends IconProps {
  parcoursRule: ParcoursRule;
}

export function ParcoursRuleIcon(props: ParcoursRuleIconProps) {
  const { parcoursRule, ...otherProps } = props;
  const template = iconMap[getKey(parcoursRule)];

  return React.cloneElement(template, {
    className: classNames(template.props.className, componentName),
    ...otherProps,
  });
}
