import { Absence, CPAM } from '@/types';
import { createAbsenceRequest, absenceRequest, updateAbsenceRequest } from './data';
import axios from '@/lib/axios';
import { isAxiosError } from 'axios';

export const createAbsence = async ({ params, body }: createAbsenceRequest) => {
  return await axios.post<{ cpam: CPAM; absence: Absence }>(
    `/api/rh/declare_absence/${params.idBusiness}/${params.idEmployeeInformation}`,
    body,
  );
};

export const updateAbsence = async ({ params, body }: updateAbsenceRequest): Promise<any> => {
  return await axios.put(`/api/rh/absences/${params.idAbsence}`, body);
};

export const deleteAbsence = async ({ params, body }: absenceRequest): Promise<any> => {
  return await axios.delete(`/api/rh/absence/${params.idAbsence}`, { data: body });
};

export const acceptAbsence = async ({ params, body }: absenceRequest): Promise<any> => {
  return await axios.put(`/api/rh/absence/${params.idAbsence}/validate`, body);
};

export const finishAbsence = async (idAbsence: string | number): Promise<any> => {
  return await axios.put(`/api/rh/absence/${idAbsence}/finish`);
};

export const deleteDocument = async (idDocument: string | number): Promise<any> => {
  return await axios.delete(`/api/documents/${idDocument}`);
};

export const exportAbsence = async (idAbsence: string): Promise<void> => {
  await axios.post(`/api/rh/absences/${idAbsence}/export`);
};

export const runJob = async (
  ...job: // Export justificatifs
  // [jobName, jobParameters? {}]
  ['export-justificatifs'] | ['export-absences']
) => {
  return await axios.get(`/api/rh/jobs/${job[0]}`);
};

export const verifyAbsenceDate = async (payload: verifyAbsenceDate.Parameters) => {
  try {
    return await axios.post('/api/rh/declare_absence/verify_date', payload);
  } catch (e: unknown) {
    if (isAxiosError(e)) return e.response;
  }
};

export namespace verifyAbsenceDate {
  export interface Parameters {
    absenceType: string;
    absenceType1?: string;
    absenceType2?: string;
    dateStart: string;
    dateEnd: string;
    idEmployeeInformation: string;
  }

  export type Return = any;
}
