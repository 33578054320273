import { RHNotification, DocumentStatus } from '@/types';
import React, { useState } from 'react';
import { ContentStructure } from './common';
import { VerifyingFileModal } from '@/features/dashboard-rh/create-absence-rh/components/Modal/OCR/VerifyingFileModal';
import { Button } from '@/components/Button';

export interface PendingValidationDocumentActionsProps {
  rhNotification: RHNotification;
  onVerifyDocument: () => void;
}

export const PendingValidationDocumentContent = () => {
  return (
    <div>
      <ContentStructure
        title={'Un justificatif doit être vérifié'}
        description={'Le salarié a déposé un justificatif qui doit être vérifié afin de valider ou non son absence'}
      />
    </div>
  );
};

export const PendingValidationDocumentActions = ({
  rhNotification,
  onVerifyDocument,
}: PendingValidationDocumentActionsProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { absence, employee_information } = rhNotification;

  const selectedDocument = absence?.documents.find((doc) => doc?.status === DocumentStatus.PENDING);
  return (
    <div>
      <Button onClick={() => setIsVisible(true)}>{`Vérifier le justificatif`}</Button>
      {selectedDocument &&
        (employee_information && absence ? (
          <VerifyingFileModal
            isVisible={isVisible}
            onCloseModal={() => setIsVisible(false)}
            absence={absence}
            employeeInformation={employee_information}
            document={selectedDocument}
            onDocumentUpdated={(document) => {
              onVerifyDocument();
            }}
          />
        ) : (
          <></>
        ))}
    </div>
  );
};
