import * as React from 'react';
import { Parcours, ParcoursOwner } from '@/types';
import { Gavel, CodeBranch, KERij } from '@/components/AppIcon';

// Name and options

const iconStyle = {
  height: '50%',
};

// Component properties
export interface ParcoursAvatarIconProps {
  parcours: Pick<Parcours, 'legal' | 'active' | 'owner'>;
}

export function ParcoursAvatarIcon(props: ParcoursAvatarIconProps) {
  const { parcours, ...otherProps } = props;
  if (parcours.owner === ParcoursOwner.Admin) return <KERij sx={iconStyle} {...otherProps} />;
  if (parcours.legal) return <Gavel sx={iconStyle} {...otherProps} />;
  return <CodeBranch sx={iconStyle} {...otherProps} />;
}
