import { parcoursCreate, parcoursDelete, parcoursFindAll, parcoursUpdate } from '@/services/rh/parcours';
import { Parcours } from '@/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const ParcoursQuery = (() => {
  const useIndex = () =>
    useQuery('parcoursIndex', async () => {
      return parcoursFindAll();
    });

  const useCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(
      async (data: parcoursCreate.Parameters) => {
        const response = await parcoursCreate(data);
        return response.data as { data?: Parcours };
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('parcoursIndex');
        },
      },
    );
  };

  const useUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation(
      async (data: parcoursUpdate.Parameters) => {
        const response = await parcoursUpdate(data);
        return response.data as { data?: Parcours };
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('parcoursIndex');
        },
      },
    );
  };

  const useDelete = () => {
    const queryClient = useQueryClient();

    return useMutation(
      async (...args: Parameters<typeof parcoursDelete>) => {
        const response = await parcoursDelete(...args);
        return response.data;
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('parcoursIndex');
        },
      },
    );
  };

  return {
    useCreate,
    useIndex,
    useUpdate,
    useDelete,
  };
})();
