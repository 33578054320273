import * as React from 'react';
import classNames from 'clsx';
import DOMPurify from 'dompurify';
// Name and options
const componentName = 'HTMLViewer';

type HTMLContent = string;

// Component properties
export interface HTMLViewerProps extends React.ComponentProps<'div'> {
  before?: React.ReactNode;
  /**
   * HTML Content. Will be "purified" using DOMPurify
   */
  htmlContent?: HTMLContent;
  after?: React.ReactNode;
}

/**
 * @example
 * <HTMLViewer
 *   before={<p>This is a first line</p>}
 *   content={{ __html: '<b>This is bold</b>' }}
 *   after={<p>This is a last line</p>}
 * />
 * @param props
 */
export function HTMLViewer(props: HTMLViewerProps) {
  const { className, children, before, after, htmlContent, ...otherProps } = props;
  // eslint-disable-next-line xss/no-mixed-html
  const safeHTML = React.useMemo(
    () => ({
      // eslint-disable-next-line xss/no-mixed-html
      __html: htmlContent == null ? '' : DOMPurify.sanitize(htmlContent),
    }),
    [htmlContent],
  );

  return (
    <div className={classNames(componentName, className)} {...otherProps}>
      {before}
      <div dangerouslySetInnerHTML={safeHTML} />
      {children}
      {after}
    </div>
  );
}
