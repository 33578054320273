import React from 'react';

interface SpacerStandardProps {
  size?: 'small' | 'medium' | 'large';
}

interface SpacerCustomProps {
  mt: number;
}

type SpacerProps = SpacerStandardProps | SpacerCustomProps;

const isCustom = (props: SpacerProps): props is SpacerCustomProps => Object.hasOwn(props, 'mt');

export const Spacer = (props: SpacerProps) => {
  const marginTop = isCustom(props) ? props.mt : props.size === 'small' ? 0.5 : props.size === 'large' ? 2 : 1.25;

  return (
    <>
      <div style={{ marginTop: `${marginTop}rem` }}></div>
    </>
  );
};
