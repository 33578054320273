import * as React from 'react';
import { Typography } from '@mui/material';

export interface ParcoursTutorialStepProps {}

export const ParcoursTutorialStep = (props: ParcoursTutorialStepProps) => {
  return (
    <Typography component="p" whiteSpace={'pre-wrap'}>
      {`Bienvenue sur la page de programmation des mails automatisés.
Grâce à ce module vous allez pouvoir organiser et piloter l’envoi de mails contextualisés !

Ces mails peuvent être adressés au salarié présent ou absent et à l’ensemble des interlocuteurs que vous avez identifiés pour conduire vos différents processus, tels que les visites médicales, les entretiens de retour à l’emploi, les entretiens de liaison ou encore le suivi du retour à l’emploi.

Pour créer un parcours mail, vous devrez suivre 5 étapes successives et éditer le parcours.

A tout moment le parcours pourra être modifié, supprimé ou simplement désactivé.`}
    </Typography>
  );
};
