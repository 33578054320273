import * as React from 'react';
import classNames from 'clsx';
import { Avatar, Typography, type TypographyProps, styled } from '@mui/material';
import { ParcoursRule as ParcoursRuleType } from '@/types';
import { extractRule } from './extractRule';

const componentName = 'ParcoursRuleDetailText';

export interface ParcoursRuleDetailTextProps extends TypographyProps {
  parcoursRule: ParcoursRuleType;
}

export function ParcoursRuleDetailText(props: ParcoursRuleDetailTextProps) {
  const { parcoursRule, ...otherProps } = props;

  const extractedRule = extractRule(parcoursRule);

  return extractedRule.success === true ? (
    <Typography className={classNames(componentName)} variant="inherit" component="span" {...otherProps}>
      {extractedRule.rule.operator} {extractedRule.rule.value}
      {extractedRule.rule.options && Object.keys(extractedRule.rule.options).length > 0 && (
        <>
          {' '}
          <AvatarRule>{JSON.stringify(extractedRule.rule.options, undefined, 2)}</AvatarRule>
        </>
      )}
    </Typography>
  ) : null;
}

const AvatarRule = styled(Avatar)(({ theme }) => ({
  height: 24,
  width: 24,
  fontSize: 16,
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primaryBackground.contrastText,
}));
