/* eslint-disable security/detect-object-injection */
import * as React from 'react';
import { ParcoursScriptWhen } from '@/types';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { AppSelect } from '../AppSelect';

/**
 * Display the delay
 */
export function parcoursWhenDelay(delayInDays: number) {
  return delayInDays === 0 ? 'J' : delayInDays < 0 ? `J-${Math.abs(delayInDays)}` : `J+${delayInDays}`;
}

type InputValue = ParcoursScriptWhen;

export interface ParcoursWhenInputProps {
  value?: InputValue;
  onChange?: (value: InputValue) => void;
}

export function ParcoursWhenInput(props: ParcoursWhenInputProps) {
  const { value = { reference_date: 'parcoursTriggeredAt', delay: 0 }, onChange } = props;
  const useField = <K extends keyof InputValue>(fieldName: K) => ({
    value: value[fieldName],
    onChange: (fieldValue: InputValue[K]) =>
      onChange?.({
        ...value,
        [fieldName]: fieldValue,
      }),
  });
  const referenceDateController = useField('reference_date');
  const delayController = useField('delay');
  const delayItems = React.useMemo(
    () =>
      // Generate J-X and J+X values
      [-10, -5, -2, 0, 2, 5, 10].map((delayInDays) => ({
        value: delayInDays,
        label: parcoursWhenDelay(delayInDays),
      })),
    [],
  );
  return (
    <Stack direction="column" spacing={4}>
      <FormControl>
        <FormLabel>Date de réference</FormLabel>
        <RadioGroup
          {...referenceDateController}
          onChange={(event) =>
            referenceDateController.onChange(
              // @ts-ignore Data are ok
              event.target.value,
            )
          }
        >
          {[
            { value: 'parcoursTriggeredAt' as const, label: "Date d'éligibilité" },
            { value: 'absenceLastDateEnd' as const, label: "Date de fin d'absence connue" },
          ].map((option) => {
            return (
              <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
            );
          })}
        </RadioGroup>
      </FormControl>
      <AppSelect
        label="Retardateur"
        items={delayItems}
        helperText="Délai par rapport à la date de référence"
        {...delayController}
        onChange={(_, value) => delayController.onChange(value)}
      />
    </Stack>
  );
}
