import React, { useEffect, useMemo } from 'react';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { PermissionBox } from '@/components/PermissionBox';
import { ParcoursList, ParcoursListProps } from '@/components/ParcoursList';
import { ParcoursQuery } from '@/query';
import { ActionButton } from '@/components/ActionButton';
import { IfPermission } from '@/components/IfPermission';
import { RHPage } from '@/components/RHPage';
import { type RHStep, RHStepper, RHStepperContent } from '@/components/RHStepper';
import { ParcoursProgramStep, type ParcoursProgramStepProps } from '@/components/ParcoursEditor/ParcoursProgramStep';
import { useToast } from '@/components/Toast';
import { ParcoursRulesStep, ParcoursRulesStepProps } from '@/components/ParcoursEditor/ParcoursRulesStep';
import { ParcoursWhenStep, type ParcoursWhenStepProps } from '@/components/ParcoursEditor/ParcoursWhenStep';
import { Parcours, ParcoursOwner } from '@/types';
import { ParcoursActionStep } from '@/components/ParcoursEditor/ParcoursActionStep';
import { defaultParcoursScriptAction } from '@/components/ParcoursInput/ParcoursActionsInput';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PRO_URL } from '@/constants/urls.constants';
import { ParcoursActionContactStep } from '@/components/ParcoursEditor/ParcoursActionContactStep';
import { ParcoursDetail } from '@/components/ParcoursDetail';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import {
  ParcoursConfirmationStep,
  type ParcoursConfirmationStepProps,
} from '@/components/ParcoursEditor/ParcoursConfirmationStep';
import {
  CategoryRounded,
  ChecklistRounded,
  MenuBookRounded,
  ScheduleSendRounded,
  VisibilityRounded,
  DrawRounded,
  ContactMailOutlined,
} from '@mui/icons-material';
import { ParcoursTutorialStep } from '@/components/ParcoursEditor/ParcoursTutorialStep';
import { File } from '@/components/AppIcon';

const defaultWhen = {
  reference_date: 'parcoursTriggeredAt' as const,
  delay: 0,
};

export const ParcoursPage: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const query = ParcoursQuery.useIndex();
  const parcoursDelete = ParcoursQuery.useDelete();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [selectedParcours, setSelectedParcours] = React.useState<Parcours | undefined>();
  const [submitting, setSubmitting] = React.useState(false);
  const [program, setProgram] = React.useState<ParcoursProgramStepProps['value']>({ name: undefined, legal: false });
  const [rules, setRules] = React.useState<ParcoursRulesStepProps['value']>([]);
  const [action, setAction] = React.useState<Parcours['script']['action']>(defaultParcoursScriptAction);
  const [when, setWhen] = React.useState<ParcoursWhenStepProps['value']>(defaultWhen);

  const getPreviewData = (parcoursPrevious?: Parcours): ParcoursConfirmationStepProps['value'] => {
    const formData = { rules, action, when };
    return {
      name: program?.name ?? parcoursPrevious?.name,
      legal: program.legal ?? parcoursPrevious?.legal ?? false,
      active: parcoursPrevious?.active ?? false,
      owner: ParcoursOwner.User,
      script: {
        version: 1,
        ...parcoursPrevious?.script,
        rule: {
          ...parcoursPrevious?.script.rule,
          where: formData.rules,
        },
        action: formData.action ?? parcoursPrevious?.script.action,
        when: formData.when ?? parcoursPrevious?.script.when,
      },
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const steps: RHStep[] = [
    {
      title: "Mode d'emploi",
      children: <ParcoursTutorialStep />,
      avatar: <MenuBookRounded />,
    },
    {
      title: 'Catégoriser un programme',
      isValid: () => !isEmpty(program.name),
      children: <ParcoursProgramStep value={program} onChange={setProgram} />,
      avatar: <CategoryRounded />,
    },
    {
      title: 'Définir les critères',
      children: <ParcoursRulesStep value={rules} onChange={setRules} />,
      avatar: <ChecklistRounded />,
    },
    {
      title: 'Choisir les destinataires',
      isValid: () =>
        Object.entries(action ?? {}).every(([_, actionDefinition]) =>
          actionDefinition._ === 'ParcoursActionEmail' ? (actionDefinition.to?.length ?? 0) > 0 : true,
        ),
      children: <ParcoursActionContactStep value={action} onChange={setAction} />,
      avatar: <ContactMailOutlined />,
    },
    {
      title: 'Rédiger le contenu',
      children: <ParcoursActionStep value={action} onChange={setAction} />,
      avatar: <DrawRounded />,
    },
    {
      title: "Déclencher l'email",
      children: <ParcoursWhenStep value={when} onChange={setWhen} />,
      avatar: <ScheduleSendRounded />,
    },
    {
      title: 'Valider mon parcours',
      subtitle: 'Vérifiez votre parcours avant de le valider',
      avatar: <VisibilityRounded />,
      children: <ParcoursConfirmationStep value={getPreviewData(selectedParcours)} />,
    },
  ];

  const goToIndex = () => navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PARCOURS);
  const reset = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setSelectedParcours(undefined);
    setProgram({ name: undefined, legal: false });
    setRules([]);
    setAction(defaultParcoursScriptAction);
    setWhen(defaultWhen);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const isEmpty = (value?: string) => value == null || value.trim() === '';
  const isValidStep = useMemo(() => {
    return steps[activeStep]?.isValid?.() ?? true;
  }, [activeStep, steps]);

  const pageView =
    location.pathname.endsWith('/edit') || location.pathname.endsWith('/create')
      ? ('edit' as const)
      : location.pathname.endsWith('/detail')
      ? ('detail' as const)
      : ('list' as const);

  const parcoursCreateMutation = ParcoursQuery.useCreate();
  const parcoursUpdateMutation = ParcoursQuery.useUpdate();
  const parcoursCurrent = React.useMemo(
    () => query.data?.find((_) => _.id === searchParams.get('id')),
    [query.data, searchParams.get('id')],
  );

  const onSubmitProgram = async (
    parameters: { data: ParcoursProgramStepProps['value']; type: 'create' } | { data: Parcours; type: 'update' },
    options: { active: boolean },
  ) => {
    let valueParcours: Parcours;
    if (parameters.type === 'create') {
      const { data } = await parcoursCreateMutation.mutateAsync({ name: parameters.data.name });
      if (data == null) return toast.present({ message: 'Une erreur est survenue', severity: 'error' });
      valueParcours = data;
    } else valueParcours = parameters.data;

    await onUpdatedParcours({ ...valueParcours, active: options.active }, { rules, action, when });
  };

  const onUpdatedParcours = async (
    parcours: Parcours,
    data: {
      rules: ParcoursRulesStepProps['value'];
      action: Parcours['script']['action'];
      when: Parcours['script']['when'];
    },
  ) => {
    try {
      await parcoursUpdateMutation.mutateAsync({
        id: parcours.id,
        name: program.name,
        legal: program.legal ?? parcours.legal,
        active: parcours.active,
        script: {
          ...parcours.script,
          rule: {
            ...parcours.script.rule,
            where: data.rules,
          },
          action: data.action ?? parcours.script.action,
          when: data.when ?? parcours.script.when,
        },
      });
      return true;
    } catch {
      return false;
    }
  };

  const handleNext = async () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const onSubmit = async (active: boolean) => {
    const isNewParcours = selectedParcours == null;
    setSubmitting(true);
    try {
      if (isNewParcours) {
        await onSubmitProgram({ type: 'create', data: program }, { active });
        toast.present({ message: 'Le parcours est créé et activé', severity: 'success' });
      } else {
        await onSubmitProgram({ type: 'update', data: selectedParcours }, { active });
        toast.present({ message: 'Le parcours a été modifié', severity: 'success' });
      }
      goToIndex();
    } finally {
      setSubmitting(false);
    }
    // Pas besoin de reset car on le fait dans onAction
    // reset();
  };

  const onAction = () => {
    reset();
    if (pageView === 'list') {
      navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PARCOURS_CREATE);
    } else {
      goToIndex();
    }
    setActiveStep(0);
  };

  const handleItemAction: ParcoursListProps['onItemAction'] = (action, parcours) => {
    switch (action) {
      case 'edit': {
        if (parcours.owner === ParcoursOwner.Admin) {
          toast.present({ message: "Vous n'avez pas les droits pour modifier ce parcours", severity: 'error' });
          return navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PARCOURS_DETAIL + '?id=' + parcours.id, { replace: true });
        }

        navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PARCOURS_EDIT + '?id=' + parcours.id);
        setSelectedParcours(parcours);
        setProgram(parcours);
        setRules(parcours.script.rule.where);
        setWhen(parcours.script.when);

        if (Object.keys(parcours.script.action ?? {}).includes('email')) setAction(parcours.script.action);
        else setAction(defaultParcoursScriptAction);

        setActiveStep(0);
        break;
      }
      case 'detail': {
        navigate(PRO_URL.PREFIXE_PRO + PRO_URL.PARCOURS_DETAIL + '?id=' + parcours.id);
        break;
      }
      case 'delete': {
        deleteConfirmController.control.setOpen(true);
        break;
      }
      // No default
    }
  };

  const deleteConfirmController = useMUIDialogController();

  const title = useMemo(() => {
    if (pageView === 'list') return 'Parcours';
    if (pageView === 'detail') return 'Détails du parcours';
    return selectedParcours ? `Modifier le parcours` : 'Créer un parcours';
  }, [pageView, selectedParcours]);

  useEffect(() => {
    if (parcoursCurrent && pageView === 'edit') handleItemAction('edit', parcoursCurrent);
    else reset();
    setActiveStep(0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [parcoursCurrent, pageView]);

  return (
    <PermissionBox scope="management" action="access">
      <RHPage
        title={title}
        variant={pageView === 'list' ? 'list' : 'form'}
        actions={
          <IfPermission scope="management" action="edit">
            {pageView === 'list' ? (
              <ActionButton variant={'contained'} color="primary" actionName={'add'} onClick={onAction} />
            ) : pageView === 'edit' ? (
              <ActionButton variant={'text'} actionName={'cancel'} onClick={onAction} />
            ) : (
              /* detail */ <ActionButton variant={'text'} actionName={'close'} onClick={onAction} />
            )}
          </IfPermission>
        }
        topElement={
          pageView === 'edit' ? (
            <Box pl={2.5} pr={2.5}>
              <RHStepper activeStep={activeStep} steps={steps} />
            </Box>
          ) : undefined
        }
      >
        {pageView === 'list' ? (
          <ParcoursList items={query.data} onItemAction={handleItemAction} />
        ) : pageView === 'detail' ? (
          <>
            <ParcoursDetail parcours={parcoursCurrent} />
            <Divider sx={{ marginTop: 2.5, marginBottom: 1.5 }} />
            <Stack direction="row" spacing={2}>
              <Box flex={1} />
              <ConfirmDialog
                title={'Suppression de parcours'}
                {...deleteConfirmController.getRootProps()}
                content={`Vous êtes sur le point de supprimer un parcours. Si vous confirmez la suppression, les mails associés à ce parcours ne seront plus adressés.`}
                onConfirm={
                  parcoursCurrent
                    ? async (event) => {
                        await parcoursDelete.mutateAsync(parcoursCurrent.id);
                        goToIndex();
                        toast.present({ message: `Le parcours est supprimé`, severity: 'success' });
                      }
                    : undefined
                }
              />

              <Grid container ml="0 !important">
                <Grid item xs>
                  <ActionButton variant={'text'} actionName={'close'} onClick={onAction} />
                </Grid>
                {parcoursCurrent && parcoursCurrent.owner != ParcoursOwner.Admin && (
                  <Grid item xs textAlign="right">
                    <ActionButton actionName="delete" onClick={() => handleItemAction('delete', parcoursCurrent)} />
                    <ActionButton
                      actionName="edit"
                      onClick={() => handleItemAction('edit', parcoursCurrent)}
                      sx={{ ml: 1.5 }}
                    />
                  </Grid>
                )}
              </Grid>
            </Stack>
          </>
        ) : (
          <RHStepperContent
            steps={steps}
            activeStep={activeStep}
            onBack={handleBack}
            onNext={handleNext}
            submitProps={{
              label: 'Valider et Activer',
              onSubmit: () => onSubmit(true),
            }}
            alternativeSubmitProps={{
              variant: 'outlined',
              actionName: 'ok',
              startIcon: <File />,
              label: 'Garder en brouillon',
              onSubmit: () => onSubmit(false),
            }}
            disabled={!isValidStep || submitting}
          />
        )}
      </RHPage>
    </PermissionBox>
  );
};
