import { Editor, Range, Transforms } from 'slate';
import { isBlockActive } from './isBlockActive';
import { unwrapBlock } from './unwrapBlock';
import { LinkElement } from '../type';

export const wrapLink = (editor: Editor, url: string) => {
  if (isBlockActive(editor, 'link', 'type')) {
    unwrapBlock(editor, 'link');
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    // Select current word
    Transforms.move(editor, {
      unit: 'word',
      reverse: true,
      edge: 'anchor',
    });
    Transforms.move(editor, {
      unit: 'word',
      edge: 'focus',
    });
  }
  Transforms.wrapNodes(editor, link, { split: true });
  Transforms.collapse(editor, { edge: 'end' });
};
