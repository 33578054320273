import * as React from 'react';
import classNames from 'clsx';
import { styled, Typography, type TypographyProps } from '@mui/material';

// Name and options
const componentName = 'Code';

// Component properties
export interface CodeProps extends TypographyProps {}

/**
 * Display inline code
 *
 * @param props
 */
export function Code(props: CodeProps) {
  const { className, ...otherProps } = props;
  return (
    <Root component="code" variant="code" className={classNames(componentName, className)} {...otherProps}>
      {props.children}
    </Root>
  );
}

const Root = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.colorScheme.primaryContainer,
  color: theme.colorScheme.onSurface, // this should be onPrimaryContainer ?
  padding: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.25),
  paddingTop: theme.spacing(0.25),
  borderRadius: theme.spacing(0.25),
  fontWeight: 700,
  fontSize: 'inherit',
}));
