import * as React from 'react';
import classNames from 'clsx';
import { Parcours, ParcoursOwner } from '@/types';
import { Tooltip, Avatar, type AvatarProps, Box, Typography, useTheme } from '@mui/material';
import { ParcoursAvatarIcon } from './ParcoursAvatarIcon';

function useParcoursIconColor(parcours: Pick<Parcours, 'legal' | 'owner'>) {
  const theme = useTheme();

  if (parcours.owner === ParcoursOwner.Admin)
    return {
      backgroundColor: theme.palette.aquaSerenity[200],
      color: theme.palette.royalLavender[700],
      borderWidth: 0,
    };

  if (parcours.legal)
    return {
      backgroundColor: theme.palette.goldenHarvest[300],
      color: theme.palette.goldenHarvest[900],
      borderWidth: 0,
    };

  return {
    backgroundColor: theme.palette.sageMeadow[200],
    color: theme.palette.sageMeadow[800],
    borderWidth: 0,
  };
}

// Name and options
const componentName = 'ParcoursAvatar';

// Component properties
export interface ParcoursAvatarProps extends Omit<AvatarProps, 'component'> {
  parcours: Pick<Parcours, 'legal' | 'active' | 'owner'>;
  component?: React.ElementType;
}

export function ParcoursAvatar(props: ParcoursAvatarProps) {
  const { component: Root = Avatar, className, parcours, ...otherProps } = props;

  let parcoursType = parcours.legal ? 'Parcours légal' : 'Parcours de management';
  if (parcours.owner === ParcoursOwner.Admin) parcoursType = 'Parcours Kerij';

  const parcoursStatus = parcours.active ? 'Actif' : 'Inactif';

  const sx = useParcoursIconColor(parcours);
  return (
    <Tooltip
      title={
        <Box>
          <Typography fontSize={12} fontWeight={600}>
            {parcoursType}
          </Typography>
          <Typography fontSize={12}>{parcoursStatus}</Typography>
        </Box>
      }
    >
      <Root className={classNames(componentName, className)} {...otherProps} sx={{ ...sx, ...otherProps.sx }}>
        <ParcoursAvatarIcon parcours={parcours} />
      </Root>
    </Tooltip>
  );
}
