import { RHNotification } from '../../../../../../types/rh.interface';
import React, { useState } from 'react';
import { DeleteAbsenceModal } from '../ActionCardModal/DeleteAbsenceModal';
import { ContentStructure } from './common';
import { deleteAbsence, exportAbsence } from '../../../../../../services/rh/absences/service';
import { Button } from '@/components/Button';
import { Box, Typography } from '@mui/material';
import { useToast } from '@/components/Toast';
import { ErrorRounded } from '@mui/icons-material';
import { IntegrationFailedModal } from '../ActionCardModal/IntegrationFailedModal';

export interface IntegrationFailedActionsProps {
  rhNotification: RHNotification;
  onActionDone: () => void;
}

export const IntegrationFailedContent = ({ rhNotification }: { rhNotification: RHNotification }) => {
  const integrationLog = rhNotification.absence?.absence_logs?.find(
    (log) => log.triggered_by === 'adp' && log.active && log.level === 'error',
  );
  const description = (
    <>
      <Box display="flex">
        <ErrorRounded color="error" />
        <Typography fontWeight={600} fontSize={14} lineHeight={'1.5rem'} ml={1}>
          {'Erreur d’intégration dans le logiciel des temps :'}
        </Typography>
      </Box>
      <Typography fontSize={14} lineHeight={'1.5rem'} mt={0.5}>
        « {integrationLog?.description ?? "La cause n'est pas disponible dans le rapport"} »
      </Typography>
    </>
  );

  return <ContentStructure description={description} />;
};

export const IntegrationFailedActions = ({ rhNotification, onActionDone }: IntegrationFailedActionsProps) => {
  const toast = useToast();
  const [deleteAbsenceModalVisible, setDeleteAbsenceModalVisible] = useState(false);
  const [exportAbsenceModalVisible, setExportAbsenceModalVisible] = useState(false);

  return (
    <div>
      <Button onClick={() => setExportAbsenceModalVisible(true)} style={{ marginRight: 8 }}>
        {"Relancer l'export"}
      </Button>
      <Button onClick={() => setDeleteAbsenceModalVisible(true)} style={{ marginRight: 8 }}>
        {"Supprimer l'absence"}
      </Button>

      <DeleteAbsenceModal
        visible={deleteAbsenceModalVisible}
        setVisible={setDeleteAbsenceModalVisible}
        onDeleteAbsence={async (description) => {
          if (rhNotification.absence)
            await deleteAbsence({
              params: {
                idAbsence: rhNotification.absence.id,
              },
              body: {
                action: 'delete',
                description: description,
              },
            }).then(() => {
              toast.present({ message: `Absence supprimée`, severity: 'success' });
              onActionDone();
            });
          setDeleteAbsenceModalVisible(false);
        }}
      />

      <IntegrationFailedModal
        visible={exportAbsenceModalVisible}
        setVisible={setExportAbsenceModalVisible}
        onReExport={async () => {
          try {
            if (rhNotification.absence?.id == null) throw 'Absence introuvable';
            await exportAbsence(rhNotification.absence.id);
            toast.present({ message: 'Export relancé', severity: 'success' });
            setExportAbsenceModalVisible(false);
            onActionDone();
          } catch {
            toast.present({ message: "Erreur de programmation de l'export", severity: 'error' });
          }
        }}
      />
    </div>
  );
};
