import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { RdvUI } from './RdvUI';

export const RdvPage: React.FC = () => {
  return (
    <DefaultLayout>
      <RdvUI />
    </DefaultLayout>
  );
};
