import { useLocation, useNavigate, Location } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { getParamsBusiness } from '../../services/get_params_business.service';
import { getBusinessAbsenceOption } from '../../services/get_business_absence_option.service';
import Modal, { Styles } from 'react-modal';
import { getNoteAbsenceParams } from '../../services/get_note_absence_params.service';
import { useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';

const getDateDebutInitValue = (document: any) => {
  if (document.dateDebut) {
    return document.dateDebut;
  } else if (['1', '2', '3'].includes(document.absenceType.value) && document.period && document.period.length) {
    return document.period.at(0).dateDebut;
  } else {
    return '';
  }
};

const getDateFinInitValue = (document: any) => {
  if (document.dateFin) {
    return document.dateFin;
  } else if (['1', '2', '3'].includes(document.absenceType.value) && document.period && document.period.length) {
    return document.period.at(0).dateFin;
  } else {
    return '';
  }
};

const getAbsenceWeek = (condition: string): { pre: number; post: number } => {
  switch (condition) {
    case '311':
      return { pre: 6, post: 10 };
    case '312':
      return { pre: 8, post: 18 };
    case '32':
      return { pre: 12, post: 22 };
    case '33':
      return { pre: 24, post: 22 };
    default:
      return { pre: 0, post: 0 };
  }
};

const customStyles = {
  content: {
    border: '0',
    borderRadius: '4px',
    bottom: 'auto',
    minHeight: '10rem',
    left: '50%',
    padding: '2rem',
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    minWidth: '20rem',
    width: '80%',
    maxWidth: '60rem',
  },
};

export const MotherDate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const document = location.state.document || {};
  const condition =
    document?.absenceType?.value ?? '' + document?.absenceType1?.value ?? '' + document?.absenceType2?.value ?? '';

  const user = useAppSelector((state) => state.accountContext.user);

  const [dateNaissance, setDateNaissance] = useState(document.dateNaissance ?? '');

  const handleClick = async () => {
    const documentVal = {
      ...document,
      period: [{ dateDebut: dateDebut, dateFin: dateFin }],
      dateNaissance: dateNaissance,
      isLastDayWork: null,
      isLinkHospi: null,
      isWorkDay: null,
      idAbsenceHospi: null,
      dateDebut: dateDebut,
      dateFin,
      nbJoursHospi: null,
      isShownDureeHospi: null,
      optionnal:
        isClickedOption1 || isClickedOption2
          ? {
              isClickedOption1: isClickedOption1,
              isClickedOption2: isClickedOption2,
            }
          : null,
      dateEndReduction:
        preReductionWeek && preReductionHours
          ? isClickedOption2
            ? moment(dateFin).add(preReductionWeek, 'weeks').subtract(prePromoWeek, 'weeks').format('YYYY-MM-DD')
            : moment(dateFin).add(preReductionWeek, 'weeks').format('YYYY-MM-DD')
          : null,
      hoursReduction: preReductionWeek && preReductionHours ? preReductionHours : null,
      dateEndPromo: prePromoWeek
        ? isClickedOption2
          ? moment(dateFin).format('YYYY-MM-DD')
          : moment(dateFin).add(prePromoWeek, 'weeks').format('YYYY-MM-DD')
        : null,
    };
    navigate('/declaration-document', {
      state: {
        document: {
          ...documentVal,
        },
      },
    });
    return;
  };

  const [dateDebut, setDateDebut] = useState<string>(getDateDebutInitValue(document));
  const [dateFin, setDateFin] = useState<string>(getDateFinInitValue(document));
  const [error, setError] = useState<string | number>('');

  const [preAbsence, setPreAbsence] = useState<number>(getAbsenceWeek(condition).pre);
  const [postAbsence, setPostAbsence] = useState<number>(getAbsenceWeek(condition).post);

  const [isClickedOption1, setIsClickedOption1] = useState(
    document.optionnal ? document.optionnal.isClickedOption1 : false,
  );
  const [isClickedOption2, setIsClickedOption2] = useState(
    document.optionnal ? document.optionnal.isClickedOption2 : false,
  );

  const [prePromoWeek, setPrePromoWeek] = useState<number>(document.prePromoWeek ? document.prePromoWeek : 0);
  const [preReductionWeek, setPreReductionWeek] = useState<number>(0);
  const [preReductionHours, setPreReductionHours] = useState<number>(0);
  const [postPromoWeek, setPostPromoWeek] = useState<number>(document.postPromoWeek ? document.postPromoWeek : 0);

  const [modalIsOpenOption1, setModalIsOpenOption1] = useState(false);
  const [modalIsOpenOption2, setModalIsOpenOption2] = useState(false);

  const [noteAbsenceOptions, setNoteAbsenceOptions] = useState<{
    notePromo: string;
    noteReduction: string;
  }>({
    notePromo: '',
    noteReduction: '',
  });

  const label = document?.absenceType?.value;
  const label1 = document?.absenceType1?.value;
  const label2 = document?.absenceType2?.value;

  const handleOnChangeChildBirthDate = (
    event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
  ) => {
    if (event.type === 'change') {
      setDateNaissance(event.target.value);
      if (!event.target.value) {
        setDateDebut('');
        setDateFin('');
        setIsClickedOption1(false);
        setIsClickedOption2(false);
      } else {
        if (moment(event.target.value).isAfter(moment()) || moment().format('YYYY-MM-DD') === event.target.value) {
          setError('');
        }
        setDateNaissance(event.target.value);
        setDateDebut(moment(event.target.value).subtract(preAbsence, 'weeks').format('YYYY-MM-DD'));
        setDateFin(moment(event.target.value).add(postAbsence, 'weeks').format('YYYY-MM-DD'));
      }
    } else {
      if (moment(dateNaissance).isBefore(moment()) && moment().format('YYYY-MM-DD') !== dateNaissance) {
        setError("La date de naissance doit être supérieur ou égale à aujourd'hui");
      }
      if (!event.target.value) {
        setIsClickedOption1(false);
        setIsClickedOption2(false);
      }
    }
  };

  useEffect(() => {
    getParamsBusiness(label, label1, label2, postAbsence, 0, preAbsence).then((value) => {
      setPostAbsence(value.postAbsence);
      setPreAbsence(value.preAbsence);
    });

    getNoteAbsenceParams().then((value) => {
      setNoteAbsenceOptions(value);
    });

    getBusinessAbsenceOption(label, label1, label2, user?.employee_information.id_business).then((value) => {
      setPrePromoWeek(value.prePromoWeek);
      setPreReductionWeek(value.preReductionWeek);
      setPreReductionHours(value.preReductionHours);
      setPostPromoWeek(value.postPromoWeek);
    });
  }, []);

  useEffect(() => {
    if (isClickedOption2) {
      setDateFin((value) => moment(value).add(prePromoWeek, 'weeks').format('YYYY-MM-DD'));
    } else {
      setDateFin((value) => moment(value).subtract(prePromoWeek, 'weeks').format('YYYY-MM-DD'));
    }
  }, [isClickedOption2]);

  return (
    <div className="container-fluid">
      <h3 className="question-form mt-5 mb-5 text-center">Comment s'organise votre congé maternité ?</h3>
      <div className="mb-3 col-lg-4">
        <label className="col-12">
          Date de naissance prévue
          <input
            className="form-control form-date"
            name="dateNaissance"
            type="date"
            placeholder="Date de naissance"
            value={dateNaissance}
            onChange={handleOnChangeChildBirthDate}
            onBlur={handleOnChangeChildBirthDate}
            min={moment().format('YYYY-MM-DD')}
            required
          />
        </label>
      </div>

      <h4 className="question-form mb-3 text-center">Congé légal de maternité : {preAbsence + postAbsence} Semaines</h4>

      <div className="row col-lg-6 planning-maternite text-center mb-5">
        <div className="col-12 col-lg-4 pre-nat container-date">
          <div className="title-div-nat">Congé pré-natal</div>
          <div className="week-div-nat">
            {preAbsence} semaines {isClickedOption2 ? <span>(+ {prePromoWeek} semaines)</span> : ''}
          </div>
          <div className="date-div-nat">
            {dateDebut && dateNaissance && `Du ${moment(dateDebut).format('DD/MM/YYYY')}`}
          </div>
        </div>
        <div className="col-12 col-lg-4 date-nat container-date">
          <div className="title-div-nat">NAISSANCE THEORIQUE</div>
          <div className="date-div-nat">{dateNaissance && moment(dateNaissance).format('DD/MM/YYYY')}</div>
        </div>
        <div className="col-12 col-lg-4 post-nat container-date">
          <div className="title-div-nat">Congé post-natal</div>
          <div className="week-div-nat">
            {postAbsence} semaines {isClickedOption2 ? <span>(+ {postPromoWeek} semaines)</span> : ''}
          </div>
          <div className="date-div-nat">
            {dateFin && dateNaissance && `Jusqu'au ${moment(dateFin).format('DD/MM/YYYY')}`}
          </div>
        </div>
      </div>
      {dateNaissance && prePromoWeek ? (
        <div>
          <h4
            className="question-form mb-3 text-center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: 0,
              alignItems: 'flex-start',
            }}
          >
            Congé supra légal offert par l'entreprise : {prePromoWeek} semaines
          </h4>
          <h6
            className="question-form mb-3 mt-2 text-center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: 0,
              alignItems: 'flex-start',
            }}
          >
            Choisissez une option
          </h6>
        </div>
      ) : (
        <></>
      )}
      {dateNaissance && (
        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 0,
            alignItems: 'flex-start',
          }}
        >
          {preReductionWeek && preReductionHours ? (
            <li
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 20,
                padding: 0,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 20,
                }}
              >
                <FontAwesomeIcon
                  icon={isClickedOption1 ? faCircleCheck : faCircle}
                  style={{
                    color: '#40C9C9',
                    marginRight: 5,
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!isClickedOption1) {
                      setIsClickedOption2(false);
                    }
                    setIsClickedOption1(!isClickedOption1);
                  }}
                />{' '}
                Aménagement des horaires &nbsp;
                <FontAwesomeIcon
                  className="fa-1x modal-info"
                  icon={faCircleInfo}
                  onClick={() => {
                    setModalIsOpenOption1(!modalIsOpenOption1);
                  }}
                />
              </div>
            </li>
          ) : (
            <></>
          )}
          {prePromoWeek ? (
            <li
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 20,
                }}
              >
                <FontAwesomeIcon
                  icon={isClickedOption2 ? faCircleCheck : faCircle}
                  style={{
                    color: '#40C9C9',
                    marginRight: 5,
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!isClickedOption2) {
                      setIsClickedOption1(false);
                    }
                    setIsClickedOption2(!isClickedOption2);
                  }}
                />{' '}
                Congé supplémentaire (Jusqu'au{' '}
                <span style={{ fontWeight: '700', marginLeft: 5 }}>
                  {isClickedOption2
                    ? moment(dateFin).format('DD/MM/YYYY')
                    : moment(dateFin).add(prePromoWeek, 'weeks').format('DD/MM/YYYY')}
                </span>
                )&nbsp;
                <FontAwesomeIcon
                  className="fa-1x modal-info"
                  icon={faCircleInfo}
                  onClick={() => {
                    setModalIsOpenOption2(!modalIsOpenOption2);
                  }}
                />
              </div>
            </li>
          ) : (
            <></>
          )}
        </ul>
      )}

      <form className="form-legacy" onSubmit={handleClick} style={{ padding: 0 }}>
        <Spacer size="medium" />
        <Button color="primary" type="submit" disabled={!(!error && dateDebut && dateFin)}>
          Suivant
        </Button>
        <Spacer />
        <Button
          color="secondary"
          onClick={() => {
            if (
              document.absenceType.value + document.absenceType1.value === '32' ||
              document.absenceType.value + document.absenceType1.value === '33' ||
              document.absenceType.value + document.absenceType1.value === '41' ||
              document.absenceType.value + document.absenceType1.value === '42'
            ) {
              navigate('/declaration-type-1', {
                state: location.state,
              });
            } else if (document.absenceType.value === '2') {
              navigate('/declaration', {
                state: location.state,
              });
            } else {
              navigate('/declaration-type-2', {
                state: location.state,
              });
            }
          }}
        >
          Précédent
        </Button>
      </form>

      <Modal
        style={customStyles as Styles}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={200}
        isOpen={modalIsOpenOption1}
        onRequestClose={() => {
          setModalIsOpenOption1(!modalIsOpenOption1);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModalIsOpenOption1(!modalIsOpenOption1);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">
          Que comprend l'aménagement des horaires du congé maternité ?
        </h3>
        <div className="text-center text-modal mt-3">
          {noteAbsenceOptions.notePromo ? (
            <p>{noteAbsenceOptions.notePromo}</p>
          ) : (
            <ul className="text-center align-center">
              <li className="mb-2">60 mn de travail en moins par jour de travail avant le congé maternité</li>
              <li className="mb-2">
                60 mn de moins par jour après le congé maternité pendant 18 mois (Jusqu’au{' '}
                <span style={{ fontWeight: '700', marginLeft: 5 }}>
                  {isClickedOption2
                    ? moment(dateFin)
                        .add(preReductionWeek, 'weeks')
                        .subtract(prePromoWeek, 'weeks')
                        .format('DD/MM/YYYY')
                    : moment(dateFin).add(preReductionWeek, 'weeks').format('DD/MM/YYYY')}
                </span>
                )
              </li>
              <li className="mb-2">
                Cette heure quotidienne peut être fragmentée en concertation avec le manager dans la limite de 30mn le
                matin et le soir ou cumulée sans pouvoir dépasser ½ journée par semaine.
              </li>
            </ul>
          )}
        </div>
      </Modal>
      <Modal
        style={customStyles as Styles}
        className="Modal"
        overlayClassName="Overlay"
        // className="modal-document"
        closeTimeoutMS={200}
        isOpen={modalIsOpenOption2}
        onRequestClose={() => {
          setModalIsOpenOption2(!modalIsOpenOption2);
        }}
        ariaHideApp={false}
      >
        <div className="float-left">
          <button
            onClick={() => {
              setModalIsOpenOption2(!modalIsOpenOption2);
            }}
            className="btn-close exit-modal"
          ></button>
        </div>
        <h3 className="question-form text-center titre-modal">
          Que comprend le congé supplémentaire du congé maternité ?
        </h3>
        <div className="text-center text-modal mt-3">
          {noteAbsenceOptions.noteReduction ? (
            <p>{noteAbsenceOptions.noteReduction}</p>
          ) : (
            <ul className="text-center align-center">
              <li className="mb-2">30 mn de travail en moins par jour avant le congé maternité</li>
              <li className="mb-2">{prePromoWeek} semaines de congé supplémentaire après l’accouchement</li>
            </ul>
          )}
        </div>

        <div className="text-center"></div>
      </Modal>
    </div>
  );
};
