import { ThemeOptions } from '@mui/material';
import { elevations } from './elevations';
import { colorScheme } from './colorScheme';
import { themePaletteLight } from './themePalette';
import { typeface } from './typeface';

export enum ThemesModeEnum {
  Light = 'light',
  Dark = 'dark',
}

export interface IThemesConfig {
  [ThemesModeEnum.Dark]?: ThemeOptions;
  [ThemesModeEnum.Light]?: ThemeOptions;
}

const spacing = (amount: number) => {
  return amount * 8;
};
const typographyTitle = {
  fontWeight: 'bolder',
};
const themePalette = themePaletteLight;

const themeShape = {
  borderRadius: 10,
  borderColor: colorScheme.outline,
  borderColorVariant: colorScheme.outlineVariant,
} satisfies ThemeOptions['shape'];

const themeValue: ThemeOptions = {
  typography: {
    ...typeface.brand,
    button: {
      textTransform: 'none',
    },
    h1: typographyTitle,
    h2: typographyTitle,
    h3: typographyTitle,
    h4: typographyTitle,
    h5: typographyTitle,
    h6: typographyTitle,
    // Added by Stéphane in the theme, it would be better to merge with an existing variant
    headline: {
      ...typographyTitle,
      fontSize: '1rem',
    },
    // Also custom typography used for inline code
    code: {
      ...typeface.monospace,
    },
  },
  components: {
    MuiChip: {
      defaultProps: {
        sx: {
          backgroundColor: 'rgba(15, 7, 91, 0.5)',
          color: 'white',
          fontWeight: 'bolder',
        },
      },
    },
    MuiDivider: {
      defaultProps: {
        sx: {
          backgroundColor: colorScheme.outline,
        },
      },
      styleOverrides: {
        root: {
          opacity: 1, // Fix global style on _reboot.scss hr { opacity: 0.25 }
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          padding: '10px 16px',
        },
      },
      // variants: [
      //   {
      //     props: { variant: 'outlined' },
      //     style: {
      //       backgroundColor: 'white',
      //       boxShadow: elevations[0].value,
      //       borderColor: royalLavender[200],
      //     },
      //   },
      // ]
    },
    // FIXME: Trop d'impact sur les TextField
    // MuiInputLabel: {
    //   defaultProps: {
    //     sx: {
    //       fontSize: 20,
    //       fontWeight: 'bolder',
    //     }
    //   }
    // },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          // Styles spécifiques pour l'état "default"
          backgroundColor: themePalette.grey?.['100'],
          color: (themePalette.primary as any).dark,
          // Attention : on rajoute un border par rapport au style de base
          // Si cela crée des conflits sur les styles de base alors il faut créer une autre variant "outline" à la place
          borderColor: themeShape.borderColorVariant,
          borderWidth: '1px',
          borderStyle: 'solid',
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          color: 'primary',
          fontWeight: 'h6.fontWeight',
        },
        secondaryTypographyProps: {
          variant: 'caption' as const,
          color: (theme: any) => theme.palette.primaryBackground.contrastText,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        dense: (() => {
          const avatarMinWidth = spacing(4 + 1.5);
          return {
            ['& .MuiListItemAvatar-root']: {
              width: spacing(4),
              height: spacing(4),
              minWidth: avatarMinWidth,
            },
            ['& .MuiListItemAvatar-root .MuiAvatar-root']: {
              width: spacing(4),
              height: spacing(4),
              fontSize: spacing(2),
            },
            ['& .MuiDivider-inset']: {
              marginLeft: avatarMinWidth + spacing(/* card marginLeft */ 2),
            },
            ['& .MuiListItemText-inset']: {
              paddingLeft: avatarMinWidth,
            },
          };
        })(),
      },
    },
    MuiFormLabel: {
      defaultProps: {
        sx: {
          fontSize: 15,
          fontWeight: 'bolder',
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'inherit', // Bug de mui ? (par défaut met à md => c'est un problème dans beaucoup de composants)
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          headline: 'p',
        },
      },
    },
  },
  palette: themePalette,
  shape: themeShape,
  shadows: ['none', ...defineValues('0px 4px 8px 0px rgba(0, 0, 0, 0.12)', elevations, 24)],
  colorScheme,
};
const theme: IThemesConfig = {
  [ThemesModeEnum.Dark]: themeValue,
  [ThemesModeEnum.Light]: themeValue,
};

export default theme;

// Helpers : aide en attendant de finir le style
function defineValues<Length extends number>(
  defaultValue: string,
  values: Array<{ index: number; value: string }>,
  length: Length,
): ThemeValue<Length> {
  const result = Array.from({ length }, () => defaultValue);

  // Remplace les valeurs à des index spécifiques avec les valeurs fournies
  for (const { index, value } of values) {
    if (index >= 0 && index < length) {
      result[index] = value;
    }
  }

  return result as ThemeValue<Length>;
}

type ThemeValue<Length extends number, R extends string[] = []> = R['length'] extends Length
  ? R
  : ThemeValue<Length, [...R, string]>;
