import * as React from 'react';
import classNames from 'clsx';
import { Typography, type TypographyProps } from '@mui/material';

// Name and options
const componentName = 'PageFormHelperText';

// Component properties
export interface PageFormHelperTextProps extends TypographyProps {}

/**
 * Form helper text. Often at the start of a form body.
 *
 * @example
 * <PageFormBody
 *   before={<PageFormHelperText>'This is a helper text'</PageFormHelperText>}
 * >
 *   ...
 * </PageFormBody>
 * @param props
 * @returns
 */
export function PageFormHelperText(props: PageFormHelperTextProps) {
  const { className, children, ...otherProps } = props;
  return (
    <Typography component="p" variant="body2" className={classNames(componentName, className)} {...otherProps}>
      {children}
    </Typography>
  );
}
