/* eslint-disable security/detect-object-injection */
/**
 * Pretty print variables
 *
 * @param expr
 */
export function printVariables(expr: string) {
  ///return expr.replaceAll('{{ parcours.name }}', '{{ Nom parcours }}');

  const regex = /{{\s*(\w+(\.\w+)*)\s*}}/g;
  const mapping: Record<string, string> = {
    'parcours.name': 'Nom parcours',
  };
  return expr.replaceAll(regex, (match, key) => {
    // Si la clé existe dans le mapping, on remplace par la valeur, sinon on laisse le match inchangé
    return mapping[key] == null ? match : `{{ ${mapping[key]} }}`;
  });
}
