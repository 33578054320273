import * as React from 'react';
import { FormatDate } from '@/constants/date.constants';
import {
  isParcoursRuleKey,
  isParcoursRuleOperator,
  ParcoursRule,
  ParcoursRuleKeyLabel,
  ParcoursRuleOperatorLabel,
} from '@/types';
import {
  isParcoursRuleArray,
  isParcoursRuleDuration,
  isParcoursRuleObject,
  isParcoursRuleValue,
  isParcoursRuleValueDate,
  ParcoursRuleValue,
} from '@/types/ParcoursRuleValue';
import moment from 'moment';
import { Code } from '@/components/Code';

export const extractRule = (
  rule: ParcoursRule,
):
  | {
      success: true;
      rule: { key: React.ReactNode; operator: React.ReactNode; value: React.ReactNode; options?: object };
    }
  | { success: false; error: React.ReactNode } => {
  const parcoursKey = Object.keys(rule)[0];

  if (!isParcoursRuleKey(parcoursKey)) {
    return { success: false, error: `La règle est invalide` };
  }

  const parcoursOperator = Object.keys(rule[parcoursKey] ?? {})[0];
  if (!isParcoursRuleOperator(parcoursOperator)) {
    return { success: false, error: `L'opérateur ${JSON.stringify(parcoursOperator)} est incorrect` };
  }

  const extractedValue = extractRuleValue(Object.values(rule[parcoursKey] ?? {})[0]);
  if (extractedValue == null) {
    return { success: false, error: `Le paramétrage de la règle est incorrect est incorrect` };
  }

  return {
    success: true,
    rule: {
      key: ParcoursRuleKeyLabel[parcoursKey],
      operator: ParcoursRuleOperatorLabel[parcoursOperator],
      ...extractedValue,
    },
  };
};

const renderValue = (value: React.ReactNode) => <Code>{value}</Code>;

export const extractRuleValue = (
  value: ParcoursRuleValue,
): { value: React.ReactNode; options?: object } | undefined => {
  if (isParcoursRuleArray(value)) {
    const values = value.map((value_) => renderValue(`${value_}`));
    return {
      value: (
        <React.Fragment>
          {values.map((element, index, arr) => (
            <>
              {renderValue(element)}
              {index < arr.length - 1 && ' ou '}
            </>
          ))}
        </React.Fragment>
      ),
    };
  }
  if (isParcoursRuleDuration(value))
    return {
      value: (
        <>
          {renderValue(value.value)} depuis {renderValue(value.since)}{' '}
          {value.sinceUnit === 'days' ? 'jour(s)' : 'année(s)'}
        </>
      ),
    };
  if (isParcoursRuleObject(value)) {
    const { value: objectValue, ...options } = value;
    return { value: renderValue(`${objectValue}`), options: options };
  }
  if (isParcoursRuleValueDate(value)) return { value: renderValue(`${moment(value).format(FormatDate.FRENCH_DATE)}`) };
  if (isParcoursRuleValue(value)) return { value: renderValue(`${value}`) };
};
