/* eslint-disable xss/no-mixed-html */
import { DefaultLayout } from '@/components/layouts';
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepProgressBar } from '@/features/declaration/components/bars/StepProgressBar';
import { NavigationContext } from '@/components/Context/NavigationContext';
import { NavigationSteps } from '@/components/Context/NavigationProvider';
import { RouterConfig } from '@/config/RouterConfig';
import { AccountContext, useAppSelector } from '@/store';
import { EmployeeCivility } from '@/types';
import { Spacer } from '@/components/Spacer';
import { Button } from '@/components/Button';

const getOptions = (slug?: string, absenceType?: string) => {
  if (slug) {
    switch (slug) {
      case 'arret_de_travail': {
        return [
          {
            value: 1,
            label: 'Maladie',
          },
          {
            value: 2,
            label: 'Hospitalisation',
          },
          {
            value: 5,
            label: 'Accident/Maladie professionnelle',
          },
        ];
      }
      case 'conge_de_parentalite': {
        return [
          {
            value: 3,
            label: 'Congé maternité',
          },
          {
            value: 4,
            label: 'Congé de parentalité',
          },
        ];
      }
      default: {
        return [];
      }
    }
  } else if (absenceType) {
    if (['1', '2'].includes(absenceType)) {
      return [
        {
          value: 1,
          label: 'Maladie',
        },
        {
          value: 2,
          label: 'Hospitalisation',
        },
        {
          value: 5,
          label: 'Accident/Maladie professionnelle',
        },
      ];
    } else if (['3', '4'].includes(absenceType)) {
      return [
        {
          value: 3,
          label: 'Congé maternité',
        },
        {
          value: 4,
          label: 'Congé de parentalité',
        },
      ];
    } else {
      return [];
    }
  }
};

const getStateTransfer = (absenceType: string, absenceTypeLabel: string) => {
  switch (absenceType) {
    case '1': {
      return {
        document: {
          absenceType: { value: '1', label: 'Arrêt de travail' },
          absenceType1: { value: '1', label: 'Maladie' },
        },
      };
    }
    case '5': {
      return {
        document: {
          absenceType: { value: '1', label: 'Arrêt de travail' },
          absenceType1: {
            value: '2',
            label: 'Accident - Maladie professionnelle',
          },
        },
      };
    }
    default: {
      return {
        document: {
          absenceType: {
            value: absenceType,
            label: absenceTypeLabel,
          },
        },
      };
    }
  }
};

const getAbsenceTypeInitValue = (slug: string, document: any): string => {
  return slug ? (slug === 'arret_de_travail' ? '1' : '3') : document?.absenceType?.value;
};

const getAbsenceTypeInitLabel = (slug: string, document: any): string => {
  return slug ? (slug === 'arret_de_travail' ? 'Arrêt de travail' : 'Congé maternité') : document?.absenceType?.label;
};

export const DeclarationTypePage = () => {
  // declaration hooks

  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(AccountContext.getUser);
  const document = location.state.document || {};
  // declaration state

  const civility = user?.employee_information.civility;
  const slug = location.state.slug;
  const [absenceType, setAbsenceType] = useState<string | any>(getAbsenceTypeInitValue(slug, document));

  const [absenceTypeLabel, setAbsenceTypeLabel] = useState<string>(getAbsenceTypeInitLabel(slug, document));
  const { setCurrentStep } = useContext(NavigationContext);

  const returnPage = () => {
    navigate(RouterConfig.declarationPage().path);
  };

  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_PROLONGATION);
    if (location.state.slug && civility === EmployeeCivility.Male && location.state.slug !== 'arret_de_travail') {
      navigate(RouterConfig.declarationType1Page().path, {
        state: {
          document: {
            absenceType: {
              value: '4',
              label: 'Congé paternité',
            },
          },
        },
      });
    }
  }, []);

  const onSubmit = () => {
    navigate(RouterConfig.declarationType1Page().path, {
      state: getStateTransfer(absenceType, absenceTypeLabel),
    });
  };

  return (
    <DefaultLayout>
      <div className="container-fluid">
        <StepProgressBar type={absenceType} />
        <h3 className="question-form mt-5 mb-5 text-center">Pour quelle raison vous absentez-vous ?</h3>
        <form onSubmit={onSubmit} className="form-legacy form-declaration col-lg-4">
          <label>
            <select
              className="form-select"
              name="absence"
              value={absenceType ?? ''}
              onChange={(event) => {
                setAbsenceType(event.target.value);
                const index: number = (event.nativeEvent.target as HTMLSelectElement).selectedIndex;
                setAbsenceTypeLabel((event.nativeEvent.target as HTMLSelectElement)[index].innerHTML);
              }}
              required
            >
              {getOptions(slug, location.state?.document?.absenceType?.value)?.map((value) => (
                <option key={value.value} value={value.value}>
                  {value.label}
                </option>
              ))}
            </select>
          </label>

          <Spacer size="medium" />
          <Button color="primary" type="submit">
            Suivant
          </Button>
          <Spacer />
          <Button
            color="secondary"
            onClick={() => {
              returnPage();
            }}
          >
            Précédent
          </Button>
        </form>
      </div>
    </DefaultLayout>
  );
};
