import axios from '@/lib/axios';
import { ParcoursPublic } from '@/types';

export const parcoursPublicCreate = async (params: parcoursPublicCreate.Parameters) => {
  return await axios.post<parcoursPublicCreate.Return>(`/api/employee/parcours`, params);
};

export namespace parcoursPublicCreate {
  export type Parameters = { parcours_id: ParcoursPublic['parcours_id'] };
  export type Return = void;
}
