import { CustomModal, type CustomModalProps } from '../../base/CustomModal';
import { ContentGestionContact } from './ContentGestionContact.component';
import React, { useEffect, useState } from 'react';
import { retrieveEmployeeContacts } from '../../../services/rh/employees';
import { EmployeeContact } from '../../../types/user.interface';
import { ActionButton } from '@/components/ActionButton';

export interface EmployeeContactListModalProps extends CustomModalProps {
  idEmployeeInformation: string;
  idBusiness: string;
}

export const EmployeeContactListModal = ({
  open: open,
  onClose: onClose,
  idEmployeeInformation,
  idBusiness,
}: EmployeeContactListModalProps) => {
  const [listContact, setListContact] = useState<Array<EmployeeContact>>([]);

  const fetchContact = async ({ id }: { id: string }) => {
    if (id !== null) {
      retrieveEmployeeContacts({
        params: {
          idEmployeeInformation: id,
        },
      }).then((value) => {
        setListContact(value.data.contacts);
      });
    }
  };
  useEffect(() => {
    fetchContact({ id: idEmployeeInformation });
  }, [idEmployeeInformation]);
  const onListUpdated = () => {
    fetchContact({ id: idEmployeeInformation });
  };
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      renderHeader={() => <> Gestion des contacts</>}
      renderContent={() => (
        <ContentGestionContact
          listContact={listContact}
          idEmployeeInformation={idEmployeeInformation}
          idBusiness={idBusiness}
          refreshPage={onListUpdated}
        />
      )}
      renderFooter={() => (
        <ActionButton actionName="validate" label={'Quitter'} onClick={() => onClose?.({}, 'escapeKeyDown')} />
      )}
    />
  );
};
