import * as React from 'react';
import classNames from 'clsx';
import { Parcours } from '@/types/Parcours';
import { ListItem, ListItemAvatar, ListItemButton, type ListItemProps, ListItemText } from '@mui/material';
import moment from 'moment';
import { FormatDate } from '@/constants/date.constants';
import { ParcoursAvatar } from '@/components/ParcoursAvatar';
import { ParcoursStatusText } from '@/components/ParcoursDetail';

const componentName = 'ParcoursListItem';

export interface ParcoursListItemProps extends ListItemProps {
  parcours: Parcours;
  onAction?: (type: 'edit' | 'delete' | 'detail', parcours: Parcours) => void;
}

export function ParcoursListItem(props: ParcoursListItemProps) {
  const { parcours, onAction, ...otherProps } = props;
  const separator = ' • ';
  const updatedAt = `Modifié le ${moment(parcours.updated_at).format(FormatDate.FRENCH_DATE)}`;
  const status = <ParcoursStatusText parcours={parcours} />;

  return (
    <ListItem
      className={classNames(componentName, componentName)}
      disablePadding
      onClick={(event) => {
        onAction?.('detail', parcours);
      }}
      {...otherProps}
    >
      <ListItemButton>
        <ListItemAvatar>
          <ParcoursAvatar parcours={parcours} />
        </ListItemAvatar>
        <ListItemText primary={parcours.name} secondary={[updatedAt, separator, status]} />
      </ListItemButton>
    </ListItem>
  );
}
