import React from 'react';
import classNames from 'clsx';
import { Step as MUIStep, StepProps, styled } from '@mui/material';
import { RHStepperStepIcon } from './RHStepperStepIcon';
import { RHStepperStepLabel } from './RHStepperStepLabel';

const componentName = 'RHStepperStep';

export interface RHStepperStepProps extends StepProps {
  title?: string;
  icon: React.ReactNode;
}

export function RHStepperStep(props: RHStepperStepProps) {
  const { className, title, icon, ...stepProps } = props;

  return (
    <Step className={classNames(componentName, className)} {...stepProps}>
      <RHStepperStepLabel StepIconComponent={RHStepperStepIcon(icon)}>{title}</RHStepperStepLabel>
    </Step>
  );
}

const Step = styled(MUIStep)(({ theme }) => ({
  padding: 0,
  maxWidth: theme.spacing(13.75),
}));
