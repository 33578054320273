// ** React Imports
import React, { ReactNode } from 'react';

// ** MUI Imports
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import DefaultTheme, { ThemesModeEnum } from './config/DefaultTheme';

interface Props {
  children: ReactNode;
}

const ThemeComponent = (props: Props) => {
  const { children } = props;

  const themeMode = ThemesModeEnum.Light;
  const theme = createTheme(DefaultTheme?.[themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Button variant="contained">Contained</Button> */}
      {/* <GlobalStyles styles={() => GlobalStyling(theme) as any} /> */}
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
