import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './changePassword.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { employeeChangePassword, employeeVerifyPassword, fetchEmployeeProfile } from '../../../../../services/employee';
import { AccountContext, useAppDispatch } from '@/store';

function ChangePasswordUI() {
  const dispatch = useAppDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const state = useLocation();
  const [error, setError] = useState<string>('');

  const handleCheckPassword = async () => {
    if (oldPassword) {
      const res = await employeeVerifyPassword({
        password: oldPassword,
      });
      return !!res?.data?.isValid;
    } else {
      return false;
    }
  };

  const handleClick = async (event: any) => {
    event.preventDefault();
    const valid = await handleCheckPassword();
    try {
      if (valid) {
        if (newPassword !== confirmPassword) {
          setError('Attention, les mots de passe ne correspondent pas.');
          return;
        }
        employeeChangePassword({
          password: newPassword,
        }).then(async () => {
          await fetchEmployeeProfile().then((value) => {
            dispatch(AccountContext.actions.setUser(value.data));
            navigate('/profilvalidation', {
              state: {
                document: state.state ? state.state.document : null,
              },
            });
          });
        });
      } else {
        setMessage('Le mot de passe est erroné');
      }
    } catch {
      setError('Erreur');
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <form className="form-legacy mt-5 mb-5 col-lg-4" onSubmit={handleClick}>
          <h3 className="question-form mb-5">Changement du mot de passe</h3>

          <div className="input-group">
            <label>Mot de passe actuel</label>
            <div className="label-password">
              <input
                name="oldpassword"
                type={showOldPassword ? 'text' : 'password'}
                value={oldPassword}
                className="input-password"
                onChange={(e) => {
                  message && setMessage('');
                  setOldPassword(e.target.value);
                }}
                required
              />
              <FontAwesomeIcon
                icon={showOldPassword ? faEyeSlash : faEye}
                className="icon-show-password"
                onClick={() => setShowOldPassword(!showOldPassword)}
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="err">{message.toString()}</div>

          <div className="input-group">
            <label>Nouveau mot de passe</label>
            <div className="label-password">
              <input
                name="newpassword"
                type={showNewPassword ? 'text' : 'password'}
                // placeholder="Accien mot de passe"
                value={newPassword}
                className="input-password"
                onChange={(e) => {
                  error && setError('');
                  setNewPassword(e.target.value);
                }}
                required
              />
              <FontAwesomeIcon
                icon={showNewPassword ? faEyeSlash : faEye}
                className="icon-show-password"
                onClick={() => setShowNewPassword(!showNewPassword)}
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="msg-pass">
            <em>
              Votre mot de passe doit être composé de 8 caractères minimum, au moins une lettre majuscule, au moins une
              lettre minuscule, et une valeur numérique.
            </em>
          </div>

          <div className="input-group">
            <label>Confirmation du mot de passe</label>
            <div className="label-password">
              <input
                name="confirmpassword"
                type={showConfirmPassword ? 'text' : 'password'}
                // placeholder="Accien mot de passe"
                value={confirmPassword}
                className="input-password"
                onChange={(e) => {
                  error && setError('');
                  setConfirmPassword(e.target.value);
                }}
                required
              />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                className="icon-show-password"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="err">{error}</div>

          <button className="btn-submit mt-3">Valider</button>
        </form>
      </div>
    </div>
  );
}

export default ChangePasswordUI;
